// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { IMyUser, headerConfig } from "./utils";
import lodash, { debounce } from "lodash";
const baseURL = require("../../../framework/src/config").baseURL;
import {
  CustomEnums,
  getCustomEnumName,
} from "../../utilities/src/CustomBlockHelpers";
import { handleLogout, makeApiMessage } from "../../../components/src/common";
import PageContainerWebAdapter from "../../adapters/src/PageContainerWebAdapter";
import {
  getStorageData
} from "framework/src/Utilities";
export const configJSON = require("./config");

let shouldScanFile = true;
export interface ReceiptPrinterSetting {
  id: number,
  account_id: number,
  printer_name: string | null,
  printer_type: null,
  station: null,
  auto_print_receipt: boolean,
  auto_print_pay_on_pickup: boolean,
  receipt_print_new_order: boolean,
  receipt_print_clean_page: boolean,
  receipt_print_ready_page: boolean,
  receipt_print_delivered_page: boolean,
  no_of_copies: number | null
  printer_setting_type?: string,
  created_at?: string
  updated_at?: string
}

export interface IUserContext {
  isSuperAdmin: boolean;
  user: IMyUser | undefined;
  refreshUser: () => void;
}
export interface ISidebarDropdown {
  store: boolean;
  settings: unknown;
  general: unknown;
  products: boolean;
  marketing: boolean;
  customer: boolean;
  homeCleanProduct: boolean;
  hardware: boolean;
}

interface RegionAttributes {
  id: number;
  branch_name: string;
  name: string;
}

interface RegionDropdown {
  id: string;
  type: string;
  attributes: RegionAttributes;
}

interface StoreAttributes {
  id: number;
  store_name: string;
  name_translation: string;
}

interface StoreManagementDropdown {
  id: string;
  type: string;
  attributes: StoreAttributes;
}

interface GroupManagementDropdown {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    "store_managements_count": boolean,
    "store_managements": {
      data: StoreManagementDropdown[]
    }
  };
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    location: { pathname: string },
    goBack: Function;
    navigate: Function;
    getParam: Function;
  };
  id?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  popoverOpen: boolean;
  searchText: string;
  myUser?: IMyUser;
  userShortName: string;
  tokenReady: boolean;
  isNotificationPopoverAnchor: HTMLElement | null;
  notificationCount: number;
  socket: WebSocket | null;
  newOrderNotificationSnackbar: boolean;
  regionListData: RegionDropdown[];
  regionId: string;
  storeListData: StoreManagementDropdown[];
  searchValue: string;
  allStoreListData: StoreManagementDropdown[];
  storeId: string[];
  selectedAllStoreId: string[];
  selectAllStoreId: { id: string, option: string } | undefined
  selectAllStoreWIthGrpId: { id: string, option: string, type: string } | undefined
  groupId: string
  groupListData: GroupManagementDropdown[];
  storeListBygroupData: StoreManagementDropdown[]
  regionMultiId: string[];
  selectAllRegionId: { id: string, option: string } | undefined
  afterPermissionComponent?: React.ReactNode
  singleStoreSelectionId: string;
  storeSearchText: string;
  storeListPageNo: number;
  storeListOptions: { id: string; option: string }[];
  selectedGroupName: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class HeaderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  popoverRef: HTMLElement | null = null;
  debounceTimer: NodeJS.Timeout | number = 0;
  getMyUserCallId: string = "";
  getNotificationCountApiCallId: string = "";
  getAllRegionApiCallId: string = "";
  getAllStoreApiCallId: string = "";
  getAllStoreAndGroupApiCallId: string = "";
  getPrinterSettingApiCallId: string = "";
  pageAdapter: PageContainerWebAdapter;
  storeListEmptyFlag: boolean = false;
  getStoreListApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LayoutDataMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.pageAdapter = new PageContainerWebAdapter();

    this.state = {
      selectAllStoreId: { id: "-1", option: "Select All" },
      selectAllStoreWIthGrpId: undefined,
      groupListData: [],
      storeListBygroupData: [],
      storeId: [],
      selectedAllStoreId: [],
      groupId: "",
      regionMultiId: [],
      singleStoreSelectionId: "",
      selectAllRegionId: undefined,
      popoverOpen: false,
      searchText: "",
      userShortName: "NA",
      tokenReady: false,
      isNotificationPopoverAnchor: null,
      notificationCount: 0,
      socket: null,
      newOrderNotificationSnackbar: false,
      regionListData: [],
      regionId: "",
      storeListData: [],
      searchValue: "",
      allStoreListData: [],
      storeSearchText: "",
      storeListPageNo: 1,
      storeListOptions: [],
      selectedGroupName: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.receiveDataFromLayout(message);
    this.handleResForGetStore(from, message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.errors) {
        handleLogout(this.props.navigation, responseJson?.errors);
      }
      // this.handleResForGetUser(from,message)
      if (apiRequestCallId === this.getNotificationCountApiCallId) {
        if (responseJson) {
          this.setState({ notificationCount: responseJson.count });
        }
      }
    }
    this.handleResForGetAllRegion(from, message)
    this.handleResForGetAllStore(from, message)
    this.handleResGetAllStoreGroup(from, message)
    this.hanldeResForPrinterSetting(from, message)
    this.handleResForGetStore(from, message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const token = await getStorageData("token");
    if (!token) {
      this.handleNavigateTo("EmailAccountLoginPage");
    }
    this.getNotificationCount();
    this.handleStorageSet();
    this.getAllRegion()
    this.getAllMultipleSelectRegion();
    this.getAllStore()
    this.getStoreList()
    this.sendData()
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevProps.navigation.location.pathname !== this.props.navigation.location.pathname) {
      if(this.state.regionMultiId.length && this.checkHeaderConfig("storeAndGroupChange")) {
        return this.sendData()
      };
      if (!this.checkHeaderConfig("multiSelectStore")) {
        this.setState({
          regionId: '',
          groupId: '',
          regionMultiId: [],
          singleStoreSelectionId: '',
          searchValue: '',
          searchText: '',
          selectAllRegionId: undefined,
          selectedAllStoreId: [],
          selectAllStoreWIthGrpId: undefined,
          storeId: []
        }, () => {
          this.sendData();
        })
      }
      if (this.checkHeaderConfig("singleSelectStore")) {
        this.getStoreList()
      }
    }
  }

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        this.setState({
          myUser: recievedData.userContext.user
        }, () => {
          this.setInitialData();
        })
      }
    }
  }

  setInitialData = () => {
    if (this.state.myUser && this.state.myUser.attributes.employee_proifle.data.attributes.region_ids) {
      let regionID = this.state.myUser.attributes.employee_proifle.data.attributes.region_ids.join(',')
      let multiRegId = this.state.myUser.attributes.employee_proifle.data.attributes.region_ids.map(String)
      if(this.state.regionMultiId.length && this.checkHeaderConfig("storeAndGroupChange")) return;
      this.setState({ regionId: regionID, regionMultiId: multiRegId }, () => {
        this.getStoreApiCallOnRegChange(regionID)
        if(!this.checkHeaderConfig('storeAndGroupChange')){
          this.sendData();
        }
      }
      )
    }
    if (shouldScanFile) {
      this.sendPrintAction("SCAN_FILES", this.state.myUser?.attributes.employee_proifle.data.attributes.share_folders)
    }
    if (this.checkHeaderConfig('printerSettings')) {
      this.getPrinterSettings()
    }
  }

  getStoreApiCallOnRegChange = (regionID: string) => {
    if (!this.checkHeaderConfig('multiRegionDropdown')) {
      this.getAllStore(regionID)
    } else {
      if (!this.checkHeaderConfig('storeAndGroupChange')) {
        this.getAllStoreByMutliRegion(regionID)
      } else {
        this.getAllStoreAndGroupByMutliRegion(regionID)
      }
    }
  }

  sendPrintAction = (action: "SCAN_FILES" | "LOG_OUT", payload?: string[]) => {
    const message = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    message.addData(getCustomEnumName(CustomEnums.CustomReducerAction), action)
    payload && message.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), payload.filter(Boolean))
    this.send(message)
    shouldScanFile = action === "LOG_OUT"
  }

  handleSocketNotification = (token: string) => {
    let updateBaseUrl = baseURL.split("//")[1];
    const socket = new WebSocket(`wss://${updateBaseUrl}/cable?token=${token}`);

    socket.onopen = () => {
      let getUserId = localStorage.getItem("userId");
      const subscribeMessage = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "NotificationChannel",
          current_user_id: getUserId,
        }),
      };
      socket.send(JSON.stringify(subscribeMessage));
    };

    socket.onmessage = (event) => {
      let notificationCount = JSON.parse(event.data);
      if (notificationCount?.message?.count) {
        this.setState({
          newOrderNotificationSnackbar: true,
          notificationCount: notificationCount.message.count,
        });
      }
    };
  };

  handleStorageSet = async () => {
    const userName = await getStorageData(configJSON.userShortNameKey);
    const tokenReady = !!(await getStorageData(configJSON.tokenKey));
    this.setState({
      userShortName: userName,
      tokenReady: tokenReady,
    });
  };

  debounce = (func: Function, delay: number) => {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(func, delay.valueOf());
  };

  componentWillUnmount(): Promise<void> {
    clearTimeout(this.debounceTimer);
    // Return a resolved Promise
    return Promise.resolve(undefined);
  }

  handleCloseNotificationPopover = () => {
    this.setState({ isNotificationPopoverAnchor: null });
  };

  handleSnackbarClose = () => {
    this.setState({ newOrderNotificationSnackbar: false });
  };

  handleSearchTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    this.setState({ searchText: event.target.value }, () => {
      this.debounce(this.handleInputChangeDebounced, 500);
    });
  };

  handlePopOverOpen = () => {
    this.setState({ popoverOpen: true });
  };

  handlePopOverClose = () => {
    this.setState({ popoverOpen: false });
  };

  handleViewProfile = () => {
    this.handleNavigateTo("CustomisableUserProfiles");
  };

  handleInputChangeDebounced = async () => {
    this.sendSearchText();
  };

  handleNavigateTo(route: string) {
    const navigateMsg: Message = new Message(
      getCustomEnumName(CustomEnums.PageContainerNavigateMessage)
    );
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMsg);
  }

  checkIsUserUndefined(user: IMyUser | undefined) {
    if (user !== undefined) {
      return user.attributes.last_name;
    } else {
      return "";
    }
  }

  renderUserName(user: IMyUser | undefined) {
    if (user) {
      return user.attributes.first_name;
    } else {
      return "" + " " + this.checkIsUserUndefined(user);
    }
  }

  getNotificationCount = () => {
    const urlStr = configJSON.getNotificationCountEndPoint;
    const requestMessage = makeApiMessage({
      url: urlStr,
      method: configJSON.getApiMethodType,
    });
    this.getNotificationCountApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClickNotification = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({ isNotificationPopoverAnchor: event.currentTarget });
  };

  getAllRegion = () => {
    const requestMessage = makeApiMessage({
      url: configJSON.getAllRegionEndPoint,
      method: configJSON.getApiMethodType,
    });
    this.getAllRegionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllMultipleSelectRegion = () => {
    if (!this.checkHeaderConfig('multiRegionDropdown')) return;
    const requestMessage = makeApiMessage({
      url: configJSON.getAllRegionEndPoint,
      method: configJSON.getApiMethodType,
    });
    this.getAllRegionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForGetAllRegion = async (from: string, message: Message) => {
    if (this.getAllRegionApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        // this.setState({ regionListData : responseJson.data },()=>this.sendData())
        this.setState({ regionListData: responseJson.data })
      }
    }
  }

  handleRegionSelection = (value: { id: string; option: string }) => {
    this.setState({ regionId: value.id, storeId: [], groupId: "" });
    this.getAllStore(value.id)
  }

  getAllStore = (regionID?: string) => {
    if (this.checkHeaderConfig("storeAndGroupChange")) return;
    let regionParam = (regionID && this.checkHeaderConfig("multiRegionDropdown")) ? `&region_id=${regionID}` : "";
    const requestMessage = makeApiMessage({
      url: `${configJSON.getAllStoreEndPoint}${regionParam}`,
      method: configJSON.getApiMethodType,
    });
    this.getAllStoreApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllStoreByMutliRegion = (regionID: string) => {
    if (!this.checkHeaderConfig("multiSelectStore")) return;
    let mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=${regionID}`
    if (!regionID) {
      mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=0`
    }
    const requestMessage = makeApiMessage({
      url: mainUrl,
      method: configJSON.getApiMethodType,
    });
    this.getAllStoreApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllStoreAndGroupByMutliRegion = (regionID: string) => {
    let mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=${regionID}&is_store_group=true`
    if (!regionID) {
      mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=01&is_store_group=true`
    }
    const requestMessage = makeApiMessage({
      url: mainUrl,
      method: configJSON.getApiMethodType,
    });
    this.getAllStoreAndGroupApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResGetAllStoreGroup = async (from: string, message: Message) => {
    if (this.getAllStoreAndGroupApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({
          groupListData: responseJson.data.store_groups.data,
          storeListData: responseJson.data.stores.data,
          allStoreListData: responseJson.data.stores.data
        }, () => this.sendData())
      }
    }
  }

  handleResForGetAllStore = async (from: string, message: Message) => {
    if (this.getAllStoreApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log("hel-kan", responseJson.data)
      if (responseJson.data) {
        this.setState({ storeListData: responseJson.data, storeId: responseJson.data.map((item: any) => item.id) }, () => this.sendData())
      }
    }
  }

  sendData = () => {
    let msgData = new Message(getName(MessageEnum.BroadcastNavbarDataMessage))
    msgData.addData(getName(MessageEnum.BroadcastNavbarData), {
      regionID: this.state.regionId,
      storeId: this.state.storeId.join(','),
      groupId: this.state.groupId,
      regionMultiId: this.state.regionMultiId.join(','),
      regionName: this.getBranchNameById(this.state.regionId),
      storeNames: this.getStoreNames(this.state.storeId),
      regionNames: this.getRegionNames(this.state.regionMultiId),
      regionSelectAll: this.state.regionMultiId.length === this.state.regionListData.length,
      singleStoreID: this.state.singleStoreSelectionId,
      singleStoreName: this.getSingleStoreName(this.state.singleStoreSelectionId)
    })
    this.send(msgData)
  }

  sendSearchText = () => {
    let msgData = new Message(getName(MessageEnum.SearchTextMessage))
    msgData.addData(getName(MessageEnum.SearchMessageText), {
      searchText: this.state.searchText
    })
    this.send(msgData)
  }

  sendPrintSettings = (settings: ReceiptPrinterSetting) => {
    let msgData = new Message(getName(MessageEnum.PrintSettingsMessage))
    msgData.addData(getName(MessageEnum.PrintSettngsData), {
      receiptPrinterSetting: settings
    })
    this.send(msgData)
  }

  onChangeMultiSelect(event: { id: string, option: string }) {
    const { storeListData, storeId } = this.state;

    const isSelectAll = storeId.length === storeListData.length;
    const idLists = JSON.parse(JSON.stringify(event.id));
    const allIds = storeListData.map((varItem) => varItem.id);
    let selectedIds = [];
    if (idLists.includes("-1")) {
      if (!isSelectAll) {
        selectedIds = allIds
        this.setState({ selectAllStoreId: event })
      } else {
        this.setState({ selectAllStoreId: undefined })
      }
    } else {
      this.setState({ selectAllStoreId: undefined })
      if (this.state.storeId.includes(idLists)) {
        selectedIds = this.state.storeId.filter(id => id !== idLists);
      } else {
        selectedIds.push(...this.state.storeId, idLists)
        if (selectedIds.length == storeListData.length) {
          this.setState({ selectAllStoreId: { id: "-1", option: "Select All" } })
        }
      }
    }

    this.setState({
      storeId: selectedIds,
    }, () => {
      if (!this.checkHeaderConfig('multiSelectStore')) return;
      this.sendData()
    });
  }

  onChangeMulSelectStoreAndGroup(event: { id: string, option: string, type: string }) {
    const { storeListData, storeId, groupId } = this.state;

    if (event.type === "store") {
      this.handleStoreSelection(event.id, storeListData, storeId);
    } else if (event.type === "group") {
      this.handleGroupSelection(event.id, groupId, event.option);
    }
  }

  handleStoreSelection(idList: string, storeListData: any[], storeId: string[]) {
    let mainAllStore = [...this.state.storeListData, ...this.state.storeListBygroupData]
    const uniqueStoreList = mainAllStore.filter(
      (store, index, self) =>
        index === self.findIndex((s) => s.id === store.id)
    );
    const isSelectAll = storeId.length === uniqueStoreList.length;
    const allIds = uniqueStoreList.map((varItem: any) => varItem.id);
    let selectedIds = [...storeId];

    if (idList === "-1") {
      selectedIds = isSelectAll ? [] : allIds;
      this.setState({ selectAllStoreWIthGrpId: isSelectAll ? undefined : { id: "-1", option: "Select All", type: "store" } });
    } else {
      selectedIds = selectedIds.includes(idList)
        ? selectedIds.filter(id => id !== idList)
        : [...selectedIds, idList];

      const selectAllStoreWIthGrpId = selectedIds.length === uniqueStoreList.length
        ? { id: "-1", option: "Select All", type: "store" }
        : undefined;

      this.setState({ selectAllStoreWIthGrpId });
    }

    this.updateSelectionState(selectedIds, this.state.groupId);
  }

  handleGroupSelection(idList: string, groupId: string, groupName: string) {

    let selectedGroupIds = idList;

    if (this.state.groupId === selectedGroupIds) {
      selectedGroupIds = ""
    }

    let selectedStoreIds
    const group = this.state.groupListData.find((group: any) => group.id === selectedGroupIds);
    if (group) {
      const storeManagements = group.attributes.store_managements.data
      const storeIds = storeManagements.map((store: any) => store.attributes.id);
      selectedStoreIds = storeIds
      this.setState({
        selectAllStoreWIthGrpId: undefined, searchValue: "", storeListBygroupData: storeManagements,
        selectedAllStoreId: selectedStoreIds.map(id => id.toString())
      })
    } else {
      selectedStoreIds = []
    }

    if (this.state.selectedGroupName === groupName) {
      this.setState({ selectedGroupName: "" })
    } else {
      this.setState({ selectedGroupName: groupName })
    }

    this.updateSelectionState(selectedStoreIds, selectedGroupIds);
  }

  updateSelectionState(storeId: string[]
    , groupId: string) {
    this.setState({
      storeId: storeId.map(id => id.toString()),
      groupId: groupId
    }, () => {
      this.sendData()
    });
  }

  onChangeMultiRegionSelect = (event: { id: string, option: string }) => {
    const { regionListData, regionMultiId } = this.state;
    const isSelectAll = regionMultiId.length === regionListData.length;
    const idLists = JSON.parse(JSON.stringify(event.id));
    const allIds = regionListData.map((varItem) => varItem.id);
    let selectedIds: any = [];
    if (idLists.includes("-1")) {
      if (!isSelectAll) {
        selectedIds = allIds
        this.setState({ selectAllRegionId: event })
      } else {
        this.setState({ selectAllRegionId: undefined })
      }
    } else {
      this.setState({ selectAllRegionId: undefined })

      if (this.state.regionMultiId.includes(idLists)) {
        selectedIds = this.state.regionMultiId.filter(id => id !== idLists);
      } else {
        selectedIds.push(...this.state.regionMultiId, idLists)
        if (selectedIds.length == regionListData.length) {
          this.setState({ selectAllRegionId: { id: "-1", option: "Select All" } })
        }
      }
    }
    this.setState({
      regionMultiId: selectedIds,
      storeId: [],
      groupId: "",
      storeListBygroupData: [],
      selectedGroupName: ""
    },()=>this.getAllStoreAndGroupByMutliRegion(selectedIds.join(',')));
  }

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  getBranchNameById(id: string): string {
    const region = this.state.regionListData.find((region) => region.id === id);
    return region ? region.attributes.branch_name : "No Region Selected";
  }

  getRegionNames(ids: string[]) {
    const storeNames = ids.map(id => {
      const store = this.state.regionListData.find(store => store.id === id);
      return store ? store.attributes.name : null;
    }).filter(name => name !== null);

    if (storeNames.length === this.state.regionListData.length) {
      return "All Region";
    }

    return storeNames.join(",");
  }

  getStoreNames(ids: string[]) {
    const storeNames = ids.map(id => {
      const store = this.state.storeListData.find(store => store.id === id);
      return store ? store.attributes.store_name : null;
    }).filter(name => name !== null);

    if (storeNames.length === this.state.storeListData.length) {
      return "All Stores";
    }

    return storeNames.join(",");
  }

  getSingleStoreName(id: string) {
    const store = this.state.storeListData.find(store => store.id === id);
    return store ? store.attributes.store_name : "";
  }

  handleStoreFilter = (value: string, uniqueStoreList: any) => {
    this.setState({ searchValue: value });
  }

  sortStoreList(storeList: { id: string, option: string, type: string }[], selectedStoreIds: Set<number | string>): { id: string, option: string, type: string }[] {
    return storeList.sort((a: { id: number | string }, b: { id: number | string }) => {
      if (selectedStoreIds.has(a.id) && !selectedStoreIds.has(b.id)) {
        return -1;
      }
      if (!selectedStoreIds.has(a.id) && selectedStoreIds.has(b.id)) {
        return 1;
      }
      return 0;
    });
  }

  getPrinterSettings = () => {
    const apiMessage = makeApiMessage({
      method: "GET",
      url: configJSON.printerSettingApi
    })
    this.getPrinterSettingApiCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  hanldeResForPrinterSetting = async (from: string, message: Message) => {
    if (this.getPrinterSettingApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.sendPrintSettings(responseJson.data);
    }
  }

  handleSingleStoreSelection = (value: { id: string; option?: string }) => {
    this.setState({ singleStoreSelectionId: value.id }, () => this.sendData());
  }

  checkHeaderConfig = (showPermission: keyof typeof headerConfig) => {
    return headerConfig[showPermission].includes(this.props.navigation.location.pathname)
  }

  handelStoreDropdownScroll = (event: React.SyntheticEvent) => {
    if (this.storeListEmptyFlag) return;
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1.3) {
      this.setState(
        (prev) => ({ storeListPageNo: prev.storeListPageNo + 1 }),
        () => {
          this.getStoreList();
        }
      );
    }
  };

  getStoreList = () => {
    if (!this.checkHeaderConfig("singleSelectStore")) return;
    const searchQuery = this.state.storeSearchText
      ? `&filter_by[query]=${this.state.storeSearchText}`
      : "";
    const apiUrl =
      configJSON.getAllStoreEndPoint +
      `${searchQuery}&page_no=${this.state.storeListPageNo}`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.apiMethodTypeGet,
    });

    this.storeListEmptyFlag = true;
    this.getStoreListApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  debouncedStoreSearch = lodash.debounce(
    (newInputValue: string) => this.handleSearchStore(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleSearchStore = (inputValue: string) => {
    if (inputValue === this.state.storeSearchText) return;
    this.setState(
      {
        storeListPageNo: 1,
        storeSearchText: inputValue,
      },
      () => this.getStoreList()
    );
  };

  handleResForGetStore = async (from: string, message: Message) => {
    if (this.getStoreListApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson: { data: StoreManagementDropdown[] } = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        const { myUser, storeListPageNo, storeListOptions } = this.state;
        const { store_id, store_name } =
          myUser?.attributes?.employee_proifle?.data?.attributes
            ?.store_management || {};
        let currentStore = storeListOptions.find(
          (store) => store.id === lodash.toString(store_id)
        );
        if (store_id !== undefined && currentStore === undefined) {
          currentStore = {
            id: lodash.toString(store_id),
            option: lodash.toString(store_name),
          };
        }
        const array = (responseJson?.data || []).map((item) => ({
          id: item.id,
          option: item.attributes.store_name,
        }));
        const newStoreList =
          storeListPageNo === 1 ? array : [...storeListOptions, ...array];
        currentStore && newStoreList.unshift(currentStore);
        this.storeListEmptyFlag = array.length < 10;
        this.setState({
          storeListOptions: lodash.uniqBy(newStoreList, "id"),
        });
      }
    }
  }

  // Customizable Area End
}
