import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { makeApiMessage as MakeApiMessage, makeApiMessage } from '../../../components/src/common'
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
export const configJSON = require("./config");
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End


// Customizable Area Start
export interface PriceListSection {
  id: string,
  type: string,
  attributes: {
      id: number,
      section_name: string,
      name: string
  }
}

export interface PriceListProduct {
  id: number,
  name: string,
  image: string
}

export interface PriceListItemAttr {
  id: number,
  name: string,
  product_currency_id: string,
  price_list_type: string,
  created_at: string,
  updated_at: string,
  tax_type: string,
  activated: boolean,
  price_list_to_copy: string,
  model_name: string,
  add_by: string,
  is_master_price_list: boolean,
  sections: {
      data: PriceListSection[]
    },
  products: PriceListProduct[]
}

export interface PriceList {
  id: string,
  type: string,
  attributes: PriceListItemAttr,
}

interface PriceListFilterSugg { 
  id: string | number 
  name: string;
}

interface SuggOption {
  label: string;
  value: string
}

export const emptyPriceListAttrItem = {
  id: 0,
  name: "",
  product_currency_id: "",
  price_list_type: "",
  created_at: "",
  updated_at: "",
  tax_type: "",
  activated: false,
  price_list_to_copy: "",
  model_name: "",
  add_by: "",
  is_master_price_list: false,
  sections: {
    data: [
      {
        id: "",
        type: "",
        attributes: {
            id: 0,
            section_name: "",
            name: ""
        }
      }
    ]
  },
  products: [
    {
      id: 0,
      name: "",
      image: ""
    }
  ],
}

export const emptyPriceListItem = {
  id: "",
  type: "",
  attributes: emptyPriceListAttrItem
}

const ApiUrls = configJSON.ApiUrls;

export interface IMeta {
  next_page: number;
  pervious_page: number;
  total_pages: number;
  total_count: number;
  current_page: number
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  viewPriceListModal: boolean;
  openAddPriceListModal: boolean;
  filters: IFilter[];
  sortingData: ISortingData;
  filterAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  openAction: EventTarget & HTMLButtonElement | null;
  openDeactiveModel: boolean;
  deActivatedItem: PriceListItemAttr;
  deactivated: boolean;
  PriceList: PriceList[];
  PriceItem: PriceList;
  page: number;
  query: string;
  searchTextPrice: string;
  pageSize: number;
  meta: IMeta;
  suggestionFieldTitle: string;
  isLoading: boolean;
  permissionStatus: PermissionStatus;
  isLoadingPermission: boolean
  // Customizable Area End
}

export interface SS {}

export default class PriceListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  priceListinId: string = "";
  rowPerPage: number = 10;
  deactivateMessageId: string = "";
  activateMessageId: string = "";
  filterSuggestionApiCallId: string = "";

  state: S = {
    viewPriceListModal: false,
    openAddPriceListModal: false,
    filterAnchor: undefined,
    openAction: null,
    openDeactiveModel: false,
    deActivatedItem: emptyPriceListAttrItem,
    deactivated: false,
    PriceList: [],
    PriceItem: emptyPriceListItem,
    sortingData: {
      name: "",
      price_list_type: "",
      price_list_to_copy: "",
      product_currency_id: "",
      activated: "",
    },
    page: 0,
    query: "",
    pageSize: 10,
    filters: [
      {
        title: "Price List Name",
        type: "autocompolete",
        value: "",
        options: [],
      },
      {
        title: "Price List Type",
        type: "select",
        value: "",
        options: [
          {
            label: "Store",
            value: "Store",
          },
          {
            label: "Mobile",
            value: "Mobile",
          },
        ],
      },
      {
        title: "Added By",
        type: "select",
        value: "",
        options: [
          {
            label: "Amount",
            value: "SAR",
          },
          {
            label: "Percentage",
            value: "%25",
          },
        ],
      },
      {
        title: "Status",
        type: "select",
        value: "",
        options: [
          {
            label: "Active",
            value: "Active",
          },
          {
            label: "Deactivated",
            value: "Deactivated",
          },
        ],
      },
    ],
    searchTextPrice: "",
    meta: {
      next_page: 1,
      pervious_page: 1,
      total_pages: 1,
      total_count: 1,
      current_page: 1
    },
    suggestionFieldTitle: "",
    isLoading: false,
    permissionStatus: {
      mainPermission: false,
      createPermission: false,
      viewPermission: false,
      editPermission: false,
      deactivatePermission: false,
      activatePermission: false
    },
    isLoadingPermission: true
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getPriceListApi());
  };

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.SearchTextMessage)
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveDataFromLayout(message);
    this.receiveSearchTextData(message)
    const successResponse: any = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    const requestId: any = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    switch(requestId) {
      case this.priceListinId:
        this.handlePriceListResp(successResponse);
        break;
      case this.deactivateMessageId:
        this.handleDeactivatePriceResp(successResponse);
        break;
      case this.activateMessageId:
        this.handleActivatePriceResp(successResponse);
        break;
      case this.filterSuggestionApiCallId:
        this.handleFilterSuggResponse(successResponse);
        break;
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getPriceListApi();
    // Customizable Area End
  }

  // Customizable Area Start
  receiveSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.handleSearchPriceItem(recievedData.searchText)
        }
    }
  }
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  handlePageChange = ( page: number ) => { 
    this.setState({ page }, () => this.getPriceListApi());
  };

  handleSearchPriceItem = (value: string) => {	
    this.setState(	
      {	
        page: 0,
        searchTextPrice: value,	
      },	
      this.getPriceListApi	
    );
  };

  handleFilterChange = (filters: IFilter[]) => {
    this.setState(
      {
        filters,
        page: 0,
      },
      this.getPriceListApi
    );
  };

  checkStatusFilter(keys: string | string[] | undefined) {
    if(keys == "Active") {
      return true;
    } else {
      return false;
    }
  }

  handleFilterAutoSugg(title: string, value: string) {
    this.setState({ suggestionFieldTitle: title });
    this.handlePriceFilterAutoComplete(title, value);
  }

  handlePriceFilterAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      else item.value = "";
      return item;
    });

    let ApiUrl;
    if (title === "Price List Name") {
      ApiUrl = ApiUrls.FilterSuggestionEndPoint;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });

    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFilterSuggResponse(response: { data: PriceListFilterSugg[]}) {
    const list =
      response?.data?.map(
        (item: PriceListFilterSugg) => {
          return {
            label: item.name,
            value: item.id,
          };
        }
      ) || [];
    this.setFilterSuggestionList(list as SuggOption[]);
  }

  setFilterSuggestionList(list: SuggOption[]) {
    const updatedFilters = this.state.filters.map((item: IFilter) => {
      if (item.value !== "") {
        if (item.title === this.state.suggestionFieldTitle) item.options = list;
        return item;
      } else {
        if (item.title === this.state.suggestionFieldTitle) item.options = [];
        return item;
      }
    });

    this.setState({ filters: updatedFilters });
  }

  getPriceListApi() {
    this.setState({ isLoading: true })
    const nameLabel = this.state.filters.find(
      (item:IFilter) => item.title === "Price List Name"
    )?.value;
    const customerOptions = this.state.filters.find(
      (item: IFilter) => item.title === "Price List Name"
    )?.options;
    const priceListNameLabel = customerOptions?.find(
      (item: SuggOption) => item.label == nameLabel
    );
    const nameLabel_id = priceListNameLabel ? priceListNameLabel?.value : "";
    const priceListTypeFilter = this.state.filters.find((item:IFilter) => item.title === "Price List Type")?.value;
    const statusFilter = this.state.filters.find((item:IFilter) => item.title === "Status")?.value;
    const addedbyFilter = this.state.filters.find((item:IFilter) => item.title === "Added By")?.value;
    const searchQuery = this.state.searchTextPrice;

    const priceListEndpoint =
      ApiUrls.priceListEndPoint +
      "?page_no=" +
      (this.state.page + 1) +
      "&per_page=" + this.rowPerPage +
      (nameLabel_id ? `&filter_by[price_list_id]=${nameLabel_id}` : "") +
      (priceListTypeFilter ? `&filter_by[price_list_type]=${priceListTypeFilter}` : "") +
      (statusFilter ? `&filter_by[activated]=${this.checkStatusFilter(statusFilter)}` : "") +
      (addedbyFilter ? `&filter_by[product_currency_id]=${addedbyFilter}` : "") +
      (searchQuery ? `&filter_by[query]=${searchQuery}` : "") +
      this.state.query

    const requestMessage: Message = MakeApiMessage({
      url: priceListEndpoint,
      method: "GET",
    });

    this.priceListinId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseConfirmModal = () => {
    this.setState({
      openDeactiveModel: false,
    });
  }

  deActivatePrice = () => {
    const idProp = this.state.deActivatedItem.id;
    const deactiveUrl = ApiUrls.priceListEndPoint + idProp + "/deactivate";
    let requestMessage = MakeApiMessage({
      url: deactiveUrl,
      method: "PUT",
    });
    this.deactivateMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  activePrice = (deActivatedItem: PriceListItemAttr) => {
    const idProp = deActivatedItem.id;
    const activeUrl = ApiUrls.priceListEndPoint + idProp + "/activate";
    let requestMessage = MakeApiMessage({
      url: activeUrl,
      method: "PUT",
    });
    this.activateMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeactivePrice = (deActivatedItem: PriceListItemAttr) => {
    if(deActivatedItem.activated) {
      this.setState({
        openAction: null,
        deActivatedItem: deActivatedItem,
        openDeactiveModel: true,
      });
    } else {
      this.activePrice(deActivatedItem)
    }
  }

  handlePriceListViewModalClose = () => {
    this.setState({
      viewPriceListModal: false,
    });
  };

  handlepopver = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: PriceList) => {
    this.setState({
      openAction: event.currentTarget,
      PriceItem: item 
    }); 
  };

  handlePriceListResp = (responseJson: { data: PriceList[], meta: IMeta }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        meta: responseJson.meta,
        PriceList: responseJson.data,
        isLoading: false
      });
    }
  };

  handleNavihateViewModal = (viewId: string) => {
    this.handleClosePopver();
    this.props.navigation.navigate("PriceListView", { id: viewId });
  };

  handleClosePopver = () => {
    this.setState({ openAction: null });
  };

  handleBackToListing = () => {
    this.setState({ deactivated: false }, () => this.getPriceListApi());
  }

  handleDeactivatePriceResp = (responseJson: { data: PriceList }) => {
    if (responseJson && responseJson.data) {
      this.setState({ openDeactiveModel: false, deactivated: true});
    }
  };

  handleActivatePriceResp = (responseJson: { data: PriceList }) => {
    if (responseJson && responseJson.data) {
      this.handleClosePopver();
      this.getPriceListApi();
    }
  };

  closeAddPriceListModal = () => {
    this.setState({
      openAddPriceListModal: false,
    }, () => this.getPriceListApi())
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.priceList;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
      isLoadingPermission: false
    })
  };
  // Customizable Area End
}
