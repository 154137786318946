import React from "react";

// Customizable Area Start
import { styled, Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import {
  Heading,
  Container,
  StyledChip,
  GridContainer,
  BottomContainer,
} from "./PromoCodeCreate.web";

import { colors } from "../../../blocks/utilities/src/Colors";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

// Customizable Area End

import PromoCodeViewController, {
  // Customizable Area Start
  configJSON,
  ITimeSlot,
  // Customizable Area End
} from "./PromoCodeViewController.web";

// Customizable Area Start
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
const Strings = configJSON.Strings.promoCodeView;
// Customizable Area End

class PromoCodeView extends PromoCodeViewController {
  render() {
    // Customizable Area Start
    const { isLoading, noData, data, permissionStatus } = this.state;
    const {
      discount_type,
      discount,
      no_of_orders,
      description,
      promo_code,
      services,
      platforms,
      stores,
      end_date,
      used,
      maximum_discount,
      min_order_amount,
      sections,
      products,
      start_date,
      exclude_days,
      promo_expires,
      available_times,
      regions,
      areas,
      home_cleaning_products,
      order_types,
      discount_product
    } = data.attributes;

    // Customizable Area End

    return (
      // Customizable Area Start
      <>
       <CustomLoader loading={this.state.isViewLoading}/>
        <MainContainer>
          <HeadingContainer>
            <Heading>{Strings.pageTitle}</Heading>
            {
              renderBaseOnConditions(
                permissionStatus.editPermission,
                <BottomContainer>
                  <button
                    data-test-id='btn-edit'
                    onClick={() =>
                      this.redirectTo("PromoCodeEdit", {
                        id: this.props.navigation.getParam("id"),
                      })
                    }
                    className='add'
                  >
                    {Strings.edit}
                  </button>
                </BottomContainer>,
                <></>
              )
            }
          </HeadingContainer>

          {isLoading && !noData && <LoadingComponent />}
          {!isLoading && noData && (
            <span style={{ color: "#EC4E20" }}>{Strings.noData}</span>
          )}
          {!isLoading && !noData && (
            <>
              <ShadowBox>
                <HeadingText>{Strings.description}</HeadingText>
                <ValueText>{description}</ValueText>

                <Spacer32 />

                <GridContainer>
                  <div>
                    <HeadingText>{Strings.promoCode}</HeadingText>
                    <ValueText>{promo_code}</ValueText>
                  </div>
                  <div>
                    <HeadingText>{Strings.region}</HeadingText>
                    <ChipContainer>
                      {regions.map((name: string) => (
                        <StyledChip key={name} label={name} size='small' />
                      ))}
                    </ChipContainer>
                  </div>
                  <div>
                    <HeadingText>{Strings.area}</HeadingText>
                    <ChipContainer>
                      {areas.map((name: string) => (
                        <StyledChip key={name} label={name} size='small' />
                      ))}
                    </ChipContainer>
                  </div>
                  <div>
                    <HeadingText>{Strings.section}</HeadingText>
                    <ChipContainer>
                      {sections.map((name: string) => (
                        <StyledChip key={name} label={name} size='small' />
                      ))}
                    </ChipContainer>
                  </div>
                  <div>
                    <HeadingText>{Strings.services}</HeadingText>
                    <ChipContainer>
                      {services.map((name: string) => (
                        <StyledChip key={name} label={name} size='small' />
                      ))}
                    </ChipContainer>
                  </div>
                  <div>
                    <HeadingText>{Strings.product}</HeadingText>
                    <ChipContainer>
                      {products.map((name: string) => (
                        <StyledChip key={name} label={name} size='small' />
                      ))}
                    </ChipContainer>
                  </div>
                  <div>
                    <HeadingText>{Strings.platform}</HeadingText>
                    <ChipContainer>
                      {platforms.map((name: string) => (
                        <StyledChip key={name} label={name} size='small' />
                      ))}
                    </ChipContainer>
                  </div>
                  <div>
                    {stores && stores.length > 0 && (
                      <>
                        <HeadingText>{Strings.store}</HeadingText>
                        <ChipContainer>
                          {stores.map((name: string) => (
                            <StyledChip key={name} label={name} size='small' />
                          ))}
                        </ChipContainer>
                      </>
                    )}
                  </div>
                  <div>
                    <HeadingText>{Strings.orderType}</HeadingText>
                    <ChipContainer>
                      {order_types.length != 0
                        ? order_types.map((name: string) => (
                            <StyledChip key={name} label={name} size='small' />
                          ))
                        : Strings.noViewDataString}
                    </ChipContainer>
                  </div>
                  <div>
                    <HeadingText>{Strings.home_cleaning_product}</HeadingText>
                    <ChipContainer>
                      {home_cleaning_products.length != 0
                        ? home_cleaning_products.map((name: string) => (
                            <StyledChip key={name} label={name} size='small' />
                          ))
                        : Strings.noViewDataString}
                    </ChipContainer>
                  </div>
                </GridContainer>
              </ShadowBox>

              <Spacer32 />

              <ShadowBox>
                <GridContainer>
                  <div>
                    <HeadingText>{Strings.used}</HeadingText>
                    <ValueText>
                      <span style={{ textTransform: "capitalize" }}>
                        {used}
                      </span>
                    </ValueText>
                  </div>
                  <div>
                    {used === "specific_number_of_orders" && (
                      <>
                        <HeadingText>{Strings.nosOfRecords}</HeadingText>
                        <ValueText>{no_of_orders}</ValueText>
                      </>
                    )}
                  </div>
                  <div>
                    <HeadingText>{Strings.startDate}</HeadingText>
                    <ValueText>{start_date}</ValueText>
                  </div>
                  <div>
                    <HeadingText>{Strings.endDate}</HeadingText>
                    {Boolean(promo_expires) ? (
                      <ValueText>{end_date}</ValueText>
                    ) : (
                      <ValueText>
                        <span style={{ color: "#EC4E20", fontStyle: "italic" }}>
                          {Strings.promoNotExpire}
                        </span>
                      </ValueText>
                    )}
                  </div>
                  <div>
                    <HeadingText>{Strings.type}</HeadingText>
                    <ValueText style={{ textTransform: "capitalize" }}>
                      {discount_type}
                    </ValueText>
                  </div>

                  <div>
                    <HeadingText>{Strings.discountPlaceHolder}</HeadingText>
                    <ValueText style={{ textTransform: "capitalize" }}>
                      {discount}
                    </ValueText>
                  </div>
                  {min_order_amount && (
                    <div>
                      <HeadingText>{Strings.minAmount}</HeadingText>
                      <ValueText>{min_order_amount}</ValueText>
                    </div>
                  )}
                  {maximum_discount && (
                    <div>
                      <HeadingText>{Strings.maxAmount}</HeadingText>
                      <ValueText>{maximum_discount}</ValueText>
                    </div>
                  )}
                  {discount_product?.id && discount_type === "bogo" &&
                    <div>
                      <HeadingText>{Strings.discountProduct}</HeadingText>
                      <ValueText>{discount_product.option}</ValueText>
                    </div>
                  }
                </GridContainer>
              </ShadowBox>

              <ShadowBox>
                <GridContainer>
                  <div>
                    <HeadingText>{Strings.excludeDay}</HeadingText>
                    <ChipContainer>
                      {exclude_days.map((item: {id:number,day:string}) => (
                        <StyledChip key={item.id} label={item.day} size='small' />
                      ))}
                    </ChipContainer>
                  </div>
                  <div>
                    <HeadingText>{Strings.availableTime}</HeadingText>
                    <ValueText>
                      {available_times.map(
                        ({ start_time, end_time }: ITimeSlot) => {
                          let time: string = start_time + " - " + end_time;
                          return <div>{time}</div>;
                        }
                      )}
                    </ValueText>
                  </div>
                </GridContainer>
              </ShadowBox>

              <BackButtonContainer>
                <button
                  onClick={() => this.redirectTo("PromoCode")}
                  className='add'
                  data-test-id='back-button'
                >
                  {Strings.back}
                </button>
              </BackButtonContainer>
            </>
          )}
        </MainContainer>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export function LoadingComponent() {
  return (
    <>
      <br /> <br />
      <Skeleton animation='wave' variant='rect' />
      <br />
      <Skeleton animation='wave' variant='rect' />
      <br />
      <Skeleton animation='wave' variant='rect' />
      <br />
      <Skeleton animation='wave' variant='rect' />
      <br />
    </>
  );
}

export const HeadingContainer = styled("div")({
  gap: 32,
  paddingBottom: 25,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& > div, & > h2": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "@media only screen and (max-width: 550px)": {
    flexFlow: "flex-flow",
    justifyContent: "flex-start",
  },
});

export const Spacer32 = styled("div")({
  height: 32,
});

const ShadowBox = styled(Box)({
  padding: 32,
  borderRadius: 12,
  marginBottom: 32,
  background: "#FFF",
  boxShadow:
    "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
});

export const ValueText = styled("div")({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "22px",
  color: "var(--basic-black, #000)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
    fontWeight: 500,
    paddingBottom: 12,
    lineHeight: "22px",
    color: colors().viewTextColor ,
  },
});

export const HeadingText = styled("div")({
  fontSize: 18,
  fontWeight: 600,
  paddingBottom: 12,
  lineHeight: "22px",
  color: "var(--dark-grey, #4D4D4D)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 12,
    lineHeight: "22px",
    color: colors().viewTextColor ,
  },
});

export const ChipContainer = styled("div")({
  "& > div": {
    marginRight: 8,
    "@media only screen and (max-width: 1024px)": {
      marginBottom: 12,
    },
  },
});

const MainContainer = styled('div')({
  paddingTop:'25px',
});

const BackButtonContainer = styled("div")({
  gap: 32,
  display: "flex",
  paddingBottom:"30px",
  "& > button": {
    fontSize: 16,
    padding: 16,
    minWidth: 126,
    border: "none",
    fontWeight: 700,
    borderRadius: 8,
    cursor: "pointer",
    lineHeight: "24px",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    fontFamily: "Montserrat",
    "&:disabled": {
      opacity: 0.5,
    },
  },
  "& > .add": {
    color: "white",
    background: colors().cyancobaltblue,
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
  "& > .cancel": {
    color: colors().slateGray,
    background: colors().antiFlashWhite,
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
});
// Customizable Area End

export default PromoCodeView;
