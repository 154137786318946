Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfPriceList";
exports.labelBodyText = "CfPriceList Body";

exports.btnExampleTitle = "CLICK ME";
exports.testCoverage = "testCoverage";
exports.priceListcoverage = "priceListcoverage";

exports.getProductListApiEndPoint = '/bx_block_catalogue/catalogues';
exports.getNewProductListApiEndPoint = '/bx_block_catalogue/catalogues/products_list';
exports.getProductListApiMethod = 'GET';
exports.addProductListApiMethod = 'POST';
exports.editProductListApiMethod = 'PUT';
exports.deleteApiMethod = 'DELETE';

exports.getPriceListApiEndPoint = '/bx_block_price_list/price_lists';
exports.getPriceListApiMethod = 'GET';
exports.addPriceListApiMethod = 'POST';

exports.getSectionListApiEndPoint = '/bx_block_categories/categories';
exports.getSectionListApiMethod = 'GET';

exports.getServiceListApiEndPoint = '/bx_block_categories/services';
exports.getServiceListApiMethod = 'GET';

exports.getPreferenceListApiEndPoint = '/bx_block_categories/preference_lists';
exports.getPreferenceListApiMethod = 'GET';

exports.getImagesListApiEndPoint = '/bx_block_categories/gallery_icons/list_all_images';
exports.getImagesListApiMethod = 'GET';

exports.getParentListApiEndPoint = '/bx_block_catalogue/catalogues/parent_products';
exports.getParentListApiMethod = 'GET';

exports.getDropdownOptionApiEndPoint = '/bx_block_drop_down/drop_down';
exports.getDropdownOptionApiMethod = 'GET';
exports.addDropdownOptionApiMethod = 'POST';

exports.dragAndDropProductApiEndPoint = '/bx_block_catalogue/catalogues/order_numbers';
exports.dragAndDropProductApiMethod = 'PUT';

exports.addNewCustomerApiEndPoint = '/account_block/web_customers';
exports.getCustomerDetailsApiMethod = 'GET';
exports.addNewCustomerApiMethod = 'POST';
exports.EditCustomerApiMethod = 'PUT';

exports.productSuggesstionApiEndPoint = '/bx_block_catalogue/catalogues/product_filter';
exports.productSuggesstionApiMethod = 'GET';

exports.checkMobilePriceListEndPoint = "bx_block_price_list/price_lists/check_mobile_price_list?price_list_type=Mobile";
exports.ApiUrls = {  
  priceListEndPoint: "bx_block_price_list/price_lists/",
  FilterSuggestionEndPoint: "bx_block_price_list/price_lists/drop_down_filter?name=",
  productListEndPoint: 'bx_block_catalogue/catalogues',
  deactivateProductsEndPoint: 'bx_block_price_list/price_lists/deactivate_products',
  exportCSVApiUrl: "bx_block_catalogue/catalogues/csv_export",
  importCsvApiUrl: "bx_block_catalogue/catalogues/csv_import",
  exportCSVTemplateApiUrl: "bx_block_catalogue/catalogues/csv_template",
  employeeDataEndpoint: "account_block/employees",
  getDropdownOptionApiEndPoint: "bx_block_catalogue/catalogues/catalogue_enum_dropdown",
  exportExcelApiUrl: "bx_block_data_import_export/catalogue_export/excel_export",
  exportExcelTemplateApiUrl: "bx_block_data_import_export/catalogue_export/excel_template",
  importExcelApiUrl: "bx_block_data_import_export/catalogue_import/excel_import",

};

exports.productTitle = "Products"
exports.subTitle ="Subtitle"
exports.SpaceDeletIcon ="Space Delete Icon"

exports.Strings = {
  token: "token",
  productList: {
    exportimport: "Export/Import"
  },
  addPriceList: {
    conformDeactiveText: "Confirm Deactivate",
    cancel: "Cancel",
    deactive: "Deactive",
    name: "Name",
    price_list_type: "Price List Type",
    price_list_to_copy: "Price List To Copy",
    tax_type: "Tax Type",
    section: "Section",
    pricelistAddedSuccess: "Pricelist added successfully.",
    add: "Add",
    addBy: "Added By",
    addPriceListText: "Add New Price List",
    product: "Product",
    emptyPriceListErrorMsg: "Please select price list type from where you want to copy.",
    noProductText: "No products found!",
    selectProductText: "Select Product",
    test: "test"
  },
  priceCodeList: {
    view: "View",
    active: "Active",
    deactivate: "Deactivate",
    deactivated: "Deactivated",
    pageTitle: "Price List",
    addPriceList: "Add Price List",
    noRecordFounds: "No records found!!",
    priceListType: "Price List Type",
    priceListName: "Price List Name",
    priceListCopy: "Price List to Copy",
    taxType: "Tax Type",
    section: "Section",
    addedBy: "Added By / Reduced By",
    status: "Status",
    conformDeactiveText: "Confirm Deactivate",
    confirmButtonText: "Back to Listing",
    pricelistDeactivateMessage: "Price List Deactivated "
  },
  priceCodeListView: {
    token: "token",
    pageTitle: "Price List Information",
    priceListName: "Price List Name",
    priceListType: "Price List Type",
    priceListCopy: "Price List to Copy",
    taxType: "Tax Type",
    section: "Section",
    addedBy: "Added By",
    product: "Products",
    cancel: "Cancel",
    noSection: "No sections found",
    noProduct: "No product found",
    save: "Save",
    edit: 'Edit',
    deactivate: "Deactivate",
    addProduct: 'Add Product',
    noProductMessage: 'No Product Found',
    pricelistSuccess: "Price updated successfully!",
  }
};
exports.priceAddFaild = "Add Price Faild"
exports.measurementText = "Measurement"
exports.addsaleText = "Add Sale Price"
exports.testCoverage = "testCoverage"
exports.productSqm = "SQM"
exports.productWeight = "Weigh"
exports.listBoxHeight = "170px"
exports.genTest = "genTest"
exports.serviceValidation = "serviceValidation"
exports.measurementFiledLabel = "Measurement*"
exports.styleExport = "Export button*"
exports.ButtonIssue = "BackAndEditButton"
exports.PriceListPerformance = "Performance"
exports.AddProductError = "Error Object Add Product"
exports.SectionTooltip = "Set Section Tooltip"
exports.NoServiceFound = "No Service Found"
// Customizable Area End