import React from "react";
// Customizable Area Start
import { Styles } from "@material-ui/styles";
import { withStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core";
import OrderSummaryController, { Props, Transaction } from "./OrderSummaryController.web";

import { CrossIcon, EyeIcon, PrintIcon } from "./assets";
import { getCurrencyString } from "../../catalogue/src/utils";
import PrintModal from "./PrintModal.web";
import { colors } from "../../utilities/src/Colors";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";

// Customizable Area End

export default class  OrderSummary extends OrderSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderRow = (label: string, value: string | number | undefined) => {
    return (
      <div style={webStyle.row}>
        <Lablediv>{label}</Lablediv>
        <CollonDiv>:</CollonDiv>
        <ValDiv>{value}</ValDiv>
      </div>
    );
  };

  getPaymentText = () => {
    if (!this.props.order) return "";
    return this.props.order.order_transactions
      .map((transaction: Transaction) => {
        const paymentOption = this.state.paymentOptions.find(
          (option) =>
            option.id === Number(transaction.attributes.payment_method.id)
        );

        if (paymentOption) {
          return `${paymentOption.name}-${getCurrencyString(
            transaction.attributes.amount,
            this.props.order ? this.props.order.currency : "SAR"
          )}`;
        }
        return "";
      })
      .filter((item: any) => item)
      .join(", ");
  };

  getPaidOption = () => {
    const isPaid = this.props.order?.paid_at === null ? "Unpaid" : "Paid";
    return isPaid;
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    if (!this.props.customer) return null;
    if (!this.props.order) return null;
    return (
      <div style={{ ...webStyle.container, flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Headerdiv>
            Order #{this.props.order.order_number}
          </Headerdiv>
          <img
            data-test-id='btn-closeOrderSummary'
            style={{ cursor: "pointer", maxHeight: "25px" }}
            onClick={this.props.onClose}
            src={CrossIcon}
          />
        </div>
        {this.renderRow("Name", this.props.customer.attributes.full_name)}
        {this.renderRow(
          "Contact",
          "+" +
            this.props.customer.attributes.country_code +
            " " +
            this.props.customer.attributes.phone_number
        )}
        {this.props.order.is_quick_drop &&
          this.renderRow("Quantity", this.props.order.no_of_items)}
        {!this.props.order.is_quick_drop &&
          this.renderRow("No. of Items", this.props.order.no_of_items)}
        {!this.props.order.is_quick_drop && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {this.renderRow("No. of Pieces", this.props.order.no_of_pieces)}
            <div>
              <img
                data-test-id='btn-printModal'
                onClick={() => this.onPrintModalOpen(true)}
                style={{ marginLeft: 4 }}
                src={EyeIcon}
              />
            </div>
          </div>
        )}
        {!this.props.order.is_quick_drop &&
          this.renderRow("No. of Tags", this.props.order.no_of_pieces)}
        {!this.props.order.is_quick_drop &&
          this.renderRow("Total", `SAR ${this.props.order.total}`)}
        {!this.props.order.is_quick_drop &&
          this.renderRow("Payment", this.getPaidOption() + "(" + this.getPaymentText() + ")")}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <StyledButton
            data-test-id='btn-newOrder'
            onClick={this.props.onNewOrder}
          >
            New Order
          </StyledButton>
          {
            renderBaseOnConditions(
              !this.props.isQuickDrop,
          <StyledButton
            data-test-id="btn-printReceipt"
            style={{ ...webStyle.button }}
            onClick={this.props.onPrint}
          >
            <StyledImg src={PrintIcon} />
          </StyledButton>,
            <></>
          )
          }
        </div>
        {this.props.order && this.props.order.id >= 0 && (
          <PrintModal
            infoView={this.state.infoView}
            open={this.state.printModalOpen}
            onClose={this.onPrintModalClose}
            order={this.props.order}
            customer={this.props.customer}
          />
        )}
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    marginTop: 16,
    // width: 332,
    borderRadius: 12,
    background: "rgba(32, 75, 156, 0.10)",
    display: "flex",
    gap: 16,
    padding: 16,
  },
  header: {
    color: "#1A1A1A",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  row: {
    display: "flex",
  },
  label: {
    minWidth: 120,
  },
  button: {
    background: "rgba(32, 75, 156, 0.10)",
    color: "rgba(32, 75, 156, 1)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    borderRadius: 8,
  },
};

const StyledButton = styled(Button)({
  background: "rgba(32, 75, 156, 0.10)",
  color: "rgba(32, 75, 156, 1)",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  borderRadius: 8,
  textTransform: "none",
  "&.MuiButton-root.Mui-disabled": {
    color: colors().white,
    cursor: "not-allowed",
    opacity: 0.5,
  },
  "&:hover": {
    background: "rgba(32, 75, 156, 0.10)",
    color: "rgba(32, 75, 156, 1)",
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
})

const StyledImg = styled('img')({
  "@media only screen and (max-width: 1024px)": {
    width: "20px",
  },
})

const Headerdiv = styled('div')({
  color: colors().viewTextColor,
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
})

const Lablediv = styled('div')({
  minWidth: 120,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "14px",
  },
})

const CollonDiv = styled('div')({
  "@media only screen and (max-width: 1024px)": {
    marginLeft: "-14px",
  },
})

const ValDiv = styled('div')({
  marginLeft: 7,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "14px",
    marginLeft: 4,
  },
})
// Customizable Area End
