import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, handleLogout } from "../../../components/src/common";

// Customizable Area Start

import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import moment from "moment";

interface City {
  id: number;
  name_ar: string;
  name_en: string;
}
export interface IWebCustomer {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    activated: boolean;
    email: string;
    date_of_birth: string;
    phone_number: number;
    country_code: number;
    deactivated_platform_id: null | string;
    deactivated_reason: null | string;
    customer_profile: {
      data: {
        id: string;
        type: string;
        attributes: {
          street_address: null | string;
          city: null | City;
          employee_id: string;
          customer_id: string;
          post_code: null | string;
          business_id: {
            id: number,
            label: string,
            module_name: string
          },
          price_list: {
            data: {
              attributes: {
                name: string,
                is_master_price_list: boolean
              }
            };
          };
          region: {
            data: {
              attributes: {
                name: string
              }
            }
          }
          notes: null | string;
          gender_id: {
            id: number,
            label: string,
            module_name: string
          };
          private_note: null | string;
          customer_type: string;
          profession: {
            data: {
              id: string;
              type: string;
              attributes: {
                id: number;
                profession_unique_id: string;
                profession_name: string;
                active: boolean;
              };
            };
          };
          organisation_tax: {
            data: {
              id: string,
              type: string,
              attributes: {
                id: number,
                name: string,
                tax_number: string,
                active: boolean
              }
            }
          },
          payment: {
            id: null | string;
            name: null | string;
          };
          organization: {
            data: {
              id: string;
              type: string;
              attributes: {
                id: number;
                name: string;
                tax_number: null | string;
                active: boolean;
              };
            };
          };
          price_list_expiry_date: string;
          store_management: {
            store_name: string,
            region : {
              id: string,
              name : string
            },
            area: {
              id: string;
              name : string
            }
          }
        };
      };
    };
    user_addresses: {
      data: IUserAddress[];
    };
    customer_preferences_attributes: any[]; // You may need to specify the correct type for preferences
    order: null | string;
    saved_notes: null | string;
  };
}

export interface IUserAddress {
  id: string;
  type: string;
  attributes: {
    id: number;
    location: string;
    address: string;
    landmark: string;
    post_code: string;
    latitude: number;
    longitude: number;
    notes: null | string;
    label: null | string;
    default: boolean;
    city: {
      id: number;
      name_en: string;
      name_ar: string;
      name: string;
    };
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  customerData: IWebCustomer;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomerDetailsApiCallId: string = "";
  authToken: string = "";

  async componentDidMount() {
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }

    this.getCustomerData();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.LayoutDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      isLoading: false,
      customerData: {
        id: "",
        type: "",
        attributes: {
          full_name: "",
          activated: false,
          email: "",
          date_of_birth: "",
          phone_number: 0,
          country_code: 0,
          deactivated_platform_id: null,
          deactivated_reason: null,
          customer_profile: {
            data: {
              id: "",
              type: "",
              attributes: {
                street_address: null,
                city: null,
                employee_id: "",
                customer_id: "",
                post_code: null,
                business_id: {
                  id: 0,
                  label: "",
                  module_name: ""
                },
                region: {
                  data: {
                    attributes: {
                      name: ''
                    }
                  }
                },
                price_list: {
                  data: {
                    attributes: {
                      name: "",
                      is_master_price_list: false,
                    }
                  }
                },
                notes: null,
                gender_id: {
                  id: 0,
                  label: "",
                  module_name: ""
                },
                private_note: null,
                customer_type: "",
                profession: {
                  data: {
                    id: "",
                    type: "",
                    attributes: {
                      id: 0,
                      profession_unique_id: "",
                      profession_name: "",
                      active: false
                    }
                  }
                },
                organisation_tax: {
                  data: {
                    id: "",
                    type: "",
                    attributes: {
                      id: 0,
                      name: "",
                      tax_number: "",
                      active: false
                    }
                  }
                },
                payment: {
                  id: null,
                  name: null
                },
                organization: {
                  data: {
                    id: "",
                    type: "",
                    attributes: {
                      id: 0,
                      name: "",
                      tax_number: null,
                      active: false
                    }
                  }
                },
                price_list_expiry_date: "",
                store_management: {
                  store_name: "",
                  area: {id: "", name: ""},
                  region: {id: "", name: ""},
                }
              }
            }
          },
          user_addresses: {
            data: []
          },
          customer_preferences_attributes: [],
          order: null,
          saved_notes: null
        }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.getDataFromLayout(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.getGenderDropdownOptionApiCallResponse(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  checkGetResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  getGenderDropdownOptionApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getCustomerDetailsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ customerData: responseJson.data, isLoading: false })
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  getCustomerData() {
    this.setState({isLoading: true})
    let getCustomerId: any = this.props.navigation.getParam('customerId');

    let url = `${configJSON.addNewCustomerApiEndPoint}/${getCustomerId}`;
    let headers: any = {
      token: this.authToken,
      "Content-type": "application/json",
    }

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getCustomerDetailsApiMethod,
    });
    this.getCustomerDetailsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleBackToListing = () => {
    this.props.navigation.navigate("PeopleManagement2");
  }

  handleEditCustomer = (getCustomerId: any) => {
    this.props.navigation.navigate("CustomerEdit", {customerId: getCustomerId});
  }

  getPriceListExpiryDate = (expirydate: string) => {
    const formattedDate = moment(expirydate).format("DD-MM-YYYY")
    return formattedDate;
  }
  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.customerPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };
  // Customizable Area End
}
