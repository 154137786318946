/** @format */

import React from "react";
// Customizable Area Start
import SpecificationListController, { 
  Product,
  Props,
  Section,
  Service, 
  SpecificationData
} from "./SpecificationListController.web"
import "./PreferenceList.css"
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CustomPagination from "../../../components/src/customComponents/CustomPagination.web";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
export const configJSON = require("./config");
import { filterIcon } from "./assets"
import FilterPopover from "../../../components/src/FilterPopover";
import CircularProgress from "@material-ui/core/CircularProgress"; 
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Popover,
  IconButton,
  styled
  } from "@material-ui/core";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import SortingTableHeader from "../../../components/src/SortingTableHeader2";
import SortingTableCell from "../../../components/src/SortingTableCell";
import { ProductShowTip } from "../../catalogue/src/HomeCleaningProduct.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import {
  renderBaseOnConditions 
} from "../../../blocks/utilities/src/CustomBlockHelpers";

const StyledBox = styled(Box)({
  margin: '0 10px',
  "@media only screen and (max-width: 1024px)": {
  
    "& #IconButton": {
      textAlign: "center",
      fontSize: "11.38px",
      fontWeight: 600,
      padding:'10.5px !important',
      height: 'max-content !important',
    },

  },
});

const StyledWrapper = styled("div")({
  "& .CustomPagination": {
    margin: "32px 0"
  },
  "& .noRecordFound": {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 600,
  },
  "& .button-add-spec": {
    height: "100%"
  },
  "& .export-btn": {
    height: "100% !important"
  },
  "& .circularDiv": {
    width: '100%',
    padding: '15px 0',
    textAlign: 'center'
  },
  "& .filterButtonSpec": {
    display:"grid",
    placeItems:"center",
    "borderRadius": "15px",
    "border": "solid 1px rgba(32, 75, 156, 1)",
    "cursor": "pointer",
    "marginRight": "24px",
    height:"56px",
    width:"56px",
    "@media only screen and (max-width: 1024px)": {
      width: "44px",
      height: "44px",
    },
  },
  "& .filterButtonSpecification": {  
    marginRight: "24px",
    cursor: "pointer",
    background: colors().white,
    height: "56px",
    color:  colors().darkliver,
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor:colors().cyancobaltblue,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
    },
  },
  "& .addButton": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px !important",
    lineHeight: "24px",
    color: colors().white,
    height: "56px !important",
    width: "184px !important",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "11.38px !important",
      width: "160px !important",
      height: "44px !important",
      padding: "12px !important",
      alignItems: 'center',
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      fontWeight: 600
    },
  },
  "& .popoverContainer":{
    zIndex: 1, 
    width: '140px',
    position: 'absolute', 
    background: '#ffffff', 
    top: '23px', 
    right: '32px', 
    padding: '15px 0', 
    borderRadius: '8px', 
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)'
  }
});

const StyledConatiner = styled('div')({
  margin: '0',
  padding: '0'
});

const SpacHeading = styled("p")({
  fontWeight: 600,
  lineHeight: "30px",
  color: colors().black,
  fontSize: "24px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    fontWeight: 600,
    color: colors().black,
  },
});

const MainModalContainer = styled("div")({
  paddingTop: "16px",
  paddingBottom: "16px",
  boxShadow:
    "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
  borderRadius: "8px",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "14px",
  "& .popoverButton": {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  "& .noPermission": {
    padding: "0 8px"
  },
});

const Strings = configJSON.Strings.specificationList;
// Customizable Area End

// Customizable Area Start
class SpecificationList extends SpecificationListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    getMenuPopoverData() {
      const { openAction, currentPopver, permissionStatus } = this.state;
      const menuOpen = Boolean(this.state.openAction);
      const menuPopoverId = menuOpen ? "simple-popover" : undefined;
  
      return (
        <Popover
          data-test-id='popver'
          id={menuPopoverId}
          open={menuOpen}
          anchorEl={openAction}
          onClose={this.handleCloseMenuPopver}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {
            renderBaseOnConditions(
              (permissionStatus.viewPermission ||
                permissionStatus.editPermission ||
                permissionStatus.deactivatePermission),
              <MainModalContainer className='popoverContainer'>
                {
                  renderBaseOnConditions(
                    permissionStatus.viewPermission,
                    <Box
                      onClick={() =>
                        this.handleViewSpecification(
                          currentPopver as SpecificationData
                        )
                      }
                      data-test-id='viewButton'
                      className='popoverButton'
                    >
                    {Strings.view} 
                    </Box>,
                    <></>
                  )
                }
                {
                  renderBaseOnConditions(
                    permissionStatus.editPermission,
                    <Box
                      onClick={() =>
                        this.handleEditSpecification(
                          currentPopver as SpecificationData
                        )
                      }
                      data-test-id='editButton'
                      className='popoverButton'
                    >
                      {Strings.edit}
                    </Box>,
                    <></>
                  )
                }
                {
                renderBaseOnConditions(
                  permissionStatus.deactivatePermission && currentPopver?.attributes.active as boolean,
                  <Box
                  data-test-id='deactiveButton'
                    onClick={() =>
                      this.handleOpenDeactivateModal(
                        currentPopver as SpecificationData
                      )
                    }
                    className='popoverButton'
                  >
                    {Strings.deactivate}
                  </Box>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  permissionStatus.activatePermission as boolean && !currentPopver?.attributes.active,
                  <Box
                  onClick={() =>
                    this.handleOpenDeactivateModal(
                      currentPopver as SpecificationData
                    )
                  }
                  className='popoverButton'
                  data-test-id='deactiveButton'
                  >
                    {Strings.activate}
                  </Box>,
                  <></>
                )
              }
              </MainModalContainer>,
              <MainModalContainer className='popoverContainer'>
                <Box className='noPermission'>
                  No Permissions
                </Box>
              </MainModalContainer>
            )
          }
        </Popover>
      );
    }
    showSectionData(item: Section) {
        return this.handleSection(item).length > 2 ? (
          <ProductShowTip title={<span>{this.handleSection(item).join(", ")}</span>} arrow>
            <span>{this.handleSection(item).splice(0, 2).join(", ")}...</span>
          </ProductShowTip>
        ) :
        <span>{this.handleSection(item).join(", ")}</span>;
    }
    showServicesData(item: Service) {
        return this.handleService(item).length > 2 ? (
          <ProductShowTip title={<span>{this.handleService(item).join(", ")}</span>} arrow>
            <span>{this.handleService(item).splice(0, 2).join(", ")}...</span>
          </ProductShowTip>
        ) :
        <span>{this.handleService(item).join(", ")}</span>;
    }
    showPoductData(item: Product) {
        return this.handleProduct(item).length > 2 ? (
          <ProductShowTip title={<span>{this.handleProduct(item).join(", ")}</span>} arrow>
            <span>{this.handleProduct(item).splice(0, 2).join(", ")}...</span>
          </ProductShowTip>
        ) :
        <span>{this.handleProduct(item).join(", ")}</span>;
    }
    renderTabelBody = (
      isLoading: boolean,
      specificationList: SpecificationData[],
      menuPopoverId: string | undefined
      ) => {
      if(isLoading) {
        return (
         <TableRow>
           <TableCell colSpan={5}>
            <div className="circularDiv">
              <CircularProgress />
            </div>
           </TableCell>
          </TableRow>
        )
      } else {
        if(specificationList.length === 0) {
          return (
            <TableRow>
              <TableCell colSpan={5}>
                <div className='noRecordFound'>
                  {Strings.noRecordFound}
                </div>
              </TableCell>
            </TableRow>
          )
        } else {
          return specificationList.map((item) => {
            return(
              <TableRow key={item.id}>
                <SortingTableCell
                  width='15vw'
                  type='left'
                  content={
                    item.attributes.name
                  }
                />
                <SortingTableCell
                  width='24vw'
                  type='middle'
                  content={this.showSectionData(item.attributes.sections)}
                />
                <SortingTableCell
                  width='24vw'
                  type='middle'
                  content={this.showServicesData(item.attributes.services)}
                />
                <SortingTableCell
                  width='24vw'
                  type='middle'
                  content={this.showPoductData(item.attributes.products)}
                />
                <SortingTableCell
                  width='8vw'
                  type='right'
                  content={
                    item.attributes.active ? Strings.activate : Strings.deactivated
                  }
                />
                <SortingTableCell
                  width='5vw'
                  type='action'
                  content={
                    <IconButton
                      data-test-id={`more-menu-${item.id}`}
                      aria-describedby={menuPopoverId}
                      onClick={(event) => this.handlepopver(event, item)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                />
              </TableRow>
            )
          })
        }
      }
    }
    
    render() {
      const { 
        specificationList,
        open,
        deactivateModal,
        deActivatedList ,
        meta,
        sortingData,
        isLoading
      } = this.state

      const openAction = Boolean(this.state.openSpecificationImpExtPopoverAction);
      const popOverId = openAction ? "simple-popover" : undefined;

      const menuOpen = Boolean(this.state.openAction);
      const menuPopoverId = menuOpen ? "simple-popover" : undefined;

      return (
        <>
          <StyledWrapper>
            <StyledConatiner className="preference-list-container">
              <div className="preference-top">
                <SpacHeading>{configJSON.specificationTitle}</SpacHeading>
                <div className="filtergroup">
                  <div
                    data-test-id="filterBtn"
                    className="filterButtonSpec"
                    onClick={(event) => { this.onFilterClickHandler(event) }}
                  >
                    <img src={filterIcon} />
                  </div>
                  <FilterPopover
                    onClose={this.handleClosePopOver}
                    anchor={this.state.filterAnchor as HTMLDivElement}
                    onFilterChange={this.handleFilterChange}
                    filters={this.state.filters}
                  />
                  {
                    renderBaseOnConditions(
                      this.state.permissionStatus.exportPermission as boolean,
                      <StyledBox component={'div'}>
                      <IconButton
                        style={{width:"auto"}}
                        data-test-id={`export-import-btn`}
                        id={"IconButton"}
                        aria-describedby={popOverId}
                        onClick={(event) => { this.onIconClickHandler(event) }}
                        className="export-btn"
                      >
                        {configJSON.exportimportText} {this.state.openSpecificationImpExtPopoverAction ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </StyledBox>,
                      <></>
                    )
                  }
                  <ExportImportPopover
                    popOverId={popOverId}
                    openAction={openAction}
                    popoverOpen={this.state.openSpecificationImpExtPopoverAction}
                    popoverClose={() => { this.handleCloseImportExportPopover() }}
                    exportTemplateButtonClick={() => this.handleSpecificationExportTemplate()}
                    importCsvButtonClick={() => this.navigateToImportModalScreen()}
                    exportCsvButtonClick={() => this.handleExportCsvFile()}
                  />
                  {
                    renderBaseOnConditions(
                      this.state.permissionStatus.createPermission,
                      <Box>
                        <button 
                          onClick={this.handleAddSpecification} 
                          className="addButton" 
                          data-test-id="add-specification"
                        >
                          {configJSON.buttonAddSpecification}
                        </button>
                      </Box>,
                      <></>
                    )
                  }
                </div>
              </div>
                <TableContainer component={Paper} className='tableContainer'>
                  <Table className='table' aria-label='customized table'>
                    <TableHead>
                      <TableRow className='tableRow'>
                        <SortingTableHeader
                          sortingData={sortingData}
                          title={Strings.specificationName}
                          sortingKey='name'
                          {...this.sortingProps}
                          type='left'
                          width='15%'
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title={Strings.sections}
                          sortingKey='section_name'
                          {...this.sortingProps}
                          type='middle'
                          width='24%'
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title={Strings.services}
                          sortingKey='service_name'
                          {...this.sortingProps}
                          type='middle'
                          width='24%'
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title={Strings.products}
                          sortingKey='product_name'
                          {...this.sortingProps}
                          type='middle'
                          width='24%'
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title={Strings.status}
                          sortingKey=''
                          {...this.sortingProps}
                          type='middle'
                          sortingDisabled
                          width='8%'
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title=''
                          type='action'
                          sortingKey=''
                          {...this.sortingProps}
                          width='5%'
                          sortingDisabled
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { this.renderTabelBody(isLoading, specificationList, menuPopoverId) }
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className="CustomPagination">
                  <CustomPagination
                    currentPage={meta.current_page}
                    totalPages={meta.total_pages}
                    totalItems={meta.total_count}
                    goToNextPage={this.nextPage}
                    goToPreviousPage={this.previousPage}
                    itemsPerPage={10}
                  />
                </Box>
                {this.getMenuPopoverData()}
            </StyledConatiner>
          </StyledWrapper>
          <ConfirmationModal
            open={open}
            handleClose={this.handleCloseModal}
            handleConfirm={this.handleDeActivateClick}
            title={configJSON.confirmDeactivate}
            message={deActivatedList}
            confirmText={configJSON.confirmText}
            dynamic={true}
          />

          {deactivateModal && (
            <ConfirmationModal
              open={deactivateModal}
              handleClose={this.handleCloseModal}
              handleConfirm={this.handleGoBackToListing}
              title={configJSON.deactivateSpecificationMessage}
              message={deActivatedList}
              confirmText={configJSON.confirmButtonText}
              displayItem={true}
            />
          )}
          <CustomSnackbar
            open={this.state.errorSnackbarOpen}
            onClose={this.handleSnackbarClose}
            errorMessage={this.state.errorMessage}
            severity={this.state.snakcbarSeverity}
          />
        </>
      );
    }
    // Customizable Area End
}
export default SpecificationList;

// Customizable Area End
