// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  checkForNewPermissonStatus,
  PermissionStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

// Customizable Area Start
export const configData = require("./config");
import { ClassNameMap } from "@material-ui/styles";
import {DropResult} from 'react-beautiful-dnd'
import { SectionType, SectionListType} from "./SevicesTypes"
import { apiCall, makeApiMessage } from "../../../components/src/common";
import { navigateTo } from "../../../../packages/blocks/utilities/src/CustomBlockHelpers";
enum DefaultValues  {
  defaultPage= 1,
  defaultQuery='',
  debounceTime= 1000

}
interface IServiceList {
  data: RawServiceListType[]
  meta: {
    current_page: number
    next_page: number
    pervious_page: number | null
    total_count: number
    total_pages: number
  }
}

interface ClickedServiceRowType {
  id: string;
  name: string;
  service_group_ids: Array<string>;
}

interface ServiceDropdownItem {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    icon: string,
    name_translation: string
  }
}

export interface RawServiceListType {
  id: string,
  name: string,
  attributes: {
    id: string,
    name: string,
    second_name: string,
    order_number: number,
    active: boolean,
    icon_id: number,
    online_order: boolean,
    icon: {
      id: string,
      name: string,
      updated_at: string,
      created_at: string,
      second_name: null | string,
      image_type: null | string
    },
    sections: {
      data: SectionType[]
    },
    service_group_ids: Array<string>
  }
}

export interface ServiceListType {
  id: string,
  name: string,
  secondName: string,
  active: boolean,
  orderNumber:number,
  onlineOrder: boolean,
  sectionList: string[]
  service_group_ids: Array<string>
}

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: ClassNameMap;
  // Customizable Area End
}

interface S {
  token: string;
  // Customizable Area Start
  permissionStatus: PermissionStatus;
  isLoadingPermission: boolean;
  threeDotOptions:string[];
  userOptions: string[],
  selectedType: string,
  errorMessage: string,
  loading: boolean,
  selectedOptions: string[] | string,
  serviceList: ServiceListType[],
  sectionLists: SectionListType[],
  openModal: boolean,
  deActivateServiceSucess: boolean,
  selectedServiceId: string,
  currentPage: number,
  totalPages: number,
  perPage: number,
  totalCount:number,
  messageText: {
      id:string,
      title:string
  }
  selectedTabs: string,
  sections : {title:string, id:string, checked:boolean}[],
  initialSections: {title:string, id:string, checked:boolean}[],
  isFilterSelected:boolean;
  textValue:string;
  isLoading: boolean;
  isFilterSelectedFirstTime:boolean,
  sortByServiceName: {
     value: string
  },
  sortBySectionName: {
     value: string
  },
  filterParam:string | null,
  currentTabValue: number,
  filters: {
    serviceName: { key?: string, value?: string},
    section:{key?: string, value?: string},
    status: {key?: string, value?: string},
    order: {key?: string, value?: string},
  };
  isDragableDisabled:boolean;
  optionsList: { label: string, value: string }[];
  openSectionGroupModal: boolean;
  clickedServiceRowData: ClickedServiceRowType | null;
  selectedServiceGroupId: Array<string>;
  serviceDropdownList: Array<ServiceDropdownItem>;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ServicesListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getIconsCallId: string = "";
  getImagesCallId: string = "";
  createIconsCallId: string = "";
  getServiceListCallId: string = "";
  getSectionListCallId: string = "";
  addServiceCallId: string = "";
  getEditPreFilledCallId: string = "";
  deActivateSericeCallId: string = "";
  activateSericeCallId: string = "";
  dragDropCallId:string = "";
  serviceSuggessionApiCallId:string="";
  getServiceDropdownListCallId: string = "";
  putServiceByGroupApiCallId: string = "";
  debounceTimer: number | null | NodeJS.Timeout = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.SearchTextMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      isLoadingPermission: true,
      isLoading: false,
      openSectionGroupModal: false,
      clickedServiceRowData: null,
      selectedServiceGroupId: [],
      serviceDropdownList: [],
      threeDotOptions:[],
      userOptions:[],
      selectedType: '',
      token: '',
      loading: false,
      selectedOptions: [],
      errorMessage: "",
      serviceList: [],
      sectionLists: [],
      openModal: false,
      deActivateServiceSucess: false,
      selectedServiceId: '',
      currentPage: 0,
      totalPages: 0,
      perPage: 10,
      totalCount:0,
      messageText:{
        id:'',
        title:''
      },
      selectedTabs:'',
      sections:[],
      initialSections: [],
      isFilterSelected:false,
      textValue:'',
      isFilterSelectedFirstTime:false,
      sortByServiceName: {
        value: ''
     },
     sortBySectionName: {
      value: ''
   },
   filterParam: '',
   currentTabValue: 0,
   filters : {
    serviceName: {},
    section:{},
    status: {},
    order: {},
   },
   isDragableDisabled:false,
      optionsList: []
    };
    // Customizable Area End
  }

  
  // Customizable Area Start
async receive(from: string, message: Message) {
  runEngine.debugLog("Message Received", message);
  this.acquireLayoutData(message)
  this.receiveSearchTextData(message)
  if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
    return;
  }

  const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

  if (apiRequestCallId === this.getServiceListCallId) {
    this.handleServiceListResponse(responseJson);
  } else if (apiRequestCallId === this.deActivateSericeCallId) {
    this.handleDeactivateServiceResponse(responseJson,message);
  }
  else if(apiRequestCallId === this.getSectionListCallId){
       this.handleSectionListResponse(responseJson)
  }
  else if(apiRequestCallId === this.serviceSuggessionApiCallId){
       this.handleServiceSuggestionResponse(responseJson)
  }
  else if(apiRequestCallId === this.getServiceDropdownListCallId) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const clickedId = this.state.clickedServiceRowData?.id;
    this.setState({
      serviceDropdownList: apiResponse.data
        .sort(
          (first: ServiceDropdownItem, second: ServiceDropdownItem) =>
            Number(first.id) - Number(second.id)
        ).filter((item: ServiceDropdownItem) => item.id !== clickedId),
    });
  } else if(apiRequestCallId === this.putServiceByGroupApiCallId) {
    this.setState({
      openSectionGroupModal: false,
      selectedServiceGroupId: [],
      clickedServiceRowData: null
    });
    this.getServiceList(DefaultValues.defaultPage, DefaultValues.defaultQuery)
  }
  else if(apiRequestCallId === this.activateSericeCallId){
    this.getServiceList(DefaultValues.defaultPage, DefaultValues.defaultQuery);
}
  
}

acquireLayoutData = (message: Message) => {
  if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
          getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        this.handleUserChange(recievedData.userContext)
      }
  }
}

receiveSearchTextData = (message: Message) => {
  if (message.id === getName(MessageEnum.SearchTextMessage)) {
      const recievedData = message.getData(
          getName(MessageEnum.SearchMessageText)
      );
      if (recievedData) {
        this.onChangeValue(recievedData.searchText)
      }
  }
}

handleServiceListResponse(responseJson: IServiceList) {
  this.setState({ loading: false, serviceList: [] });



  if (responseJson) {
    const parsedServiceList: ServiceListType[] = [];


              this.setState({
               totalPages: responseJson?.meta?.total_pages,
               currentPage: responseJson?.meta?.current_page,
               totalCount: responseJson?.meta?.total_count
               })



    responseJson?.data?.forEach((item: RawServiceListType) => {
      const serviceItem: ServiceListType = {
        id: item.id,
        name: item.attributes.name,
        secondName: item.attributes.second_name,
        active: item.attributes.active,
        onlineOrder: item.attributes.online_order,
        sectionList: this.getAllSectionName(item.attributes.sections.data),
        orderNumber: item.attributes.order_number,
        service_group_ids: item.attributes.service_group_ids
      };

      parsedServiceList.push(serviceItem);
    });

   

    this.setState({isLoading:false, serviceList: parsedServiceList });
  }
}

handleRedirect = () => {
  navigateTo({props: this.props, screenName: 'AddServices'})
}

handleDeactivateServiceResponse(responseJson: {errors: [] | undefined}, message:Message) {
  this.setState({ loading: false, openModal: false });

  if (responseJson !== undefined && responseJson?.errors === undefined) {
    const updatedService = this.state.serviceList.map((service: ServiceListType) => {
      if (service.id === this.state.selectedServiceId) {
        service.active = false;
      }

      return service;
    });

    this.setState({ deActivateServiceSucess: true, serviceList: updatedService });
  } else {
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
  }
}





async componentDidMount() {
    super.componentDidMount();
    this.getServiceList(DefaultValues.defaultPage, DefaultValues.defaultQuery);


  }

  
  componentDidUpdate(prevProps:Props, prevState:S){
      if (prevState.textValue !== this.state.textValue 
        || prevState.sortBySectionName !== this.state.sortBySectionName || prevState.sortByServiceName !== this.state.sortByServiceName
        || prevState.filters !== this.state.filters
        ) {
      this.handleDeActivateDragable()
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.servicePermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    value.viewPermission && this.state.threeDotOptions.push("View")
    value.editPermission && this.state.threeDotOptions.push("Edit")
    value.deactivatePermission && this.state.threeDotOptions.push("Deactivate")
    value.activatePermission && this.state.threeDotOptions.push("Activate")
    !value.activatePermission && !value.editPermission && !value.viewPermission && !value.deactivatePermission && this.state.threeDotOptions.push("No permission") 
    this.setState({
      permissionStatus: value,
      isLoadingPermission:false
    })
  };

  token = localStorage.getItem('token');

  getSelectedTabValue = (value: { order: {}, section: {}, serviceName: {}, status: {}}) => {

   

    this.setState({
      filters: {...value},
    });

  
  };

  filterSelectedSectionsIds =  ()=>{
       
    const selectedIds  =  this.state.sections.reduce((accum: string[],item: {id: string, title: string, checked: boolean})=>{
       if(item.checked===true){
       return  [...accum, item.id]
       }
       return accum
    }, [])
     
    
   return selectedIds;
  
  }


  clearFilter = ()=>{

     this.getServiceList(DefaultValues.defaultPage ,DefaultValues.defaultQuery, this.state.sortByServiceName.value, this.state.sortBySectionName.value);
     this.getSectionLists() 
     this.setState({
      filters: {
        serviceName: {},
    section:{},
    status: {},
    order: {},
      },
      filterParam:null
      
     })
  }


 


  handlePagination = (pageNumber:number)=>{

  
    if(this.state.isFilterSelected ){
     
      this.callServiceFilterApi(pageNumber);
     
    }
    else{
        this.getServiceList(
          pageNumber, 
          this.state.textValue, 
          this.state.sortByServiceName.value, 
          this.state.sortBySectionName.value );
       
    }
}


  onChangeValue = (value:string) => {
    this.setState({
      textValue: value
    })
  
      this.debounce(this.handleDebouncedChange, DefaultValues.debounceTime);
  
    }


 handleDebouncedChange = () => {
   this.searchByText(this.state.textValue)
  };
  
  debounce = (func: () => void, delay: number ) => {
    if (this.debounceTimer &&  typeof this.debounceTimer === "number") {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(func, delay);
  };

  handleSectionListResponse = (responseJson:{ data: { id: string, type: string, attributes: { id: number, name: string, section_name: string}}[], errors: []})=>{
     

    if(!responseJson.errors){
      
      const parsedSectionsList =  responseJson.data.map((item: { id: string, type: string, attributes: { id: number, name: string, section_name: string}})=>{
              
        return {
             id: item.id,
             title: item.attributes.section_name,
             checked:false
        }
      })

      this.setState({
        sections:parsedSectionsList,
        initialSections:parsedSectionsList
      })

    }
    
  }

  handleServiceSuggestionResponse = (responseJson: {filter_names: string[]}) => {
    const list = responseJson?.filter_names?.map((value: string) => ({ label: value, value })) || [];
    this.setState({ optionsList: list });
  }

  callServiceFilterApi = (pageNumber:number=DefaultValues.defaultPage)=>{
     
    this.setState({
      currentPage:pageNumber
  })
    const selectedTab =  this.state.filters;

  

    const queryEncoded =  this.filterSelectedSectionsIds().map((item: string | number)=>{
     return `${selectedTab.section.key}=${item}`;
})
  

const params = `${
  selectedTab?.order?.key ? `&${selectedTab.order.key}=${selectedTab.order.value}` : ''
}${
  selectedTab?.section?.key && queryEncoded.length > 0 ? `&${queryEncoded.join('&')}` : ''
}${
  selectedTab?.serviceName?.key ? `&${selectedTab.serviceName.key}=${selectedTab.serviceName.value}` : ''
}${
  selectedTab?.status?.key ? `&${selectedTab.status.key}=${selectedTab.status.value}` : ''
}`;





   this.setState({
     filterParam:params
   })    

 this.getServiceList(
  pageNumber, 
  this.state.textValue, 
  this.state.sortByServiceName.value, 
  this.state.sortBySectionName.value, 
  params)

}



  getSectionLists = () => {
    const headers = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.token,
    };

    const sectionListDataMessages = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSectionListCallId = sectionListDataMessages.messageId;

    sectionListDataMessages.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSectionListsAPIEndPoint}?dropdown=true`
    );

    sectionListDataMessages.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    sectionListDataMessages.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
    );
    runEngine.sendMessage(sectionListDataMessages.id, sectionListDataMessages);
}


searchByText = (value:string)=>{

  this.getServiceList(1, value, this.state.sortByServiceName.value, this.state.sortBySectionName.value, this.state.filterParam )
    
}



  updateSectionLists = (itemId:string)=>{
       
    const updatedSection =  this.state.sections.map((item)=>  item.id === itemId ? {...item, checked: !item.checked}: item)
    
    this.setState({
       sections:updatedSection 
    })
  }


  handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const items = Array.from(this.state.serviceList);
    const [reorderedItem] = items.splice(source.index, 1);

    items.splice(destination.index, 0, reorderedItem);

    this.setState({ serviceList: items });

   const dragAndDropData =  this.parseDragAndDropData(items)
    this.updateHandleDragDrop(dragAndDropData) 



  };


  parseDragAndDropData = (serviceList:ServiceListType[])=>{
   
    const allOrderNumber  = serviceList.map((item:ServiceListType)=> item.orderNumber)

    const getMaxOrderNumber = this.getMaxOrderNumber(allOrderNumber);



    
  const draggedData =  serviceList.reduce((accum: { id: number; order_number: number }[],current:ServiceListType,index:number)=>{
           const tempObj =  {
                  "id": parseInt(current.id),
                  "order_number":  getMaxOrderNumber - index

           };
          
           
           return [...accum, tempObj]


    },[])

   

return draggedData;

};



 getMaxOrderNumber =  function(orderNumbers:number[]){

    return Math.max(...orderNumbers)
}

 buildQueryParams = (query: string | undefined, 
  sortByName: string | undefined, 
  sortBySectionName: string | undefined, 
  filter?: string | null)=> {
  const queryParams = [];

  if (query) {
    queryParams.push(`&filter_by[query]=${query}`);
  }

  if (sortByName) {
    queryParams.push(`&name=${sortByName}`);
  }

  if (sortBySectionName) {
    queryParams.push(`&category_name=${sortBySectionName}`);
  }

  queryParams.push(filter);

  return queryParams.join('');
}



getServiceList = (pageNumber:number=1, query?:string, sortByName?:string, sortBySectionName?:string, filter?: string | null) => {
  this.setState({isLoading: true})
  const sortNameKey = this.getParamsValue(sortByName || "");
  const sortSectionKey = this.getParamsSectionNameValue(sortBySectionName || "");
  const apiEndpoint = configJSON.getServiceListAPIEndPoint;
  const queryParams = this.buildQueryParams(query, sortNameKey, sortSectionKey, filter);

  const apiUrl = `${apiEndpoint}?page_no=${pageNumber}&per_page=${this.state.perPage}${queryParams}`; 

    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.token,
    };

    const servicesListDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getServiceListCallId = servicesListDataMessage.messageId;

    servicesListDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       apiUrl
    );

    servicesListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    servicesListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(servicesListDataMessage.id, servicesListDataMessage);
  }

  setServiceName = (value: string) => {
    let headers = {
      "Content-type": "application/json", 
      token: this.token
    }

    let apiUrl;
    apiUrl = configJSON.serviceSuggestionAPIEndPoint + `?service_name=${value}`

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.httpGetType,
    });

    this.serviceSuggessionApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  updateHandleDragDrop = (updateData: { id: number; order_number: number }[])=>{
     

      const header  = {
           "Content-Type": configData.categoryApiContentType,
           token: this.token
      }

      const  body =  JSON.stringify({
           "data":{
              "attributes": updateData
           }
      })

      const addDragDropMsg =  new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.dragDropCallId =  addDragDropMsg.messageId
     
      addDragDropMsg.addData(
           getName(MessageEnum.RestAPIRequestBodyMessage),
           body
      )

      addDragDropMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configData.dragDropAPIEndPoint
    );

      addDragDropMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
      addDragDropMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
      runEngine.sendMessage(addDragDropMsg.id, addDragDropMsg);

  } 





  getAllSectionName = (items: SectionType[]) => {

    const parsedSectionName: string[] = []

    items.forEach((item: SectionType) => {
      parsedSectionName.push(item.attributes.section_name)
    })

    return parsedSectionName

  }


getSelectedOption = (values: string, item: {id: string, name: string}) => {
   
    if (values === 'Edit') {
      this.props.navigation.history.push(`/Products-ServicesListEdit/${item.id}`, { state: item.id })
    }

    if (values === "View") {
      this.props.navigation.history.push(`/Products-ServicesListView/${item.id}`, { state: item.id })
    }

    if (values === 'Deactivate') {
      const result = {
        id: item.id,
        title: item.name
      };
      this.setState({
        openModal: true,
        selectedServiceId: item.id,
        messageText: result
        
      })

    }
    if(values === "Activate"){
      this.activateService(item.id)
    }
  }


deActivateService = (urlId: string) => {

    this.setState({
      loading: true
    })


    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.token
    }



    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deActivateSericeCallId = getDataMsg.messageId;



    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addServiceListAPIEndPoint}/${urlId}/${configData.deactivate}`
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);

  }

  activateService = (urlId: string) => {

    this.setState({
      loading: true
    })

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.token
    }
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.activateSericeCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addServiceListAPIEndPoint}/${urlId}/${configData.activateEndPoint}`
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);

  }

// Function to determine the value of 'res' for sorting by service name
 getServiceNameSortingValue = (currentValue:string) => {
  if (currentValue === "") {
    return { value: "serviceNameUp" };
  } else if (currentValue === "serviceNameUp") {
    return { value: "serviceNameDown" };
  } else {
    return { value: "" };
  }
};

// Function to determine the value of 'params' for sorting by service name
 getParamsValue = (currentValue:string) => {
 
  if (currentValue === "serviceNameUp") {
    return "asc";
  } else if (currentValue === "serviceNameDown") {
    return "desc";
  } else {
    return "";
  }
};

handleSortByName = () => {
  const response = this.getServiceNameSortingValue(this.state.sortByServiceName.value);

  this.setState({
    sortByServiceName: response,
    sortBySectionName: {
      value: '',
    },
   
  });

 this.getServiceList(1, this.state.textValue, response.value, '', this.state.filterParam);
};


// Function to determine the value of 'res' for sorting by section name
 getSectionNameSortingValue = (currentValue:string) => {
  if (currentValue === "") {
    return { value: "sectionNameUp" };
  } else if (currentValue === "sectionNameUp") {
    return { value: "sectionNameDown" };
  } else {
    return { value: "" };
  }
};

// Function to determine the value of 'params' for sorting by section name
 getParamsSectionNameValue = (currentValue:string) => {
  if (currentValue === "sectionNameUp") {
    return "asc";
  } else if (currentValue === "sectionNameDown") {
    return "desc";
  } else {
    return "";
  }
};

handleSortBySectionName = () => {
  const response = this.getSectionNameSortingValue(this.state.sortBySectionName.value);

  this.setState({
    sortBySectionName: response,
    sortByServiceName: {
      value: '',
    },
  });

 
  this.getServiceList(1, this.state.textValue, '', response.value, this.state.filterParam);
};
 
handleDeActivateDragable = ()=> {
  const isServiceNameEmpty = Object.keys(this.state.filters.serviceName).length === 0;
const isSectionEmpty = Object.keys(this.state.filters.section).length === 0;
const isStatusEmpty = Object.keys(this.state.filters.status).length === 0;
const isOrderEmpty = Object.keys(this.state.filters.order).length === 0;
  if(this.state.textValue === "" 
  && this.state.sortBySectionName.value === "" && this.state.sortByServiceName.value === "" 
  && isServiceNameEmpty && isSectionEmpty && isStatusEmpty && isOrderEmpty
  ){
      this.setState({isDragableDisabled:false})
  }else {
      this.setState({isDragableDisabled:true}) 
  }
}
getCurrentTabValue =  (currentTabValue:number)=>{
       this.setState({
        currentTabValue
       })
}

clear = ()=>{
  let updatedTabsValue;
  switch(this.state.currentTabValue){
   
    case 0 : 
     updatedTabsValue =  {...this.state.filters, serviceName: {}}
    this.setState({
        filters: updatedTabsValue
    })
    break;

    case 1: 
     updatedTabsValue =  {...this.state.filters, section: {}}
     this.setState({
      filters: updatedTabsValue,
      sections: this.state.initialSections,
    })
  break;

  case 2 :
    updatedTabsValue =  {...this.state.filters, status: {}}
    this.setState({
      filters: updatedTabsValue
  })
  break;

  case 3: 
  updatedTabsValue =  {...this.state.filters, order: {}}
  this.setState({
    filters: updatedTabsValue
})
break;

  }


}

getOptionsPerRow = (status: boolean) => {
  let newOptions = []
  const permission = this.state.permissionStatus
  permission.viewPermission && newOptions.push("View")
  permission.editPermission && newOptions.push("Edit")
  if(!status){
    permission.activatePermission && newOptions.push("Activate")
  } else {
    permission.deactivatePermission && newOptions.push("Deactivate")
  }
  !permission.activatePermission && !permission.editPermission && !permission.viewPermission && !permission.deactivatePermission && this.state.threeDotOptions.push("No permission") 
  return newOptions
}

  handleOpenServiceGroup = (item: ServiceListType) => {
    this.setState(
      {
        openSectionGroupModal: true,
        clickedServiceRowData: {
          id: item.id,
          name: item.name,
          service_group_ids: item.service_group_ids,
        },
        selectedServiceGroupId: item.service_group_ids
      },
      () => this.handleFetchServices()
    );
  };

  handleCheckedService = (selectedId: string) => {
    const { selectedServiceGroupId } = this.state;
    const tempSelectedIds = [...selectedServiceGroupId];
    if (selectedServiceGroupId.includes(selectedId)) {
      const newArry = tempSelectedIds.filter(
        (sectionNumber) => sectionNumber !== selectedId
      );
      this.setState({
        selectedServiceGroupId: newArry,
      });
    } else {
      tempSelectedIds.push(selectedId);
      this.setState({
        selectedServiceGroupId: tempSelectedIds,
      });
    }
  };

  handleFetchServices = () => {
    let message = makeApiMessage({
      url: configJSON.getServiceListAPIEndPoint + "?dropdown=true",
      method: "GET",
    });
    this.getServiceDropdownListCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleCloseGroupModal = () => {
    this.setState({
      openSectionGroupModal: false,
    });
  };

  handleCallServiceGroupAPI = () => {
    const { clickedServiceRowData, selectedServiceGroupId } = this.state;
    const putBody = {
      data: {
        attributes: {
          service_group_ids: selectedServiceGroupId,
        },
      },
    };

    let message = makeApiMessage({
      url:
        configJSON.getServiceListAPIEndPoint + `/${clickedServiceRowData?.id}`,
      body: JSON.stringify(putBody),
      method: "PUT",
    });
    this.putServiceByGroupApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  // Customizable Area End
}
