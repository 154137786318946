import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Icon,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  Modal,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { filterIcon, gridIcon, listIcon } from "./assets";
import "./RolesPermissions2.web.css";
import { handleHeaderClick, IPermissionGroup } from "./utils";
import { colors } from "../../../blocks/utilities/src/Colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const modalStyle: any = {
  maxHeight: "calc(100vh - 126px)",
  position: "absolute",
  top: "49%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "587px",
  height: "auto",
  bgcolor: "#fff",
  borderRadius: 12,
  boxShadow: 24,
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "auto",
  "@media (max-width: 768px)": {
    padding: "20px",
    width: "75%",
  },
};

// Customizable Area End

import RolesPermissions2ControllerWeb, {
  configJSON,
  Props,
} from "./RolesPermissions2Controller.web";
import PageHeader from "../../../components/src/PageHeader";
import TablePopover from "../../../components/src/TablePopover.web";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import RoleDisableModal from "../../../components/src/customComponents/RoleDisableModal";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";

export default class RolesPermissions2 extends RolesPermissions2ControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderGridView() {
    const {permissionStatus} = this.state;
    return (
      <>
      <Box sx={webStyle.mainWrapper} className='rolesWrappper'>
        <Grid container spacing={3} data-test-id="rolesData">
          {this.state.rolesList.data.map((role) => {
            const { id, name, active, permission_groups } = role.attributes;
            let permissionString = "";
            permission_groups.forEach((permission: IPermissionGroup) => {
              if (permission.permitted) {
                permissionString += permission.name + ", ";
              }
            });
            permissionString = permissionString.replace(/,\s*$/, "");
            return (
              <Grid
                className="rolesBoxWrap"
                key={id}
                item
                md={4}
                sm={6}
                xs={12}
              >
                <Box
                  style={{ ...webStyle.cardItem }}
                  className={active ? "rolesBox" : "rolesBox roleDisable"}
                >
                  <Typography variant='h6' style={webStyle.cardHeading}>
                    <Box alignItems={"center"} display={"flex"}>
                      <GridImgWrapper
                        src={
                          role.attributes.gallery_icon ? role.attributes.gallery_icon.image : ""
                        }
                      />

                      <TypographyWrapper
                        data-test-id={`role_${id}`}
                      >
                        {name}
                      </TypographyWrapper>
                      <MainIcondiv>
                        <Icon
                          className="iconSet"
                          component={MoreVertIcon}
                          onClick={(
                            event: React.MouseEvent<SVGSVGElement>
                          ) => {
                            this.handleMenuOpen(event as any, id);
                          }}
                          data-test-id={`roleMenu${id}`}
                        />
                      </MainIcondiv>
                    </Box>
                  </Typography>
                  <TypographyLableWrapper>
                    {configJSON.labelCardPermission}
                  </TypographyLableWrapper>
                  <TypographyMainText>
                    {permissionString || configJSON.textNoPermission}
                  </TypographyMainText>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <Popover
          anchorEl={this.state.anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          anchorPosition={{ left: 20, top: 20 }}
          disablePortal={true}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
        >
          {
            renderBaseOnConditions(
              (permissionStatus.viewPermission ||
                permissionStatus.editPermission ||
                permissionStatus.activatePermission ||
                permissionStatus.deactivatePermission),
                <div style={webStyle.popoverContainer}>
                  {
                    renderBaseOnConditions(
                      permissionStatus.viewPermission,
                      <Box
                        sx={webStyle.popoverButton}
                        onClick={this.viewNavigation}
                        data-test-id='btnView'
                      >
                        {configJSON.btnViewText}
                      </Box>,
                      <></>
                    )
                  }
                  {
                    renderBaseOnConditions(
                      permissionStatus.editPermission,
                      <Box
                        sx={webStyle.popoverButton}
                        onClick={this.editNavigation}
                        data-test-id='btnEdit'
                      >
                        {configJSON.btnEditText}
                      </Box>,
                      <></>
                    )
                  }
                  {
                    renderBaseOnConditions(
                      permissionStatus.deactivatePermission && this.state.popOverItemStatus === "Active",
                      <Box
                        sx={webStyle.popoverButton}
                        onClick={() => {
                          this.handleActivateDeactivate();
                        }}
                        data-test-id='btnDeactivate'
                      >
                        {configJSON.btnDeactivateText}
                      </Box>,
                      <></>
                    )
                  }
                  {
                    renderBaseOnConditions(
                      permissionStatus.activatePermission as boolean && this.state.popOverItemStatus !== "Active",
                      <Box
                        sx={webStyle.popoverButton}
                        onClick={() => {
                          this.handleActivateDeactivate();
                        }}
                        data-test-id='btnActivate'
                      >
                        {configJSON.btnActivateText}
                      </Box>,
                      <></>
                    )
                  }
                </div>,
                <div style={webStyle.popoverContainer}>
                  <div style={webStyle.noPermission}>
                    {configJSON.noPermission}
                  </div>
                </div>
            )
          }
        </Popover>
      </Box>
      <TablePagination
        count={this.state.meta.total_count}
        onPageChange={(_: any, page: number) => this.handlePageChange(_, page)}
        data-test-id='pagination'
        rowsPerPageOptions={[10]}
        component='div'
        rowsPerPage={this.state.pageSize}
        page={this.state.page}
      />
    </>
    );
  }

  renderListView() {
    const rows = this.state.rolesList.data.map((role) => {
      const name = role.attributes.name;
      const permissions = role.attributes.permission_groups
        ?.filter((item) => item.permitted)
        .map((item: any) => item.name);
      const icon = role.attributes.gallery_icon ? role.attributes.gallery_icon.image : "";
      const status = role.attributes.active ? "Active" : "Deactivated";
      const id = role.id;
      const accounts = role.attributes.accounts?.map((item: any) => item.name);

      return this.createData(
        name,
        icon,
        permissions,
        status,
        id,
        role.attributes.accounts_count,
        accounts
      );
    });
    const emptyRowsRole = this.state.pageSize - rows.length;
    return (
      <>
        <TableContainer component={Paper}>
          <Table style={webStyle.tableArea} aria-label='customized table'>
            <TableHead>
              <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
                <SortingTableHeader
                  data-test-id='nameSortingHeader'
                  {...this.sortingProps}
                  sortingData={this.state.sortingData}
                  title={configJSON.role}
                  sortingKey='name'
                  type='left'
                  handleHeaderClick={() => {
                    handleHeaderClick(
                      this.state.filters,
                      (updatedFilters: IFilter[]) => {
                        this.handleSetFilterDataOnSortClick(updatedFilters);
                      }
                    );
                  }}
                />

                <SortingTableHeader
                  data-test-id='usersSortingHeader'
                  {...this.sortingProps}
                  sortingData={this.state.sortingData}
                  title={configJSON.users}
                  sortingKey='user'
                  handleHeaderClick={() => {
                    handleHeaderClick(
                      this.state.filters,
                      (updatedFilters: IFilter[]) => {
                        this.handleSetFilterDataOnSortClick(updatedFilters);
                      }
                    );
                  }}
                />

                <SortingTableHeader
                  data-test-id='iconSortingHeader'
                  {...this.sortingProps}
                  sortingData={this.state.sortingData}
                  title={configJSON.icon}
                  sortingKey='icon_id'
                  handleHeaderClick={() => {
                    handleHeaderClick(
                      this.state.filters,
                      (updatedFilters: IFilter[]) => {
                        this.handleSetFilterDataOnSortClick(updatedFilters);
                      }
                    );
                  }}
                />
                <SortingTableHeader
                  data-test-id='permissoinSortingHeader'
                  {...this.sortingProps}
                  sortingData={this.state.sortingData}
                  title={configJSON.permissions}
                  sortingKey='permission_group_ids'
                  handleHeaderClick={() => {
                    handleHeaderClick(
                      this.state.filters,
                      (updatedFilters: IFilter[]) => {
                        this.handleSetFilterDataOnSortClick(updatedFilters);
                      }
                    );
                  }}
                />
                <SortingTableHeader
                  {...this.sortingProps}
                  sortingData={this.state.sortingData}
                  title='Status'
                  sortingKey=''
                  sortingDisabled
                />

                <SortingTableHeader
                  {...this.sortingProps}
                  sortingData={this.state.sortingData}
                  title=''
                  sortingKey=''
                  sortingDisabled
                  type='action'
                />
              </TableRow>
            </TableHead>
            <TableBody data-test-id='tableData'>
              {rows.map((rowData, index) => (
                <TableRow key={index + "TableRow"}>
                  <SortingTableCell
                    width='22vw'
                    type='left'
                    content={rowData.name}
                  />
                  <SortingTableCell
                    data-test-id='userTableCell'
                    width='22vw'
                    content={
                      <span
                        data-test-id='userNumber'
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.handleOpenListingModal("Users", rowData.accounts)
                        }
                      >
                        {rowData.users}
                      </span>
                    }
                  />
                  <SortingTableCell
                    width='22vw'
                    type='icon'
                    content=''
                    iconSrc={rowData.icon}
                  />

                  <SortingTableCell
                    data-test-id='permissionTableCell'
                    width='22vw'
                    content={
                      <span
                        data-test-id='permissionNumber'
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.handleOpenListingModal(
                            "Permissions",
                            rowData.permissions
                          )
                        }
                      >
                        {rowData.permissions.length}
                      </span>
                    }
                  />
                  <SortingTableCell
                    width='22vw'
                    type='right'
                    content={rowData.status}
                  />

                  <SortingTableCell
                    width='22vw'
                    type='action'
                    content={
                      <IconButton
                        data-test-id={configJSON.moreBtn}
                        id={"IconButton" + index}
                        onClick={(event) => {
                          const position =
                            event.currentTarget.getBoundingClientRect();
                          this.setState({
                            popOverItemStatus: rowData.status,
                            popOverItemId: rowData.id,
                            popOverTop: position.top + window.scrollY,
                            popOverLeft: position.left + window.scrollX,
                            popOverOpened: true,
                          });
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    }
                  />
                </TableRow>
              ))}
              {emptyRowsRole > 0 && (
                <TableRow style={{ height: 53 * emptyRowsRole }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TablePopover
              data-test-id={configJSON.popoverTest}
              open={this.state.popOverOpened}
              onClose={() => this.setState({ popOverOpened: false })}
              top={this.state.popOverTop}
              left={this.state.popOverLeft}
              onView={() => this.handleViewRole(this.state.popOverItemId)}
              onEdit={() => this.handleEditRole(this.state.popOverItemId)}
              onDeactivate={
                () =>
                  this.setState({
                    disableModalVisible: true,
                    popOverOpened: false,
                  }, () => this.getRoleListOptions())
                // () => this.handleDeactivate()
              }
              disableDeactivateModal={true}
              onActivate={() =>
                this.handleActivate()
              }
              deactivateTitle={configJSON.addAreaHeader}
              isActive={
                this.state.popOverItemStatus === "Active" ? true : false
              }
              permissionStatus={this.state.permissionStatus}
            />
            <Modal
              open={this.state.listingShowModalShow}
              data-test-id='permission_list_modal'
              onClose={this.handleCloseListingModal}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={modalStyle}>
                <h4 style={webStyle.modalHeading}>
                  {this.state.listingModalTitle}
                </h4>
                <Box className='listingWrapper'>
                  {this.state.listingDataShow.map((item: any) => {
                    return (
                      <Box
                        data-test-id='roles_list_item'
                        key={item}
                        className='listingItem'
                      >
                        {item}
                      </Box>
                    );
                  })}
                </Box>
                {this.state.listingDataShow.length <= 0 && (
                  <Box style={webStyle.noDataFound}>
                    {`No ${this.state.listingModalTitle} found`}
                  </Box>
                )}
                <Button
                  data-test-id='closePermissionModal'
                  style={webStyle.modalCancelBtn}
                  onClick={() => this.handleCloseListingModal()}
                >
                  Cancel
                </Button>
              </Box>
            </Modal>
          </Table>
        </TableContainer>
        <TablePagination
          count={this.state.meta.total_count}
          onPageChange={(_: any, page: number) => this.handlePageChange(_, page)}
          data-test-id='pagination'
          rowsPerPageOptions={[10]}
          component='div'
          rowsPerPage={this.state.pageSize}
          page={this.state.page}
        />
      </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
        <Container
            style={{ padding: "30px 0px 30px 0px" }}
            maxWidth={false}
          >
          
            <PageHeader
              data-test-id='pageContainerButton'
              header={configJSON.labelRoleHeading}
              permissionStatus={this.state.permissionStatus.createPermission}
              buttonText={configJSON.btnAddRoleText}
              onButtonClick={this.createNavigation}
              onClickGrid={this.gridProps.onClick}
              onClickList={this.listProps.onClick}
              filterComponent={
                <StyledBoxWrapper>
                  <div className="mainWrapperPage">
                    <div className="mainGridIcon">
                      <div
                        data-test-id='listButton'
                        className={!this.state.isGridView ? 'selectedLeftSelectionButton' : 'leftSelectionButton'} 
                        {...this.listProps}
                      >
                        <img src={listIcon} />
                      </div>
                      <div
                        data-test-id='gridButton'
                        className={this.state.isGridView ? 'selectedRightSelectionButton' : 'rightSelectionButton'} 

                        {...this.gridProps}
                      >
                        <img src={gridIcon} />
                      </div>
                    </div>
                      <div
                        data-test-id="filterIcon"
                        className="filterButton"
                        onClick={(event) => {
                          this.setState({
                            filterAnchor: event.currentTarget,
                          });
                        }}
                      >
                        <img src={filterIcon} />
                      </div>
                    <FilterPopover
                      data-test-id='filterPopover'
                      onClose={() => {
                        this.setState({ filterAnchor: undefined });
                      }}
                      anchor={this.state.filterAnchor}
                      onFilterChange={this.handleFilterChange}
                      onAutoCompleteChange={(title: string, value: string) => {this.handleFilterAutoCompleteChange(title, value)}}
                      filters={this.state.filters}
                    />
                  </div>
                </StyledBoxWrapper>
              }
            />
            {this.state.isGridView
              ? this.renderGridView()
              : this.renderListView()}

            <Snackbar
              data-test-id='snackbar'
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackbar.open}
              autoHideDuration={4000}
              onClose={this.handleSnackbarClose}
            >
              <Alert severity={this.state.snackbar.severity}>
                {this.state.snackbar.message}
              </Alert>
            </Snackbar>
          </Container>
        </ThemeProvider>
        <RoleDisableModal
          data-test-id ="RoleDisableModal"
          open={this.state.disableModalVisible}
          handleClose={() => this.setState({ disableModalVisible: false })}
          handleConfirm={(disableAllUsers, roleId) =>
            this.deActivateHandler(roleId)
          }
          roleList={
            this.state.roleOptions.map((role) => ({
              id: role.id,
              name: role.attributes.name,
            })) || []
          }
        />
        <ConfirmationModal
          displayItem={true}
          open={this.state.roleDeactivateSuccessDialogOpen}
          handleClose={this.handleGoToListing}
          handleConfirm={this.handleGoToListing}
          title={'Role Deactivated'}
          confirmText={'Back to Listing'}
          key={"account-deactivate-modal"}
          data-test-id='RoleDeactivateSuccess'
          customMessage={
            !this.state.roleDeactivateMoved ? 'Role has been deactivated sucessfully' : 'All users are moved to selected role'
          }
          message=''
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    marginTop: "40px",
  },
  heading: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  headingGrid: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  cardItem: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#F6F0EC",
    borderRadius: "12px",
    padding: "30px 24px",
  },
  cardItem1: {
    backgroundColor: "#F6F0EC",
  },
  cardItem2: {
    backgroundColor: "#F2EBFC",
  },
  cardItem3: {
    backgroundColor: "#D6EBE8",
  },
  cardHeading: {
    color: "#1A1A1A",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    marginBottom: "32px",
  },
  cardPermissionTitle: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    marginBottom: "10px",
  },
  cardPermissions: {
    color: "#1A1A1A",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
  },
  imgIcon: {
    width: "32px",
    height: "32px",
  },
  buttonStyle: {
    width: "184px",
    height: "56px",
    border: "none",
    backgroundColor: "#204B9C",
    borderRadius: "8px",
    textTransformation: "none",
    fontSize: "16px",
    fontWeight: 600,
    cursor: "pointer",
    textTransform: "none" as "none",
  },
  buttonStylePrimary: {
    backgroundColor: "#204B9C",
    color: "#FFF",
  },
  popoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  noPermission: {
    padding: "0 8px"
  },
  popoverButton: {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  tableArea: {
    minWidth: 700,
  },
  filterHeaderText: {
    color: "#204B9C",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    margin: 24,
  },
  filterLine: {
    marginLeft: "24px",
    backgroundColor: "rgba(217, 217, 217, 1)",
    marginRight: "24px",
    width: "90%",
    height: "1px",
    marginBottom: "8px",
  },
  cancelButton: {
    fontWeight: 700,
    backgroundColor: "#F1F5F8",
    width: "128px",
    color: "#64748C",
    height: "56px",
    fontSize: "16px",
    borderRadius: "8px",
  },
  saveButton: {
    fontSize: "16px",
    fontWeight: 700,
    backgroundColor: "#204B9D",
    height: "56px",
    width: "128px",
    borderRadius: "8px",
  },
  modalHeading: {
    fontSize: "24px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    margin: "0 0 16px 0",
  },
  modalCancelBtn: {
    minHeight: "56px",
    width: "126px",
    backgroundColor: "rgba(32, 75, 156, 0.1)",
    borderRadius: "8px",
    marginTop: "32px",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(100, 116, 139, 1)",
    fontWeight: 600,
    textTransform: "capitalize" as const,
  },
  noDataFound: {
    fontSize: "20px",
    lineHeight: "20px",
    fontFamily: "Montserrat",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: "16px 0",
    fontWeight: 500,
  },
};
const StyledBoxWrapper = styled(Box)({
  "& .filterButton": {
    padding: "16px",
    borderRadius: "8px",
    border: "solid 1px",
    borderColor:colors().cyancobaltblue,
    cursor: "pointer",
    marginLeft: "24px",
    "@media only screen and (max-width: 1024px)": {
      padding: "10px",
    },   
  },
  "& .mainGridIcon": {
    border: "1px solid",
    borderColor:colors().lightborder,
    borderRadius: "8px",
    display: "flex",
  }, 
  '& .mainWrapperPage': {
    display:"flex"
  },
  '& .leftSelectionButton': {
    padding: "16px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    cursor: "pointer",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "10px",
    },   
  },  
  '& .selectedLeftSelectionButton': {
    padding: "16px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    cursor: "pointer",
    background: colors().brightgray,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "10px",
    },   
  },
  '& .rightSelectionButton': {
    padding: "16px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    cursor: "pointer",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "10px",
    },   
  }, 
  '& .selectedRightSelectionButton': {
    padding: "16px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    cursor: "pointer",
    background: colors().brightgray,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "10px",
    },   
  },  
});

const GridImgWrapper = styled('img')({
  width: "32px",
  height: "32px",
  "@media only screen and (max-width: 1024px)": {
    width: "24px",
    height: "24px",
  },   
});

const TypographyWrapper = styled(Typography)({
  paddingLeft: "30px",
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "22px",
  color: colors().viewTextColor,
  "@media only screen and (max-width: 1024px)": {
    fontWeight: 700,
    fontSize: "14px",
    color: colors().viewTextColor,
    fontFamily: 'Montserrat',
    lineHeight: "22px",
  },   
});


const TypographyLableWrapper = styled(Typography)({
  color: colors().darkliver,
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "22px",
  marginBottom: "10px",
  "@media only screen and (max-width: 1024px)": {
    fontWeight: 600,
    fontSize: "13px",
    color: colors().darkliver,
    fontFamily: 'Montserrat',
  },   
});

const TypographyMainText = styled(Typography)({
  color:  colors().viewTextColor,
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "22px",
  "@media only screen and (max-width: 1024px)": {
    fontWeight: 600,
    fontSize: "13px",
    color: colors().viewTextColor,
    fontFamily: 'Montserrat',
  },   
});

const MainIcondiv = styled('div')({
  marginLeft : "auto",
  '& .iconSet':{
    "@media only screen and (max-width: 1024px)": {
      marginLeft : "auto",
      width:"24px",
      height:"24px"
    },   
  }
});

// Customizable Area End
