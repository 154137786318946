// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IFilter } from "../../../components/src/FilterPopover";
import { apiCall, makeApiMessage } from "../../../components/src/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModalId: number | null;
  sectionList: SectionListData[]
  verifyMessage: string
  successMessage: string
  buttonText: string
  deActivateMessage: string
  isLoading: boolean;
  meta: {
      next_page: number;
      pervious_page: number;
      total_pages: number;
      total_count: number;
      current_page: number
  };

  filterAnchor: HTMLDivElement | boolean | undefined;
  filters: IFilter[];
  query: string;
  isDragableDisabled:boolean;
  nameSorting:{
      value:string;
    },
  permissionStatus: PermissionStatus;
  openSectionGroupModal: boolean;
  sectionDropdownList: Array<SectionDropdownItem>;
  clickedSectionRowData: ClickedSectionRowType | null;
  selectedSectionGroupId: Array<string>;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;

  // Customizable Area End
}

// Customizable Area Start
interface SectionListData {
  id: number
  attributes: {
    section_name: string,
    section_second_name: string
    active: boolean,
    online: boolean,
    preference_id: number,
    order_number: number,
    section_group_ids: Array<string>;
  }
}

interface ClickedSectionRowType {
  id: number;
  name: string;
  section_group_ids: Array<string>;
}

interface MovedItem {
    id: number,
    order_number: number
}

interface SectionDropdownItem {
  id: "132";
  type: "category_dropdown";
  attributes: {
    id: 132;
    section_name: "Women";
    name: "Women";
  };
}
// Customizable Area End

export default class SectionListController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getsectionCallId: string = "";
    deactivateSectionListCallId: string = "";
    dragDropListCallId: string = "";
    sectionSuggestionApiCallId: string = "";
    activateApiCallId: string = "";
    getSectionDropdownListCallId: string = "";
    postSectionByGroupApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.LayoutDataMessage),
            getName(MessageEnum.SearchTextMessage)
        ];

        this.state = {
            openModalId: null,
            sectionList: [],
            verifyMessage: configJSON.sectionVerifyMessage,
            successMessage: configJSON.sectionSuccessMessage,
            buttonText: configJSON.confirmButtonText,
            deActivateMessage: configJSON.sectionDeactivateMessage,
            filterAnchor: false,
            isLoading: false,
            query: "",
            filters: [
                {
                    title: "Section Name",
                    type: "autocompolete",
                    value: "",
                    options: [],
                },
                {
                    title: "Status",
                    type: "select",
                    options: [
                        {
                            label: "Activate",
                            value: "activate",
                        },
                        {
                            label: "Deactivated",
                            value: "deactivate",
                        },
                    ],
                    value: "",
                },
                {
                    title: "Order",
                    type: "select",
                    options: [
                        {
                            label: "Online",
                            value: "online",
                        },
                        {
                            label: "Offline",
                            value: "offline",
                        },
                    ],
                    value: "",
                },

            ],
            meta: {
                next_page: 1,
                pervious_page: 1,
                total_pages: 1,
                total_count: 1,
                current_page: 1
            },
            isDragableDisabled:false,
            nameSorting:{
                value:""
              },
          permissionStatus: {
            mainPermission: false,
            createPermission: false,
            viewPermission: false,
            editPermission: false,
            deactivatePermission: false
          },
          openSectionGroupModal: false,
          sectionDropdownList: [],
          clickedSectionRowData: null,
          selectedSectionGroupId: []
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

  // Customizable Area Start

  async componentDidMount() {
    this.getSectionListData(1, "","")
  }
  
    onChangeValue = (value: string) => {
        this.setState({
            query: value
        }, () => {
          this.handleInputChangeDebounced()
        })
    }
    componentDidUpdate(prevProps: Props, prevState: S) {
        if (prevState.query !== this.state.query || prevState.filters !== this.state.filters 
            || prevState.nameSorting !== this.state.nameSorting
            ) {
          this.handleDeActivateDragable()
        }
      }
    handleDeActivateDragable = ()=> {
        const filterByName = this.state.filters.find((item) => item.title === "Section Name")?.value;
        const filterByStatus = this.state.filters.find((item) => item.title === "Status")?.value;
        const filterByOrder = this.state.filters.find((item) => item.title === "Order")?.value;
        if(this.state.query === "" && filterByName === "" && filterByStatus === "" && filterByOrder === "" 
        && this.state.nameSorting.value === ""
        ){
            this.setState({isDragableDisabled:false})
        }else {
            this.setState({isDragableDisabled:true}) 
        }
    }

    onFilterClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.setState({
            filterAnchor: event.currentTarget,
          });
    }

    handleSortingBySectionName = () => {
        let response;
        let params;
        if (this.state.nameSorting.value === "") {
          response = { value: "nameUp" };
        } else if (this.state.nameSorting.value === "nameUp") {
          response = { value: "nameDown" };
        } else {
          response = { value: "" };
        }
        this.setState({
          nameSorting: response,
    
        });
       
        if (response.value === "nameUp") {
          params = "asc";
        } else if (response.value === "nameDown") {
          params = "dsc";
        } else {
          params = "";
        }
        this.getSectionListData(this.state.meta.current_page,this.state.query,params)
      };
    handleClosePopOver = () => {
        this.setState({ filterAnchor: undefined });
    }
    handleInputChangeDebounced = () => {
        this.getSectionListData(1, this.state.query,this.state.nameSorting.value)
    }
    handleOpenModel = (itemId: number) => {
        this.setState((prevState) => ({
            openModalId: prevState.openModalId === itemId ? null : itemId,
        }));
    };

    handleAddSection = () => {
        this.props.navigation.history.push("/Products-SectionListAdd")
    }
    handleSetionView = (item: SectionListData) => {
        this.props.navigation.history.push(`Products-SectionListView/${item.id}`, { state: item })

    }
    handleSectionEdit = (item: SectionListData) => {
        this.props.navigation.history.push(`Products-SectionListEdit/${item.id}`, { state: item })
    }
    goToNextPage = () => {
        this.state.meta.next_page
            && this.getSectionListData(this.state.meta.next_page,"","")
    }

    goToPreviousPage = () => {
        this.state.meta.pervious_page
            && this.getSectionListData(this.state.meta.pervious_page,"","")
    }
    handleFilterChange = (filters: IFilter[]) => {
        this.setState({ filters }, () => {
            this.getSectionListData(1,this.state.query,this.state.nameSorting.value)
        });
    };
    getSectionListData = (pageNumber: number, query: string,sectionName:string) => {
      this.setState({isLoading:true})
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };
        const nameFilter = this.state.filters.find((item) => item.title === "Section Name")?.value;
        const filterStatus = this.state.filters.find((item) => item.title === "Status")?.value;
        const filterOrder = this.state.filters.find((item) => item.title === "Order")?.value;

        const apiUrl =
            configJSON.sectionListEndPoint +
            "?page_no=" +
            (pageNumber) +
            (nameFilter ? `&filter_by[section_name]=${nameFilter}` : "") +
            (filterStatus ? `&filter_by[status]=${filterStatus}` : "") +
            (filterOrder ? `&filter_by[order]=${filterOrder}` : "") + (query ? `&filter_by[query]=${query}` :"")
            + (sectionName ? `&name=${sectionName}` :"") 
        const sectionListDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getsectionCallId = sectionListDataMessage.messageId;

        sectionListDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiUrl
        );

        sectionListDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        sectionListDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(sectionListDataMessage.id, sectionListDataMessage);
    }
    handleDragAndDrop = (item: MovedItem[]) => {
        let headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: item
            }

        }
        const dragdropMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.dragDropListCallId = dragdropMsg.messageId;

        dragdropMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sectionDragAndDropApiEndPoint
        );

        dragdropMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        dragdropMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        dragdropMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpUpdateMethodType
        );

        runEngine.sendMessage(dragdropMsg.id, dragdropMsg);

    }
    handleDeActivateSectionList = (item: SectionListData) => {

        let headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    active: false,
                }
            }

        }
        const deactiveSectionDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deactivateSectionListCallId = deactiveSectionDataMessage.messageId;

        deactiveSectionDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deActivateSectionListEndPoint}/${item.id}/${configJSON.deActiveText}`
        );

        deactiveSectionDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        deactiveSectionDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        deactiveSectionDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpUpdateMethodType
        );

        runEngine.sendMessage(deactiveSectionDataMessage.id, deactiveSectionDataMessage);

    }
    handleActivate= (item: SectionListData) => {

        let headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    active: true,
                }
            }

        }
        const activateMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.activateApiCallId = activateMessage.messageId;

        activateMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deActivateSectionListEndPoint}/${item.id}/${configJSON.activeText}`
        );

        activateMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        activateMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        activateMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpUpdateMethodType
        );

        runEngine.sendMessage(activateMessage.id, activateMessage);

    }
    handleResponseForGetSectionList = (from: string, message: Message) => {
        if (this.getsectionCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse) {
                this.setState({
                  sectionList: apiResponse.data,
                  meta: apiResponse.meta,
                  isLoading: false
                })

            }
        }
    }
    handleResponseForDeactivateSectionList = (from: string, message: Message) => {
        if (this.deactivateSectionListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse) {
                const updatedFeedData = this.state.sectionList.map((object: SectionListData) => {

                    if (object.id == apiResponse.data.id) {
                        object.attributes.active = false;
                    }
                    return object;
                })
                this.setState({
                    sectionList: updatedFeedData,
                    openModalId: null,

                })
            }

        }
    }

    sectionSuggestionApiResponse = (from: string, message: Message) => {
        if (this.sectionSuggestionApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse) {
                const list = apiResponse?.filter_names?.map((value: string) => ({
                    label: value,
                    value,
                })) || [];
                const updatedFilters = this.state.filters.map((item: IFilter) => {
                    if (item.title === "Section Name") item.options = list;
                    return item;
                });
                this.setState({ filters: updatedFilters });
            }

        }
    }
    handleResponseActivate = (from: string, message: Message) => {
        if (this.activateApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            this.getSectionListData(1, "","")
            this.setState({
               openModalId: null,
           })
       }
    }

    handleFilterAutoCompleteChange = (filterName: string, query: string) => {
        this.state.filters.forEach((item: IFilter) => {
            if (item.title === filterName) item.value = query;
            return item;
        });

        let headers = {
            "Content-type": "application/json",
            token: window.localStorage.getItem(configJSON.token)
        }

        let apiUrl;
        apiUrl = configJSON.getSectionSuggestionApiEndPoint +
            (filterName === "Section Name" ? `?section_name=${query}` : '');

        const getAccount = apiCall({
            header: headers,
            httpBody: {},
            url: apiUrl,
            httpMethod: configJSON.httpGetType,
        });

        this.sectionSuggestionApiCallId = getAccount.messageId;
        runEngine.sendMessage(getAccount.id, getAccount);
    }

    handleUserChange = (userContext: IUserContext) => {
      const apiKey = customPermissionApiKey.sectionNewPermissions;
      const userData = userContext.user?.attributes.permission_groups;
      const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
      this.setState({
        permissionStatus: value
      })
    };

    handleOpenSectionGroup = (item: SectionListData) => {
      this.setState(
        {
          openSectionGroupModal: true,
          clickedSectionRowData: {
            id: item.id,
            name: item.attributes.section_name,
            section_group_ids: item.attributes.section_group_ids,
          },
          selectedSectionGroupId: item.attributes.section_group_ids
        },
        () => this.handleFetchSections()
      );
    };
  
    handleCloseGroupModal = () => {
      this.setState({
        openSectionGroupModal: false,
      });
    };

    handleCheckedSection = (selectedId: string) => {
      const { selectedSectionGroupId } = this.state;
      const tempSelectedIds = [...selectedSectionGroupId];
      if (selectedSectionGroupId.includes(selectedId)) {
        const newArry = tempSelectedIds.filter(
          (sectionNumber) => sectionNumber !== selectedId
        );
        this.setState({
          selectedSectionGroupId: newArry,
        });
      } else {
        tempSelectedIds.push(selectedId);
        this.setState({
          selectedSectionGroupId: tempSelectedIds,
        });
      }
    };
  
    handleFetchSections = () => {
      let message = makeApiMessage({
        url: configJSON.getSectionListsAPIEndPoint + "?dropdown=true",
        method: "GET",
      });
      this.getSectionDropdownListCallId = message.messageId;
      runEngine.sendMessage(message.id, message);
    };

    handleCallSectionGroupAPI = () => {
      const { clickedSectionRowData, selectedSectionGroupId } = this.state;
      const putBody = {
        data: {
          attributes: {
            section_group_ids: selectedSectionGroupId,
          },
        },
      };
  
      let message = makeApiMessage({
        url:
          configJSON.getSectionListsAPIEndPoint + `/${clickedSectionRowData?.id}`,
        body: JSON.stringify(putBody),
        method: "PUT",
      });
      this.postSectionByGroupApiCallId = message.messageId;
      runEngine.sendMessage(message.id, message);
    };
  
    handleSectionDropownListResponse = (_from: string, message: Message) => {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.getSectionDropdownListCallId === apiRequestCallId) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const clickedId = this.state.clickedSectionRowData?.id;
        this.setState({
          sectionDropdownList: apiResponse.data
            .sort(
              (first: SectionListData, second: SectionListData) =>
                Number(first.id) - Number(second.id)
            ).filter((item: SectionListData) => item.id !== clickedId),
        });
      }
    };

    handlePostSectionByGroupResponse = (_from: string, message: Message) => {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.postSectionByGroupApiCallId === apiRequestCallId) {
        this.setState({
          openSectionGroupModal: false,
          selectedSectionGroupId: [],
          clickedSectionRowData: null
        });
        this.getSectionListData(1, "","");
      }
    };

    loadSearchText = (message: Message) => {
      if (message.id === getName(MessageEnum.SearchTextMessage)) {
          const recievedData = message.getData(
              getName(MessageEnum.SearchMessageText)
          );
          if (recievedData) {
            this.onChangeValue(recievedData.searchText)
          }
      }
    }
    
    acquireLayoutData = (message: Message) => {
      if (message.id === getName(MessageEnum.LayoutDataMessage)) {
          const recievedData = message.getData(
              getName(MessageEnum.LayoutMessageData)
          );
          if (recievedData.userContext) {
            this.handleUserChange(recievedData.userContext)
          }
      }
    }

  // Customizable Area End

  // Customizable Area Start
    async receive(from: string, message: Message) {
        this.acquireLayoutData(message);
        this.loadSearchText(message)
        this.handleResponseForGetSectionList(from, message)
        this.handleResponseForDeactivateSectionList(from, message)
        this.sectionSuggestionApiResponse(from, message)
        this.handleResponseActivate(from, message)
        this.handleSectionDropownListResponse(from, message);
        this.handlePostSectionByGroupResponse(from, message);
      }
  // Customizable Area End
}
