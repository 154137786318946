// Customizable Area Start
import React from "react";
import { Box, Button, TablePagination, styled } from "@material-ui/core";
import IncomingTransactionController, {
  Props,
} from "./IncomingTransactionController.web";
import {
  MainBodyBox,
  StyleContent,
  StyleContentVariance,
  StyledWrapper,
} from "./OutGoingTransaction.web";
import CustomCashierTab from "../../../components/src/commonComponents/CustomCashierTab";
import CashierHeader from "../../../components/src/CashierHeader";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
import { printIcon, editIcon } from "./assets";
const configJSON = require("./config");
import { IFilter } from "../../../components/src/FilterPopover";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
const Strings = configJSON.IncomingTransaction.Strings;
import { tabString } from "./Cfcashier.web";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { getPeriodDate, handleMomentDateFormat } from "./utils";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

class IncomingTransaction extends IncomingTransactionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  incomingTransactionTableHeader() {
    return (
      <RowContainer header>
        <TableRow columns={this.getTableRowColumns()} className='table-cols'>
          {[
            {
              title: Strings.transactionID,
              sortingKey: "transfer_id",
            },
            {
              title: Strings.storeTxt,
              sortingKey: "from_store",
            },
            {
              title: Strings.storeID,
              sortingKey: "from_store_id",
            },
            {
              title: Strings.storePos,
              sortingKey: "station",
            },
            {
              title: Strings.date,
              sortingKey: "date",
            },
            {
              title: Strings.storeKeeper,
              sortingKey: "account",
            },
            {
              title: Strings.to,
              sortingKey: "to",
            },
            {
              title: Strings.driver,
              sortingKey: "driver",
            },
            {
              title: Strings.declaredAmount,
              sortingKey: "transfered",
            },
            {
              title: Strings.confirmAmount,
              sortingKey: "confirm_amount",
            },
            {
              title: Strings.variance,
              sortingKey: "variance",
            },
          ].map((label: { title: string; sortingKey: string }) => (
            <TableCell
              contentFontSize='13px'
              header
              content={label.title}
              className='align-item-center p5'
              sortingIcons={[IconSortingNormal, IconSortingUp, IconSortingDown]}
              sortingKey={label.sortingKey}
              sortingDisabled={!Boolean(label.sortingKey)}
              {...this.getSortingProps()}
            />
          ))}
        </TableRow>
      </RowContainer>
    );
  }

  incomingTransactionTableBody() {
    const { permissionStatus } = this.state;
    return (
      <RowContainer>
        {this.state.transactionList.length === 0 && (
          <TableRow
            key={"NoDataTableRow"}
            columns='1fr'
            className='table-rows-norecord'
          >
            <TableCell
              className='w-100'
              type='none'
              content={
                <Box className='no_records'>
                  {configJSON.stringsListPage_noRecordsFound}
                </Box>
              }
            />
          </TableRow>
        )}
        {this.state.transactionList.map((item) => {
          const {
            id,
            transfer_id,
            transfer_from,
            station,
            created_at,
            created_by,
            transfer_to,
            driver,
            amount,
            variance_amount,
            confirm_amount,
            status
          } = item?.attributes || {};
          return (
            <Box className='mainrow'>
              <TableRow columns={this.getTableRowColumns()} className='table-rows'>
                {[
                  <StyleContent>{transfer_id}</StyleContent>,
                  <StyleContent>{transfer_from?.store_name}</StyleContent>,
                  <StyleContent>{transfer_from?.store_id}</StyleContent>,
                  <StyleContent>{station}</StyleContent>,
                  <StyleContent>
                    {handleMomentDateFormat(created_at, "DD-MM-yyyy h:mm A")}
                  </StyleContent>,
                  <StyleContent>{created_by?.full_name}</StyleContent>,
                  <StyleContent>{transfer_to?.name}</StyleContent>,
                  <StyleContent>{driver?.full_name}</StyleContent>,
                  <Box className="declaredAmtCont">
                    <StyleContent> {`SAR ${amount}`}</StyleContent>
                    {status !== "completed" && <button data-test-id="ConfirmBtn" onClick={(event)=>this.handleDirectConfirmAmt(event,id,amount)} className="confirmBtn">Confirm</button>}
                  </Box>,
                  <StyledInputBox display={"flex"}>
                    {this.state.isOpenConfirmAmt &&
                    this.state.editConfirmAmtId === item.id ? (
                      <>
                        <input
                          value={this.state.editConfirmAmt}
                          id='confAmountInput'
                          className='confAmountInput'
                          onChange={this.handleAmountChange}
                          data-test-id='confirm-amount-input'
                        />
                        <Button
                          id='confAmountSubmitBtn'
                          className='confAmountSubmitBtn lable-16-700'
                          onClick={this.handleUpdateConfirmAmt}
                          data-test-id='confirm-amount-submit'
                        >
                          Submit
                        </Button>
                      </>
                    ) : (
                      <StyleContent>
                        {`SAR ${confirm_amount}`}
                      </StyleContent>
                    )}
                  </StyledInputBox>,
                  <StyledInputBox>
                    <StyleContentVariance>
                      {`SAR ${variance_amount}`}
                    </StyleContentVariance>
                    <Box id='varActionContainer' className='varActionContainer'>
                      {this.state.editConfirmAmtId !== item.id && status === "pending" && permissionStatus.CashReceivePermission && (
                        <img
                          alt={"edit"}
                          src={editIcon}
                          className='edit-icon'
                          data-test-id={`btn-edit-${item.id}`}
                          onClick={() => this.handleConfirmAmtInput(item)}
                        />
                      )}
                      <img
                        alt={"print"}
                        src={printIcon}
                        className={'print-icon' + (!item.attributes.invoice_pdf ? " disabled": "")}
                        data-test-id='btn-print'
                        onClick={() => this.handlePrintInvoice(item.attributes.invoice_pdf)}
                      />
                    </Box>
                  </StyledInputBox>,
                ].map((value, index, array) => (
                  <TableCell
                  style={{ background: sortCondition(status === "completed", "#f7f4e7", "") as string}}
                    content={(<>{value}</>) as React.ReactNode}
                    type={this.getTableCellType(index, array.length)}
                    handleClick={() => this.handleRedirectDetail(item, index)}
                  />
                ))}
              </TableRow>
            </Box>
          );
        })}
      </RowContainer>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <StyledWrapper>
        <Loader loading={this.state.loading} isAllowed={true} hideBackgroundColor={true} />
          <Box>
            <CustomCashierTab
              tabList={[configJSON.Tabs.Strings.overview, configJSON.Tabs.Strings.incomingTransaction, configJSON.Tabs.Strings.outgoingTransaction]}
              value={this.state.selectedTab}
              id='incoming-transaction'
              handleTabSeletion={this.handleTabSeletion}
              data-test-id='incoming-transaction-tab'
            />
          </Box>
          <MainBodyBox>
            <CashierHeader
              handleFilterButton={this.handleFilterButton}
              title='Incoming transaction'
              handleCloseFilterButton={this.handleCloseFilterButton}
              isTransferAmount={false}
              handleFilterChange={this.handleFilterChange}
              filters={this.state.filters as IFilter[]}
              filterAnchor={
                this.state.filterBtnAnchor as HTMLDivElement | undefined
              }
              periodDateRange={getPeriodDate(
                this.handleDateFilterParam().startDateText,
                this.handleDateFilterParam().endDateText
              )}
              handleExport={this.exportAll}
            />
            <Box id='tableContainer' className='tableContainer'>
              <OrderTable
                minWidth={1380}
                minWidth1024={887}
                minWidth1280={1109}
                minWidth1366={1184}
              >
                {this.incomingTransactionTableHeader()}
                {this.incomingTransactionTableBody()}
              </OrderTable>
              {this.state.transactionList?.length > 0 && (
                <TablePagination
                  className='tablePagination'
                  page={this.state.page - 1}
                  count={this.state.totalCount}
                  onPageChange={(
                    _event: React.MouseEvent<HTMLElement> | null,
                    page: number
                  ) => this.handlePageChange(page)}
                  data-test-id='pagination'
                  rowsPerPage={10}
                  component='div'
                  rowsPerPageOptions={[10]}
                />
              )}
            </Box>
          </MainBodyBox>
        </StyledWrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledInputBox = styled(Box)({
  "& .confAmountInput": {
    border: "1px solid #ECECEC",
    width: "101px",
    height: "44px",
    padding: "11px 8px",
    boxSizing: "border-box",
    borderRadius: "8px 0px 0px 8px",
    "@media (max-width: 1280px)": {
      fontSize: "14px",
      width: "70px",
      height: "36px"
    },
    "@media (max-width: 1024px)": {
      fontSize: "12px",
      width: "65px",
      height: "32px"
    }
  },
  "& .confAmountSubmitBtn": {
    width: "93px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    borderRadius: "0px 8px 8px 0px",
    background: "#204B9C",
    color: "#fff",
    textTransform: "none",
    "@media (max-width: 1280px)": {
      fontSize: "14px",
      width: "70px",
      height: "36px"
    },
    "@media (max-width: 1024px)": {
      fontSize: "12px",
      width: "65px",
      height: "32px"
    }
  },
  "& .varActionContainer": {
    marginTop: "10px",
    display: "flex",
    gap: "10px",
  },
  "& .filterBtn": {
    height: "44px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    border: "1px solid #ECECEC",
    "& .MuiSvgIcon-root": {
      color: "black",
    },
  },
  "& .p0": {
    padding: "0",
  },
  "& .print-icon": {
    width: 26,
    margin: 16,
    cursor: "pointer",
    textAlign: "right",
    "&.disabled": {
      opacity: 0.5
    }
  },
  "& .edit-icon": {
    width: 26,
    margin: 16,
    cursor: "pointer",
    textAlign: "right",
  },
});
export default IncomingTransaction;
// Customizable Area End
