// Customizable Area Start
import React from "react";
import MainDashboardController, { Props, storeTableColumns, configJSON } from "./MainDashboardController";
import { Box, styled, LinearProgress, Dialog } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import { colors } from "../../utilities/src/Colors";
import * as assets from "./assets";
import DashboardTabPanel from "../../../components/src/DashboardTabPanel.web";
import CustomBarGraph from "../../../components/src/CustomBarGraph";
import { Chart } from "react-google-charts";
import DashboardHeader from "../../../components/src/DashboardHeader";
import CustomCard, {StyledText} from "../../../components/src/customComponents/CustomCards.web";
import SectionSalesTable, {StyledLabel, StyledValue} from "../../../components/src/customComponents/SectionSalesTable.web"
import { IFilter } from "../../../components/src/FilterPopover";
import StyledInlineDatePicker from "../../../components/src/StyledInlineDatepicker.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import ExportModal from "../../../components/src//ExportModal";
import { getGroupNameDisplay } from "../../cfsalesdashboard3/src/utils";
import { Skeleton } from "@material-ui/lab";
import { storeNameBasedOnGroup } from "./utils";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class MainDashboard extends MainDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderOverallSales = () => {
    const { overallSales } = this.state
    return (
      <Box className="overall-sales">
        {
          [
            {
              label: "Stores",
              value: overallSales.stores_count
            },
            {
              label: "Orders",
              value: overallSales.order_count
            },
            {
              label: "Pcs",
              value: overallSales.pieces_count
            },
            {
              label: "Amount",
              value: overallSales.amount
            },
            {
              label: "VAT",
              value: overallSales.vat
            },
            {
              label: "Discount",
              value: overallSales.discount
            },
            {
              label: "Revenue",
              value: overallSales.revenue_amount
            },
            {
              label: "Used Credit",
              value: overallSales.used_credit
            },
            {
              label: "Unpaid",
              value: overallSales.unpaid_amount
            },
            {
              label: "Rewash",
              value: overallSales.rewash_order_count
            }
          ].map(this.renderStat)
        }
      </Box>
    )
  }
  renderPieChart = () => {
    const { totalSales, isLoadingTotalSale } = this.state
    const storeSalesValue = Number(totalSales.storeSales)
    const mobileSalesValue = Number(totalSales.mobileSales)
    const totalValue = storeSalesValue + mobileSalesValue

    const chartData = [
      {
        name: "Store Sale",
        value: storeSalesValue,
        stringValue: totalSales.storeSales,
        color: "#122956"
      },
      {
        name: "Mobile Sale",
        value: mobileSalesValue,
        stringValue: totalSales.mobileSales,
        color: colors().cyancobaltblue
      }
    ]

    if (isLoadingTotalSale) {
      return (
        <Skeleton animation="wave" variant="rect" width={"full"} height={200} />
      )
    }

    return (
      <Box className="pie-chart-wrapper">
        {
          totalValue ? <Chart
            chartType="PieChart"
            data={[
              ["Section", "Sales Revenue"],
              ...chartData.map(item => [item.name, item.value])
            ]}
            options={{
              slices: {
                0: { color: "#122956" },
                1: { color: colors().cyancobaltblue },
              },
              width: 120,
              chartArea: {
                left: 12,
                top: 0,
                height: 120,
                width: 120
              },
              legend: "none",
              enableInteractivity: false,
              pieSliceText: "none",
            }}
          />
            :
            <Box className="pie-chart-empty">
              0
            </Box>
        }
        <Box
          className="pie-chart-legends"
        >
          {
            chartData.map(item => (
              <Box padding="8px 8px 12px" key={item.name} borderRadius={8} border={`solid 1px ${colors().lightborder}`}>
                <Box display="flex" alignItems="center" gridGap={4} marginBottom={0.5}>
                  <Box width={12} height={12} flexShrink={0} borderRadius={4} style={{ background: item.color }} />
                  <p>{item.name}</p>
                </Box>
                <p>SAR {item.stringValue} {totalValue ? <span>({item.value / totalValue * 100}%)</span> : ""}</p>
              </Box>
            ))
          }
        </Box>
      </Box>
    )
  }

  renderSalesByDayProgress = () => {
    const { today_sales_amount, yesterday_sales_amount } = this.state.salesDetail
    const targetValue = Math.max(Number(today_sales_amount), Number(yesterday_sales_amount)) * 1.1
    const itemsToRender = [
      {
        name: "Today",
        value: today_sales_amount,
        color: colors().orange,
      },
      {
        name: "Yesterday",
        value: yesterday_sales_amount,
        color: colors().cyancobaltblue
      }
    ]

    return itemsToRender.map(item => (
      <Box key={item.name}>
        <Box display="flex" justifyContent="space-between" gridGap={5}>
          <StyledLabel fontColor={colors().darkjunglegreen}>{item.name}</StyledLabel>
          <StyledLabel fontColor={colors().darkjunglegreen}>SAR {item.value}</StyledLabel>
        </Box>
        <BorderLinearProgress customColor={item.color} variant="determinate" value={targetValue ? Number(item.value) / targetValue * 100 : 0} />
      </Box>
    )
    )
  }

  renderStat = ({ value, label, background }: { value: number | string; label: string, background?: string }, index: number) => (
    <Box style={{ background }} key={`${label}_${index}`} whiteSpace="nowrap">
      <StyledLabel>
        {label}
      </StyledLabel>
      <StyledValue>
        {value}
      </StyledValue>
    </Box>
  )

  renderDialog = () => {
    const { showModal, regionSalesDetails, newStoreDetails, loadingModal } = this.state

    return (
      <>
        <CustomLoader loading={loadingModal} />
        <h2 className="modal-title">
          {showModal === "region_sales" ? "Region Sales" : "New Stores"}
        </h2>
        {
          showModal === "region_sales" ?
            <Box className="region-sales-grid">
              {
                regionSalesDetails.length ? regionSalesDetails.map((region, index) => (
                  <CustomCard key={index} icon={assets.regionIcon} title={region.region_name}>
                    <Box padding={1}>
                      <SectionSalesTable tableData={region.data} total={region.total} />
                    </Box>
                  </CustomCard>
                ))
                  : (
                    <Box className="no-data-message">
                      <p>No Data</p>
                    </Box>
                  )
              }
            </Box>
            :
            <Box className="new-stores-table">
              <Box className="table-header">
                {
                  storeTableColumns.map(column => <p key={column.label}>{column.label}</p>)
                }
              </Box>
              <Box className="table-body">
                {
                  newStoreDetails.map((store, index) => (
                    <Box className="table-row" key={index}>
                      {
                        storeTableColumns.map(column =>
                          <p key={column.label}>
                            {
                              store[column.binding]
                            }
                          </p>)
                      }
                    </Box>
                  ))
                }
              </Box>
            </Box>
        }
        <Box className="actions">
          <button onClick={() => this.toggleModal("")} data-test-id="modal-cancel-btn">
            Cancel
          </button>
        </Box>
      </>
    )
  }

  renderCustomerSection = () => {
    const { totalCustomer, topCustomer, newCustomer, inactiveCustomer, repeatedCustomer } = this.state
    return (
      <Box className="customers-section">
        <Box className="customers-section-label">
          <img src={assets.customerIcon} alt="" />
          <StyledText>
            Customers
          </StyledText>
        </Box>
        <Box className="customers-section-data">
          {
            [
              {
                label: "Total",
                value: totalCustomer,
                background: colors().paleblue
              },
              {
                label: "Most Spending",
                value: topCustomer,
                background: colors().lavenderblue
              },
              {
                label: "New",
                value: newCustomer,
                background: colors().lightyellow
              },
              {
                label: "Inactive",
                value: inactiveCustomer,
                background: colors().peach
              },
              {
                label: "Repeated",
                value: repeatedCustomer,
                background: colors().paleblue2
              },

            ].map(this.renderStat)
          }
        </Box>
        <Box className="customers-section-btn" data-test-id="go-to-customer-btn" onClick={() => this.navigateToScreen("CustomerDashboard")}>
          <ChevronRightIcon />
        </Box>
      </Box>
    )
  }

  renderTableSection = () => {
    const {
      storeDetails,
      sectionSales,
      mobileSales,
    } = this.state
    const sectionSalesTable = {
      title: configJSON.sectionSales,
      onNavigate: () => this.navigateToScreen("SectionSales"),
      tableData: sectionSales,
      icon: assets.sectionIcon,
      className: "section-sales-table"
    }
    const mobileSalesTable = {
      title: configJSON.mobileSales,
      onNavigate: () => { },
      tableData: mobileSales,
      icon: assets.mobileIcon,
      className: "mobile-sales-table"
    }
    const isPrintLayout = this.isPrintLayout()

    return (
      <>
        <Box className="tables-section">
          {
            (isPrintLayout ? [mobileSalesTable] : [sectionSalesTable, mobileSalesTable]).map((table) => (
              <CustomCard
                key={table.title}
                title={table.title}
                onNavigate={table.onNavigate}
                icon={table.icon}
                className={table.className}
              >
                <SectionSalesTable tableData={table.tableData} />
              </CustomCard>
            ))
          }
          <CustomCard
            title="Stores"
            icon={assets.storeIcon}
          >
            <Box display="flex" flexDirection="column" gridGap={12} padding="8px 12px">
              {
                [
                  {
                    label: "Stores",
                    value: storeDetails.stores_count
                  },
                  {
                    label: "Active Stores",
                    value: storeDetails.active_stores_count
                  },
                  {
                    label: "New Stores",
                    value: storeDetails.new_stores_count
                  },
                  {
                    label: "Closed Stores",
                    value: storeDetails.closed_stores_count
                  },
                  {
                    label: "Top Stores",
                    value: storeDetails.top_stores_count
                  },
                  {
                    label: "Low Stores",
                    value: storeDetails.low_stores_count
                  }
                ].map(item => (
                  <Box
                    key={item.label}
                    padding="6px 0"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gridGap={8}
                  >
                    <StyledLabel fontColor={colors().darkjunglegreen}>
                      {item.label}
                      {
                        item.label === "New Stores" &&
                        <img
                          className="view-icon go-to-details"
                          data-test-id="new-stores-view-btn"
                          src={assets.viewIcon}
                          onClick={() => this.toggleModal("new_stores")}
                        />
                      }
                    </StyledLabel>
                    <StyledValue>
                      {item.value}
                    </StyledValue>
                  </Box>
                ))
              }
            </Box>
          </CustomCard>
        </Box>
        {
          isPrintLayout && (
            <CustomCard
              key={sectionSalesTable.title}
              title={sectionSalesTable.title}
              onNavigate={sectionSalesTable.onNavigate}
              icon={sectionSalesTable.icon}
              className={sectionSalesTable.className}
            >
              <SectionSalesTable tableData={sectionSalesTable.tableData} />
            </CustomCard>
          )
        }
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      regionName,
      storeId,
      showModal,
      isLoadingOverallSale,
      isLoadingRegionSale,
      isLoadingSaleDetail,
      groupId
    } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <DashboardTabPanel navigation={this.props.navigation} id="MainDashboard" permissionStatus={this.state.permissionStatus}  />
        <Box>
          <StyledWrapper id={this.state.openExportModal ? "print-wrapper" : undefined} className={this.isPrintLayout() ? "print-layout" : undefined}>
            <Box data-test-id={configJSON.dashboardHeader}>
              <DashboardHeader
                regionVal={regionName}
                storeVal={storeNameBasedOnGroup(storeId)}
                isGroup={true}
                groupVal={getGroupNameDisplay(groupId)}
                handleFilterButton={this.handleFilterButton}
                handleCloseFilterButton={this.handleCloseFilterButton}
                handleFilterChange={this.handleFilterChange}
                filterAnchor={this.state.filterAnchor}
                filters={this.state.filters as IFilter[]}
                periodDateRange=""
                dateRange={this.dateRange()}
                elementBeforeFilter={
                  <Box className="datepicker-wrapper">
                    <StyledInlineDatePicker
                      data-test-id="main-dashboard-datepicker"
                      format="DD-MM-YYYY"
                      value={this.selectedDateValue()}
                      InputProps={{
                        endAdornment: null,
                        style: {
                          paddingLeft: 10,
                          width: 144
                        },
                        readOnly: true
                      }}
                      onChange={this.handleChangeSelectedDate}
                    />
                  </Box>
                }
                elementAfterFilter={
                  <div className="actions">
                    <button
                      type="button"
                      onClick={() => this.handleOpenExportModal()}
                    >
                      {configJSON.exportAll}
                    </button>
                  </div>
                }
              />
            </Box>


            {
              isLoadingOverallSale ? (
                <CustomCard title="Overall Sales" icon={assets.statsIcon}>
                  <Skeleton animation="wave" variant="rect" width={"full"} height={100} />
                </CustomCard>
              ) :

                <CustomCard title="Overall Sales" icon={assets.statsIcon}>
                  { this.renderOverallSales() }
                </CustomCard>
            }

            <Box className="sales-charts">
              <CustomCard title="Total Sales" icon={assets.pieChartIcon}>
                {this.renderPieChart()}
              </CustomCard>


              {
                isLoadingRegionSale ? (
                  <CustomCard title="Region Sales" icon={assets.barChartIcon}>
                    <Skeleton animation="wave" variant="rect" width={"full"} height={150} />
                  </CustomCard>
                ) :
                  <CustomCard title="Region Sales" icon={assets.barChartIcon}>
                    <p className="chart-description">{configJSON.regionSalesGraphDesc}</p>
                    <Box className="region-sales-chart">
                      <CustomBarGraph
                        BarData={this.regionSalesChartData()}
                        Height={"114px"}
                        steps={this.getBarChartYAxisValues()}
                      />
                    </Box>
                  </CustomCard>
              }

              {
                isLoadingSaleDetail ? (
                  <CustomCard title="Sales" icon={assets.barChartIcon}>
                    <Skeleton animation="wave" variant="rect" width={"full"} height={150} />
                  </CustomCard>
                ) :
                  <CustomCard title="Sales" icon={assets.horizontalBarChartIcon}>
                    <Box className="sales-by-day">
                      {this.renderSalesByDayProgress()}
                    </Box>
                  </CustomCard>
              }
              {
                isLoadingRegionSale ? (
                  <CustomCard title="Region Sales" icon={assets.barChartIcon}
                    onNavigate={() => this.toggleModal("region_sales")}
                    navigateIcon={<img data-test-id="sales-view-btn" src={assets.viewIcon} />}
                  >
                    <Skeleton animation="wave" variant="rect" width={"full"} height={150} />
                  </CustomCard>
                ) :
                  <CustomCard
                    title="Region Sales"
                    icon={assets.regionIcon}
                    onNavigate={() => this.toggleModal("region_sales")}
                    navigateIcon={<img data-test-id="sales-view-btn" className="go-to-details" src={assets.viewIcon} />}>
                    <Box className="region-sales">
                      {
                        this.state.regionSales.map(saleItem => (
                          <Box display="flex" justifyContent="space-between" alignItems="center" key={saleItem.region_name} gridGap={10}>
                            <StyledLabel className="region-sales-item">{saleItem.region_name}</StyledLabel>
                            <Box className="custom-chip">
                              SAR {saleItem.total}
                            </Box>
                          </Box>
                        ))
                      }
                    </Box>
                  </CustomCard>
              }
            </Box>

            {this.renderCustomerSection()}

            {this.renderTableSection()}
          </StyledWrapper>
        </Box>
        {
          this.state.openExportModal && <ExportModal isOpen onCancel={this.handleOpenExportModal} onConfirm={this.handleExportConfirm} />
        }
        {
          showModal &&
          <StyledDialog open onClose={() => this.toggleModal("")}>
            {this.renderDialog()}
          </StyledDialog>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  paddingBottom: 20,
  "&#print-wrapper": {
    gap: 30,
    "& .d-flex, & .go-to-details": {
      visibility: "hidden"
    },
    "& .customers-section-btn": {
      display: "none"
    },
    "& .tables-section": {
      gridTemplateRows: "auto",
    },
    "&.print-layout": {
      "& .tables-section": {
        gridTemplateColumns: "1fr 1fr",
      },
      "& .section-sales-table": {
        breakBefore: "always"
      },
    }
  },
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 12,
    marginTop: 10,
    alignItems: "center",
  },
  "& .rangeBox": {
    display: "flex",
    gap: 40,
    "& > h2:last-of-type": {
      display: "none"
    },
  },
  "& .PageHeading": {

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().darkjunglegreen,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .storeval": {
    marginLeft: "50px",
  },
  "& .datePeriod": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .d-flex": {
    display: "flex",
  },
  "& .flexstyle": {
    flexWrap: "wrap",
    gap: 20,
  },
  "& .datepicker-wrapper": {
    marginRight: 16,
    "& .MuiInputBase-root": {
      height: 44,
    },
    "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd": {
      display: "none"
    }
  },
  "& .filterButtonAccount": {
    cursor: "pointer",
    background: colors().white,
    height: "44px",
    color: colors().darkliver,
    width: "44px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors().lightborder,
    "@media only screen and (max-width: 1024px)": {
      height: 44,
      width: 44,
    },
  },
  "& .actions": {
    marginLeft: 18,
    display: "flex",
    gap: 16,
    "& > button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
      padding: "10px 16px",
      lineHeight: 1.5,
      border: 0,
      borderRadius: 8,
      color: colors().darkliver,
      background: colors().lightgray,
      fontWeight: 600,
      cursor: "pointer",
      fontSize: "16px",
    }

  },
  "& .overall-sales": {
    flex: 1,
    marginTop: 16,
    display: "grid",
    gap: 8,
    gridTemplateColumns: "repeat(10, 1fr)",
    overflow: "auto",
    "& .MuiBox-root": {
      padding: "12px",
      wordBreak: "break-all",
      "&:not(:last-of-type)": {
        borderRight: `solid 1px ${colors().lightborder}`,
      },
      "& > p:first-of-type": {
        marginBottom: "12px"
      }
    }
  },
  "& .sales-charts": {
    display: "grid",
    maxWidth: "100%",
    gridTemplateColumns: "minmax(302px, 385px) minmax(220px, 1.75fr) 1.13fr minmax(234px, 1.35fr)",
    gap: 12,
    gridTemplateRows: "220px",
    "@media only screen and (max-width:1280px)": {
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "220px 220px",
    },
    "& .pie-chart-wrapper": {
      display: "flex",
      justifyContent: "space-around",
      gridGap: 10,
      flex: 1,
      padding: 4,
      "& > div:first-of-type": {
        paddingTop: 16,
        "&.pie-chart-empty": {
          width: 120,
          display: "grid",
          placeItems: "center",
          fontWeight: 500,
          fontSize: 23
        }
      },
      "& .pie-chart-legends": {
        padding: "0 4px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: 12,
        minWidth: 144,
        gap: 10,
        marginBottom: -16,
        "& p": {
          wordBreak: "break-all",
          "& > span": {
            whiteSpace: "nowrap"
          }
        }
      }
    },
    "& .chart-description": {
      fontSize: 12,
      color: colors().darkjunglegreen,
      padding: "10px 10px 0 10px",
      whiteSpace: "nowrap",
      minHeight: 25,
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "& .region-sales-chart": {
      minHeight: 130,
      "& > div": {
        width: "unset"
      },
      "& .noDataConatainer": {
        display: "grid !important"
      },
      "& .paymentOptionContainerGraph": {
        display: "flex",
        gap: 10,
        padding: 0,
        boxShadow: "none",
        "& .paymentSummaryHeaderContainer": {
          display: "none"
        },
        "& .columnGraphContainer": {
          flex: 1,
          marginTop: 10,
          gap: 6,
          "& .columnLimitContainer": {
            "& > p": {
              color: colors().darkliver
            },
          },
          "& .graphScrollBox": {
            bottom: -10
          }
        },
        "& .graphLegendsContainer": {
          flexDirection: "column",
          maxWidth: "50%",
          gap: 4,
          marginTop: 12,
          marginBottom: 10,
          paddingRight: 2,
          "& .LegendIcon": {
            flexShrink: 0
          },
        }
      },
    },
    "& .sales-by-day": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      flex: 1,
      paddingTop: 12
    },
  },
  "& .customers-section": {
    padding: 8,
    marginTop: 16,
    display: "flex",
    gap: 8,
    border: `solid 1px ${colors().lightborder}`,
    borderRadius: 12,
    "& .customers-section-label": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 129
    },
    "& .customers-section-data": {
      flex: 1,
      display: "grid",
      gridTemplateColumns: "repeat(5, 1fr)",
      overflow: "auto",
      "& > .MuiBox-root": {
        borderRadius: 8,
        padding: "12px",
        wordBreak: "break-word",
        "&:nth-of-type(-n+4)": {
          marginRight: -12,
        },
        "& > p": {
          paddingRight: 5,
          "&:first-of-type": {
            marginBottom: "12px",
          }
        },
      },
    },
    "& .customers-section-btn": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `solid 1px ${colors().lightborder}`,
      width: 40,
      flexShrink: 0,
      borderRadius: 8,
    },
  },
  "& .region-sales": {
    marginTop: 16,
    paddingLeft: 12,
    paddingRight: 4,
    display: "flex",
    flexDirection: "column",
    gap: 12,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#bbb"
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1"
    },
    "& .region-sales-item": {
      color: colors().darkjunglegreen,
      whiteSpace: "normal"
    },
    "& .custom-chip": {
      padding: 6,
      minWidth: 84,
      borderRadius: 6,
      backgroundColor: colors().brightgray,
      textAlign: "center",
      lineHeight: 1.4,
      fontWeight: 500,
      flexShrink: 0
    }
  },
  "& .tables-section": {
    display: "grid",
    gridTemplateColumns: "minmax(35%, max-content) minmax(min-content, 35%) minmax(min-content, 1fr)",
    gridTemplateRows: "360px",
    gap: 12,
    paddingBottom: 20,
    "& > .custom-card": {
      paddingBottom: 16,
      "& .MuiTableContainer-root::-webkit-scrollbar": {
        width: 5
      },
      "& .MuiTableContainer-root::-webkit-scrollbar-thumb": {
        background: "#bbb"
      },
      "& .MuiTableContainer-root::-webkit-scrollbar-track": {
        background: "#f1f1f1"
      },
    },
    "& .MuiTableCell-root": {
      border: 0,
      "& .no-data-message": {
        display: "grid",
        placeItems: "center",
        minHeight: 100,
        fontWeight: 500,
        fontSize: 16
      }
    },
    "& .view-icon": {
      verticalAlign: "middle",
      marginLeft: 8
    },
    "@media only screen and (max-width:1360px)": {
      gridTemplateColumns: "unset",
      gridTemplateRows: "unset"
    },
  },
  "& .section-sales-table": {
    backgroundColor: colors().lightlavender,
    "& .custom-card-header": {
      borderBottomColor: colors().purple
    },
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: colors().lightlavender
    },
    "& .MuiTableCell-root": {
      border: 0,
      "& .no-data-message": {
        display: "grid",
        placeItems: "center",
        minHeight: 100,
        fontWeight: 500,
        fontSize: 16
      }
    },
  },
  "& .mobile-sales-table": {
    backgroundColor: colors().lightblue,
    "& .custom-card-header": {
      borderBottomColor: colors().blueborder
    },
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: colors().lightblue,
    }
  },
  "& .go-to-details, & .customers-section-btn": {
    cursor: "pointer"
  }
})

const BorderLinearProgress = styled(LinearProgress)(({ customColor }: { customColor: string }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: colors().lightgray,
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundColor: customColor || '#1a90ff',
  }
}))

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: 24,
    padding: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
    minWidth: "fit-content",
    maxWidth: 1280
  },
  "& h2.modal-title": {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 1.25
  },
  "& .actions": {
    "& > button": {
      padding: 12,
      borderRadius: 8,
      width: 186,
      border: 0,
      fontWeight: 700,
      lineHeight: 1.5,
      textTransform: "none",
      cursor: "pointer",
      backgroundColor: colors().antiFlashWhite,
      color: colors().slateGray,
      "@media (max-width: 1024px)": {
        padding: "10px 0"
      },
    },
  },
  "& .region-sales-grid": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: 16,
    gap: 16,
    borderRadius: 12,
    maxHeight: 565,
    overflow: "auto",
    boxShadow: "0px 2px 8px 0px #00000014",
    "& > div:only-of-type": {
      gridColumn: "span 2"
    },
    "& .no-data-message": {
      width: 300,
      height: 150,
      gridColumn: "span 2",
      display: "grid",
      placeItems: "center",
      fontSize: 16,
      fontWeight: 500
    },
    "& .custom-card": {
      padding: 0,
    },
    "& .MuiTableContainer-root": {
      maxHeight: 200,
      "&::-webkit-scrollbar": {
        width: 3,
        height: 3
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
      },
    },
    "& .MuiTableBody-root .MuiTableRow-root:last-of-type > .MuiTableCell-root": {
      paddingBottom: 16
    },
    "& .MuiTableCell-root": {
      padding: 8,
      borderBottom: 0,
      "&.MuiTableCell-head": {
        "& > p": {
          fontSize: 13,
          fontWeight: 500,
          color: colors().darkliver
        },
        "&:first-of-type > p": {
          color: colors().darkjunglegreen
        }
      },
      "&.MuiTableCell-body, &.MuiTableCell-footer": {
        "& > p": {
          fontSize: 14,
          fontWeight: 500,
          color: colors().darkliver,
        },
        "&:first-of-type > p": {
          fontWeight: 700
        }
      },
      "&.MuiTableCell-footer": {
        background: colors().brightgray,
        position: "sticky",
        bottom: 0,
        "&:first-of-type": {
          borderRadius: "8px 0 0 8px"
        },
        "&:last-of-type": {
          borderRadius: "0 8px 8px 0 "
        }
      }
    },
  },
  "& .new-stores-table": {
    minWidth: 756,
    maxWidth: 900,
    "& .table-header": {
      display: "grid",
      gridTemplateColumns: "0.35fr 0.18fr 0.2fr 0.27fr",
      fontWeight: 600,
      color: "white",
      backgroundColor: colors().cyancobaltblue,
      padding: "4px 12px",
      borderRadius: "12px 12px 0 0",
      "& > p": {
        padding: 8
      }
    },
    "& .table-body": {
      fontWeight: 500,
      display: "flex",
      flexDirection: "column",
      gap: 8,
      padding: 12,
      maxHeight: 470,
      overflow: "auto",
      boxShadow: "0px 2px 8px 0px #00000014",
      borderRadius: "0 0 12px 12px",
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
      },
    },
    "& .table-row": {
      display: "grid",
      gridTemplateColumns: "0.35fr 0.18fr 0.2fr 0.27fr",
      borderRadius: 12,
      border: `solid 1px ${colors().lightborder}`,
      "& > p": {
        padding: "8px 12px",
        wordBreak: "break-word"
      }
    }
  }
})
// Customizable Area End