import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikValues } from "formik";
import { debounce, concat, uniqBy } from "lodash";
import { apiCall, handleLogout, makeApiMessage } from "../../../components/src/common";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { IMyUser, PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import moment from "moment";


interface DropdownOption {
  id: string;
  option: string
};
export interface PriceListDropdownOptions {
  id: string,
  type: string,
  attributes: {
    id: 35,
    name: string,
    price_list_type: string,
    is_master_price_list: boolean
  }
};

interface RegionDropdownOptions {
  id: string,
  type: string,
  attributes: {
    id: 88,
    branch_name: string,
    name: string
  }
};

interface AreaDropdownOptions {
  id: string,
  type: string,
  attributes: {
    id: 88,
    area_name: string
  }
};

interface StoreDropdownOptions {
  id: string,
  type: string,
  attributes: {
    id: 88,
    store_name: string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  open: any;
  handleClose: any;
  customerData?: any;
  isEdit: boolean;
  handleGetCustomerPhone?: (customerData: any) => void;
  priceId?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  accountError: any;
  addPriceInitialvalues: any;
  addNewProfessionPopup: any;
  addNewOrganisationPopup: any;
  dropdownOptions: any;
  openPriceListSnackbar: any;
  snackbarMessage: any;
  formError: any;
  formErrorFlag: any;
  openErrorSnackbar: any;
  snackbarErrorMessage: any;
  optionValue: any;
  preferenceModalVisible: boolean;
  preferenceData: any[];
  lastCreatedCustomer: any;

  autoCopmpleteValue: string;
  pricelistDropPage: number;
  selectedPriceList: { id: number, option: string } | null;
  editSelectedPricelist: { id: string, option: string } | null;
  isMasterPricelistId: string;
  permissionStatus: PermissionStatus;
  regionList: Array<DropdownOption>;
  buttonLoading: boolean;
  priceListId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddCustomerModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCityDropdownOptionsApiCallId: string = "";
  getGenderDropdownOptionsApiCallId:string = "";
  getProfessionDropdownOptionsApiCallId:string = "";
  getOrganisationDropdownOptionsApiCallId:string = "";
  getBusinessDropdownOptionsApiCallId:string = "";
  getPaymentTypeDropdownOptionsApiCallId:string = "";
  addProfessionDropdownOptionApiCallId:string = "";
  addOrganisationDropdownOptionApiCallId:string = "";
  addNewCustomerApiCallId:string = "";
  editCustomerApiCallId:string = "";
  authToken: string = "";

  itemsPerPage: number = 10;
  disableLoadMorePriceList = false;
  getPriceListApiCallId:string = "";
  getAreaListApiCallId: string = "";
  getStoreListApiCallId: string = "";
  getRegionListApiCallId: string = "";
  getEmployeeDataId: string = "";


  async componentDidMount() {
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }

    if(this.props.isEdit) {
      this.isEditDetails();
    } else {
      this.isCreateDetails();
      this.getAllPriceLists();
    }

    this.getMyUserData();
    this.getGenderDropdownOptions();
    this.getCityList();
    this.getAllRegionList();
  
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      accountError: "",
      isLoading: false,
      preferenceModalVisible: false,
      preferenceData: [],
      lastCreatedCustomer: undefined,
      addPriceInitialvalues: {
        name: '',
        mobile_number_prefix: '+966',
        mobile_number: '',
        email: '',
        gender: '',
        birthday: '',
        profession: '',
        organisation: '',
        street_address: '',
        city: '',
        postal_code: '',
        id: '',
        business: '',
        price_list: '',
        payment_type: '',
        notes: '',
        private_note: '',
        region_id: '',
        price_list_expiry_date: '',
        price_list_region_id: '',
        price_list_area_id: '',
        price_list_store_id: '',
      },
      addNewProfessionPopup: false,
      addNewOrganisationPopup: false,
      dropdownOptions: {
        gender: [],
        profession: [],
        organisation: [],
        organisationTax: [],
        business: [],
        payment_type: [],
        city: [],
        price_list: [],
        area_list: [],
        store_list: []
      },
      openPriceListSnackbar: false,
      snackbarMessage: '',
      formError: {
        profession: '',
        organisation: ''
      },
      formErrorFlag: false,
      openErrorSnackbar: false,
      snackbarErrorMessage: '',
      optionValue: '',

      autoCopmpleteValue: '',
      pricelistDropPage: 1,
      selectedPriceList: null,
      editSelectedPricelist: null,
      isMasterPricelistId: '',
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        isHaveLinkPricelistPermission: false
      },
      regionList: [],
      buttonLoading: false,
      priceListId: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.getProfessionDropdownOptionsApiCallResponse(apiRequestCallId, responseJson);
      this.getGenderDropdownOptionsApiCallResponse(apiRequestCallId, responseJson);
      this.getBusinessDropdownOptionsApiCallResponse(apiRequestCallId, responseJson);
      this.getPaymentTypeDropdownOptionsApiallResponse(apiRequestCallId, responseJson);
      this.getCityListApiCallResponse(apiRequestCallId, responseJson)
      this.addNewCustomerApiCallResponse(apiRequestCallId, responseJson);
      this.editCustomerApiCallResponse(apiRequestCallId, responseJson);
      this.getOrganisationDropdownOptionsApiCallResponse(apiRequestCallId, responseJson);

      this.getPriceListsApiCallResponse(apiRequestCallId, responseJson);
      this.getRegionApiCallResponse(apiRequestCallId, responseJson);
      this.getAreaListApiCallResponse(apiRequestCallId, responseJson);
      this.getStoreListApiCallResponse(apiRequestCallId, responseJson);
      this.getEmployeeDataApiCallResponse(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkGetResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  handleCreatePreferenceForCustomer = (data: any) => {
    const { isEdit } = this.props
    this.setState({ isLoading: true })
    this.setState({
      preferenceData: data?.map((item: {
        id?: number;
        variant_id: string;
        preferenceId: string;
        upchargeListIds: number[];
        serviceId: string;
        notes?: string;
        saveForFuture: boolean;
        selectedOptions?: {
          option_id: string;
        }[]
      }) => {
        return {
          id: isEdit ? item.id : '',
          catalogue_variant_id: Number(item.variant_id),
          preference_id: Number(item.preferenceId),
          upcharge_list_ids: item.upchargeListIds,
          service_id: item.serviceId,
          notes: item.notes,
          save_for_future: item.saveForFuture,
          pref_spec_options_attributes: (
            item.selectedOptions as {
              specification_id: number;
              option_id: string;
            }[]
          )
            .filter((item) => item.option_id)
            .map((item) => ({
              specification_option_id: Number(item.option_id),
            })),
        }
      })
    }, () => {
      this.setState({ isLoading: false })
    });
  }

  getGenderDropdownOptionsApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getGenderDropdownOptionsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            gender: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.label } })
          }
        }), () => {this.getBusinessDropdownOptions()});
      } else {
        this.setState({ isLoading: false })
      }
    }
  }
  
    getBusinessDropdownOptionsApiCallResponse(apiRequestCallId: any, responseJson: any) {
      if (apiRequestCallId === this.getBusinessDropdownOptionsApiCallId) {
        if (this.checkGetResponse(responseJson)) {
          this.setState(() => ({
            dropdownOptions: {
              ...this.state.dropdownOptions,
              business: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.label } })
            }
          }), () => { this.getPaymentTypeDropdownOptions() });
        } else {
          this.setState({ isLoading: false })
        }
      }
    }
  
    getPaymentTypeDropdownOptionsApiallResponse(apiRequestCallId: any, responseJson: any) {
      if (apiRequestCallId === this.getPaymentTypeDropdownOptionsApiCallId) {
        if (this.checkGetResponse(responseJson)) {
          this.setState(() => ({
            dropdownOptions: {
              ...this.state.dropdownOptions,
              payment_type: responseJson.data?.map((item: any) => { return { id: item.id, option: item.name } })
            }
          }), () => { this.getProfessionDropdownOptions(); this.getOrganisationDropdownOptions() });
        } else {
          this.setState({ isLoading: false })
        }
      }
    }

  getProfessionDropdownOptionsApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getProfessionDropdownOptionsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            profession: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.profession_name } })
          }
        }));
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  getOrganisationDropdownOptionsApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getOrganisationDropdownOptionsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            organisation: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.name, active: item.attributes.active } }).filter((item: { active: boolean }) => item.active),
            organisationTax: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.tax_number } })
          }
        }));
      } else {
        this.setState({ isLoading: false })
      }
    }
  }
  
  addNewCustomerApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.addNewCustomerApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ buttonLoading: false, snackbarMessage: "Customer Created Successfully.", openPriceListSnackbar: true });
        setTimeout(() => {
          if (this.props.handleGetCustomerPhone) {
            this.state.lastCreatedCustomer.full_phone_number = this.state.lastCreatedCustomer.full_phone_number.replace(
              "+",
              ""
            );
            this.props.handleGetCustomerPhone({
              customer_id: responseJson?.data?.id,
              ...this.state.lastCreatedCustomer
            });
          }
          this.props.handleClose();
        }, 1000);
      } else {
        this.setState({ buttonLoading: false, openErrorSnackbar: true, snackbarErrorMessage: responseJson && responseJson.errors[0]?.message })
      }
    }
  }
  
  editCustomerApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.editCustomerApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({
          buttonLoading: false,
          snackbarMessage: "Customer Updated Successfully.",
          openPriceListSnackbar: true
        }, () => {
          if (this.props.handleGetCustomerPhone) {
            this.state.lastCreatedCustomer.full_phone_number = this.state.lastCreatedCustomer.full_phone_number.replace(
              "+",
              ""
            );
            this.props.handleGetCustomerPhone({
              customer_id: responseJson?.data?.id,
              ...this.state.lastCreatedCustomer
            });
          }
          this.props.handleClose();
        });
      } else {
        this.setState({ buttonLoading: false, openErrorSnackbar: true, snackbarErrorMessage: responseJson && responseJson.errors[0]?.message })
      }
    }
  }

  getCityListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getCityDropdownOptionsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            city: responseJson?.cities || []
          }
        })        
      }
    }
  }

  getGenderDropdownOptions() {
    let headers = { "Content-type": "application/json" }
    let url = `${configJSON.getDropdownOptionApiEndPoint}?module_name=Gender`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getDropdownOptionApiMethod,
    });

    this.getGenderDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getProfessionDropdownOptions() {
    let url = `${configJSON.getProfessionDropdownOptionApiEndPoint}?dropdown=true`;
    let headers = { "Content-type": "application/json", token: this.authToken }
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getDropdownOptionApiMethod,
    });

    this.getProfessionDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getOrganisationDropdownOptions() {
    let url = `${configJSON.getOrganisationTaxApiEndPoint}?dropdown=true`;
    let headers = { "Content-type": "application/json", token: this.authToken }
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getDropdownOptionApiMethod,
    });

    this.getOrganisationDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getBusinessDropdownOptions() {
    let headers = { "Content-type": "application/json" }
    let url = `${configJSON.getDropdownOptionApiEndPoint}?module_name=Business`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getDropdownOptionApiMethod,
    });

    this.getBusinessDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getPaymentTypeDropdownOptions() {
    let url = `${configJSON.getPaymentTypeApiEndPoint}`;
    let headers = { "Content-type": "application/json" }
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getDropdownOptionApiMethod,
    });

    this.getPaymentTypeDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleAddCustomer(values: any) {
    this.setState({ buttonLoading: true });
    const data = {
      full_name: values.name,
      email: values.email,
      gender_id: values.gender,
      date_of_birth: values.birthday,
      country_code: values?.mobile_number_prefix,
      full_phone_number: values.mobile_number_prefix + values.mobile_number,
      employee_id: values.id,
      notes: values.notes,
      city_id: values.city,
      street_address: values.street_address,
      profession_id: values.profession,
      organization_id: values.organisation,
      post_code: values.postal_code,
      business_id: values.business,
      payment_id: values.payment_type,
      private_note: values.private_note,
      preferences_attributes: this.state.preferenceData,
      region_id: values.region_id,
      price_list_id: values.price_list,
      store_management_id: values.price_list_store_id,
      price_list_expiry_date: moment(values.price_list_expiry_date).format("YYYY-MM-DD")
    };

    this.setState({ lastCreatedCustomer: data });
    let httpBody: any = {
      data,
    };

    let headers: any = {
      "Content-type": "application/json",
      token: this.authToken,
    };

    let url = `${configJSON.addNewCustomerApiEndPoint}?allow_access=true`;
    const getAccount = apiCall({
      header: headers,
      httpBody: httpBody,
      url: url,
      httpMethod: configJSON.addNewCustomerApiMethod,
    });

    this.addNewCustomerApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleClosePreference = () => {
    this.setState({ preferenceModalVisible: false });
  };

  handleEditCustomer(values: any) {
    this.setState({ buttonLoading: true });
    let httpBody: any = {
        full_name: values.name,
        email: values.email,
        gender_id: values.gender,
        date_of_birth: values.birthday,
        country_code: values?.mobile_number_prefix,
        full_phone_number: "" + values.mobile_number_prefix + values.mobile_number,
        employee_id: values.id,
        notes: values.notes,
        city_id: values.city,
        street_address: values.street_address,
        profession_id: values.profession,
        organization_id: values.organisation,
        post_code: values.postal_code,
        business_id: values.business,
        payment_id: values.payment_type,
        private_note: values.private_note,
        organisation_tax_id: values.organisationTax,
        preferences_attributes: this.state.preferenceData,
        region_id: values.region_id,
        price_list_id: values.price_list,
        price_list_expiry_date: moment(values.price_list_expiry_date).format("YYYY-MM-DD"),
        store_management_id: values.price_list_store_id,
    }

    this.setState({ lastCreatedCustomer: httpBody });

    let headers: any = {
      "Content-type": "application/json",
      token: this.authToken
    }

    let url = `${configJSON.addNewCustomerApiEndPoint}/${this.props.customerData?.id}`;
    const getAccount = apiCall({
      header: headers,
      httpBody: httpBody,
      url: url,
      httpMethod: configJSON.EditCustomerApiMethod,
    });

    this.editCustomerApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  isEditDetails() {
    const price_list_id = this.props.customerData?.attributes.customer_profile.data.attributes?.price_list_expiry_date;
    this.setState(() => ({
      addPriceInitialvalues: {
        ...this.state.addPriceInitialvalues,
        name: this.props.customerData?.attributes?.full_name,
        mobile_number_prefix: this.getCountryCodeVal(this.props.customerData?.attributes?.country_code),
        mobile_number: this.props.customerData?.attributes?.phone_number,
        email: this.props.customerData?.attributes?.email,
        gender: this.props.customerData?.attributes?.customer_profile?.data?.attributes?.gender_id?.id,
        birthday: this.props.customerData?.attributes?.date_of_birth,
        profession: this.props.customerData?.attributes?.customer_profile?.data?.attributes?.profession?.data?.attributes?.profession_name,
        organisation: this.props.customerData?.attributes?.customer_profile?.data?.attributes?.organization?.data?.attributes?.name,
        street_address: this.props.customerData?.attributes?.customer_profile?.data.attributes?.street_address,
        city: this.props.customerData?.attributes?.customer_profile?.data.attributes?.city?.id,
        postal_code: this.props.customerData?.attributes?.customer_profile?.data.attributes?.post_code,
        id: this.props.customerData?.attributes?.customer_profile?.data?.attributes?.employee_id,
        business: this.props.customerData?.attributes?.customer_profile?.data?.attributes?.business_id?.id,
        payment_type: this.props.customerData?.attributes?.customer_profile?.data?.attributes?.payment?.id,
        notes: this.props.customerData?.attributes?.customer_profile?.data?.attributes?.notes,
        private_note: this.props.customerData?.attributes?.customer_profile?.data?.attributes?.private_note,
        organisationTax: this.props.customerData?.attributes?.customer_profile?.data?.attributes?.organisation_tax?.data?.attributes?.tax_number,
        preferences_attributes: this.props.customerData?.attributes?.customer_preferences_attributes,
        region_id: 
          this.props.customerData?.attributes.customer_profile.data.attributes.region?.data?.id,
        price_list: this.props.customerData?.attributes.customer_profile.data.attributes?.price_list_id,
        price_list_expiry_date: price_list_id ? price_list_id : "",
        price_list_region_id: 
          this.props.customerData?.attributes.customer_profile.data.attributes.store_management?.region?.id,
        price_list_area_id:
          this.props.customerData?.attributes.customer_profile.data.attributes.store_management?.area?.id,
        price_list_store_id:
          this.props.customerData?.attributes.customer_profile.data.attributes.store_management?.id
      },
      editSelectedPricelist: {
        id: this.props.customerData?.attributes.customer_profile.data.attributes.price_list.data?.id,
        option: 
          this.props.customerData?.attributes.customer_profile.data.attributes.price_list.data?.attributes.name,
      }
    }), () => this.getAllPriceLists());
  }

  getCountryCodeVal = (countryCode : number|null) => {
    if(countryCode){
      return "+" + countryCode.toString()
    }else{
      return "+966"
    }
}

  isCreateDetails() {
    this.setState(() => ({
      addPriceInitialvalues: {
        ...this.state.addPriceInitialvalues,
        name: this.props.customerData?.attributes?.full_name,
        mobile_number_prefix: this.props.customerData?.attributes?.country_code,
        mobile_number: this.props.customerData?.attributes?.phone_number,
        email: this.props.customerData?.attributes?.email,
        gender: this.props.customerData?.attributes?.gender,
        birthday: this.props.customerData?.attributes?.date_of_birth,
        profession: this.props.customerData?.attributes?.profession,
        organisation: this.props.customerData?.attributes?.organization,
        street_address: this.props.customerData?.attributes?.address,
        city: this.props.customerData?.attributes?.city,
        postal_code: this.props.customerData?.attributes?.post_code,
        id: this.props.customerData?.attributes?.employee_id,
        business: this.props.customerData?.attributes?.business,
        payment_type: this.props.customerData?.attributes?.payment_type,
        notes: this.props.customerData?.attributes?.notes,
        private_note: this.props.customerData?.attributes?.private_note,
      }
    }));
  }

  getFilteredorganisationTaxOptions(selectedorganisation: string) {
    const { organisation, organisationTax } = this.state.dropdownOptions;
  
    let selectedOption = organisation?.find(
      (item: any) => item.option == selectedorganisation
    )
  
    let list: Array<any> = organisationTax?.filter((item: any) => item.option);
    list = list?.filter((item: any) => item.id === selectedOption?.id);
  
    return list;
    
  }

  getCityList() {
    let headers = {
      "Content-type": "application/json"
    }

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: configJSON.getCityListApiEndPoint,
      httpMethod: configJSON.getCityListApiMethod,
    });

    this.getCityDropdownOptionsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handelFormikSubmit(values: FormikValues) {
    const { isEdit } = this.props;
    isEdit ? this.handleEditCustomer(values) : this.handleAddCustomer(values)
  }

  getConditionBasedValues(flag: unknown, trueValue: unknown, falseValue: unknown) {
    return flag ? trueValue : falseValue
  }

  openModal = (selectedPriceList: string) => {
    this.setState({
      priceListId: selectedPriceList,
      preferenceModalVisible: true,
    })
  }

  getHeaderText() {
    const { isEdit } = this.props;
    return isEdit ? "Edit Customer" : "Create New Customer"
  }

  handleScrollPricelistDropdown = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - position <= 1.3 &&
      !this.disableLoadMorePriceList
      ) {
      this.setState({
        pricelistDropPage: this.state.pricelistDropPage + 1
      },
        () => this.getAllPriceLists()
      )
    }
  };

  handleAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.autoCopmpleteValue) return;
    if (getValue.length < 1 || getValue.length > 2) {
      this.setState(
        {
          autoCopmpleteValue: getValue,
          pricelistDropPage: 1,
        },
        () => this.getAllPriceLists()
      );
    }
  };

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) =>
      inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleChangeSelectedPriceList = (
    newSelectedPriceList: { id: number; option: string } | null,
    setFieldValue: (
      field: string,
      value: string | number | Array<number>
    ) => void
  ) => {
    if(newSelectedPriceList) {
      setFieldValue(
        "price_list",
        newSelectedPriceList ? newSelectedPriceList.id : ""
      );
      if(String(newSelectedPriceList.id) == this.state.isMasterPricelistId) {
        setFieldValue("price_list_expiry_date", "");
        setFieldValue("price_list_region_id", "");
        setFieldValue("price_list_area_id", "");
        setFieldValue("price_list_store_id", "");
      }
    }
    if (!newSelectedPriceList) {
      this.debouncedFunction("", this.handleAutoCompleteChange);
    }
  };

  getAllPriceLists = () => {
    const { autoCopmpleteValue } = this.state;

    const page_no = this.state.pricelistDropPage;

    const searchQuery = autoCopmpleteValue
      ? `&filter_by[name]=${this.state.autoCopmpleteValue}`
      : "";

    let apiUrl =
      `${configJSON.getPriceListApiEndPoint}?page_no=${page_no}&per_page=${this.itemsPerPage}&dropdown=true` + searchQuery;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.getDropdownOptionApiMethod,
    });

    this.disableLoadMorePriceList = true;
    this.getPriceListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleReturnPricelistOptions = (
    responseJson: { data: Array<PriceListDropdownOptions> }
  ) => {
    const { editSelectedPricelist } = this.state;

    const optionList = responseJson.data.map((item) => {
      return {
        id: item.id,
        option: item.attributes.name
      }
    });

    if(this.props.isEdit) {
      optionList.push(editSelectedPricelist as DropdownOption)
      const master_price_list_id = responseJson.data.find((item) => {
        if (item.attributes.is_master_price_list) return item;
      });
      this.setState({
        isMasterPricelistId: master_price_list_id?.id as string,
      });
    };

    return optionList;
  };

  getAllRegionList() {
    const getRegion = makeApiMessage({
      url: configJSON.regionListApiEndPoint,
      method: configJSON.getPriceListApiMethod,
    });

    this.getRegionListApiCallId = getRegion.messageId;
    runEngine.sendMessage(getRegion.id, getRegion);
  };

  handleChangePriceListRegion(
    value: { id: number} | null,
    setFieldValue: Function
  ) {
    if(value) {
      setFieldValue("price_list_region_id", value?.id);
      this.getAllAreaLists(value.id);
    } else {
      setFieldValue("price_list_area_id", "");
      setFieldValue("price_list_store_id", "");
      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          area_list: [],
          store_list: []
        }
      });
    }
  };

  getAllAreaLists = (region_id: number) => {
    let apiUrl = `${configJSON.areaListDropdownApiEndPoint}?dropdown=true&region_id=${region_id}`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.getDropdownOptionApiMethod,
    });

    this.getAreaListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangePriceListArea(
    value: { id: number} | null,
    setFieldValue: Function
  ) {
    if(value) {
      setFieldValue("price_list_area_id", value.id);
      this.getAllStoreLists(value.id);
    } else {
      setFieldValue("price_list_store_id", "");
      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          store_list: []
        }
      });
    }
  };

  getAllStoreLists = (area_id: number) => {
    let apiUrl = `${configJSON.storeListDropdownApiEndPoint}?dropdown=true&area_id=${area_id}`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.getDropdownOptionApiMethod,
    });

    this.getStoreListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangePriceListStore(
    value: { id: number} | null,
    setFieldValue: Function
  ) {
    if(value !== null) {
      setFieldValue("price_list_store_id", value.id);
    }
  };

  handleChangeRegion(
    value: { id: number} | null,
    setFieldValue: Function
  ) {
    if(value !== null && value.id) {
      setFieldValue("region_id", value.id);
    } else {
      setFieldValue("region_id", "");
    }
  };

  getMyUserData = () => {
    const apiUrl = configJSON.employeesApiEndPoint;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.validationApiMethodType,
    });
    this.getEmployeeDataId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOnInputChange = (
    setFieldValue: Function,
    formik_key: string,
    value: unknown
  ) => {
    if(value) {
      setFieldValue(formik_key, value);
    } else {
      setFieldValue(formik_key, "");
    }
  };

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (
      this.state.addPriceInitialvalues.price_list_region_id !==
      prevState.addPriceInitialvalues.price_list_region_id
    ) {
      this.getAllAreaLists(this.state.addPriceInitialvalues.price_list_region_id);
    }
    if (
      this.state.addPriceInitialvalues.price_list_area_id !==
      prevState.addPriceInitialvalues.price_list_area_id
    ) {
      this.getAllStoreLists(this.state.addPriceInitialvalues.price_list_area_id);
    }
  };

  async componentWillUnmount() {
    this.setState({
     isMasterPricelistId: ''
    });
   };

  // handle response start
  getPriceListsApiCallResponse(
    apiRequestCallId: string,
    responseJson: { data: Array<PriceListDropdownOptions> }
  ) {
    const { autoCopmpleteValue } = this.state;

    if (apiRequestCallId === this.getPriceListApiCallId) {
      if (
        this.state.pricelistDropPage == 1 &&
        autoCopmpleteValue == "" &&
        !this.props.isEdit
      ) {
        const is_master_pricelist = responseJson.data.find((item) => {
          if (item.attributes.is_master_price_list) return item;
        });

        this.setState({
          addPriceInitialvalues: {
            ...this.state.addPriceInitialvalues,
            price_list: is_master_pricelist?.id,
          },
          isMasterPricelistId: is_master_pricelist?.id as string,
        });
      }

      const optionList = this.handleReturnPricelistOptions(responseJson);
      
      this.disableLoadMorePriceList = optionList.length < this.itemsPerPage;

      let newPriceList =
        this.state.pricelistDropPage === 1
          ? optionList
          : concat(this.state.dropdownOptions.price_list, optionList);

      this.state.selectedPriceList &&
        newPriceList.unshift(this.state.selectedPriceList);

      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          price_list: uniqBy(newPriceList, "id")
        }
      });
    }
  };

  getRegionApiCallResponse(
    apiRequestCallId: string,
    responseJson: { data: Array<RegionDropdownOptions> }
  ) {
    if (apiRequestCallId === this.getRegionListApiCallId) {
      const optionList = responseJson.data.map((item) => {
        return {
          id: item.id,
          option: item.attributes.name
        }
      })
      this.setState({
        regionList: optionList
      });
    }
  };

  getAreaListApiCallResponse = (
    apiRequestCallId: string,
    responseJson: { data: Array<AreaDropdownOptions> }
  ) => {
    if (apiRequestCallId === this.getAreaListApiCallId) {
      const option_lists = responseJson.data.map((area) => {
        const option = {
          id: area.id,
          option: area.attributes.area_name
        };
        return option;
      });

      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          area_list: option_lists
        }
      });
    }
  };

  getStoreListApiCallResponse = (
    apiRequestCallId: string,
    responseJson: { data: Array<StoreDropdownOptions> }
  ) => {
    if (apiRequestCallId === this.getStoreListApiCallId) {
      const option_lists = responseJson.data.map((store) => {
        const option = {
          id: store.id,
          option: store.attributes.store_name
        };
        return option;
      });

      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          store_list: option_lists
        }
      });
    }
  };

  getEmployeeDataApiCallResponse = (
    apiRequestCallId: string,
    responseJson: { data: IMyUser}
  ) => {
    if (apiRequestCallId === this.getEmployeeDataId) {
      const apiKey = customPermissionApiKey.customerPermission;
      const userData = responseJson.data.attributes.permission_groups;
      const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
      this.setState({
        permissionStatus: value
      });
    }
  }

  // Customizable Area End
}
