import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall, handleLogout, makeApiMessage } from "../../../components/src/common";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IMeta } from "./PriceListController.web";
import { DropdownOption } from "./AddPricelistModalController.web";
import { IImportCSVResponse } from "../../importexportdata/src/ImportExportData.web";
import { CustomEnums, checkForImportExportPermissionStatus, getCustomEnumName, navigateTo, randomNumberGenerator } from "../../utilities/src/CustomBlockHelpers";
import ImportExportWebAdapter from "../../adapters/src/ImportExportWebAdapter";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { uniqBy } from "lodash";
export interface IImportCSVResponses {
  status?: number;
  message: string;
  error?: string;
  errors: string;
  url:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  page: number;
  pageSize: number;
  dragItem: any;
  productPagination: IMeta;
  isLoading: boolean;
  openAction: any;
  openSortingFilterAction: any;
  dropdownOptions: any;
  selectedPriceList: any;
  confirmDeactivationModalOpen: boolean;
  productDeactivatedModalOpen: boolean;
  accountError: string;
  getProductDataForDeactivate: any;
  viewProfileId: any;
  selectedFilter: any;
  selectedFilterDrag: any;
  subFilterName: any;
  priceRangeValue: any;
  mainFilterName: any;
  sortingData: ISortingData;
  productQuery: string;
  query: string;
  nameFilter: any;
  priceFilter: any;
  sectionFilter: any;
  productTypeFilter: string;
  preferenceFilter: any;
  isDragableDisabled:boolean,
  sectionPopup: boolean;
  servicePopup: boolean;
  sectionPopupId: any;
  servicePopupId: any;
  optionsList: any;
  openAddPriceListModal: boolean;
  openServicePopup: boolean;
  priceListMeta: IMeta;
  pricelistDropPage: number;
  autoCopmpleteValue: string;
  priceListDropdownEmptyFlag: boolean;
  isInitalRender: boolean;
  openProductImpExtPopover: EventTarget & HTMLButtonElement | null;
  uploadedFile: File | null;
  setLoaded: number;
  errorMessage: string;
  errorSnackbarOpen: boolean;
  snackbarSeverity: "error" | "warning" | "info" | "success";
  permissionStatus: PermissionStatus;
  priceListPermissionStatus: PermissionStatus;
  importButtonLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfPriceListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductListApiCallId: string = "";
  getPriceListApiCallId: string = "";
  deactivateProductApiCallId: string = "";
  activateProductApiCallId: string = "";
  dragAndDropProductApiCallId: string = "";
  filterProductsApiCallId: string = "";
  getSectionListApiCallId: string = "";
  getPreferenceListApiCallId: string = "";
  productSuggessionApiCallId: string = "";
  exportFileApiId: string = "";
  importFileApiId: string = "";
  exportTemplateFileApiId: string = "";
  adapter: ImportExportWebAdapter;
  authToken: string = "";
  timeout: any = null;
  isFilterApplied: boolean = false;

  async componentDidMount() {
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.getPriceList();
    this.timeout = null;
  }

  async componentWillUnmount() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getCustomEnumName(CustomEnums.ImportExportClearFileMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked),
      getName(MessageEnum.SessionResponseMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage),
      getName(MessageEnum.SearchTextMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupFileMessage),
      getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      page: 0,
      pageSize: 100,
      dragItem: [],
      productPagination: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1
      },
      isLoading: false,
      openAction: null,
      openSortingFilterAction: null,
      dropdownOptions: {
        priceList: [],
        productsList: [],
        currency: [
          {id: 'SAR', option: 'SAR'},
          {id: '%', option: '%'},
        ],
        section: [],
        preference: [],
        productNameOptionList: []
      },
      selectedPriceList: '',
      confirmDeactivationModalOpen: false,
      productDeactivatedModalOpen: false,
      accountError: '',
      getProductDataForDeactivate: '',
      viewProfileId: 0,
      selectedFilter: 0,
      selectedFilterDrag: '',
      subFilterName: "",
      priceRangeValue: [0, 100],
      mainFilterName: 'name',
      sortingData: {
        name: '',
        service: '',
        sections: ''
      },
      productQuery: '',
      query: '',
      nameFilter: "",
      priceFilter: {
        order: "",
        range: [0, 100]
      },
      sectionFilter: [],
      productTypeFilter: '',
      preferenceFilter: {},
      isDragableDisabled:false,
      sectionPopup: false,
      servicePopup: false,
      openServicePopup: false,
      sectionPopupId: 0,
      servicePopupId: 0,
      optionsList: [],
      openAddPriceListModal: false,
      priceListMeta: {
        next_page: 0,
        pervious_page: 0,
        total_pages: 0,
        total_count: 0,
        current_page: 0
      },
      pricelistDropPage: 1,
      autoCopmpleteValue: '',
      priceListDropdownEmptyFlag: false,
      isInitalRender: true,
      openProductImpExtPopover: null,
      uploadedFile: null,
      setLoaded: 105,
      errorSnackbarOpen: false,
      snackbarSeverity: "error",
      errorMessage: "",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false,
        priceAddProdPermission: false,
        exportPermission: false
      },
      priceListPermissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      importButtonLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.adapter = new ImportExportWebAdapter();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.getProductListApiCallResponse(apiRequestCallId, responseJson);

      this.dragAndDropProductApiCallResponse(apiRequestCallId, responseJson);

      this.getPriceListApiCallResponse(apiRequestCallId, responseJson);

      this.filterProductsApiCallResponse(apiRequestCallId, responseJson);

      this.deactivateProductApiCallResponse(apiRequestCallId, responseJson);
      this.activateProductApiCallResponse(apiRequestCallId, responseJson);

      this.getSectionListApiCallResponse(apiRequestCallId, responseJson)

      this.getPreferenceListApiCallResponse(apiRequestCallId, responseJson)

      this.productSuggesstionApiCallResponse(apiRequestCallId, responseJson)
      this.exportCsvTemplateFileApiCallResponse(apiRequestCallId, responseJson);
      this.exportCsvFileApiCallResponse(apiRequestCallId, responseJson);      
      this.importCsvFileApiCallResponse(apiRequestCallId, responseJson);
    } else {
      this.handleImportExportActions(from, message);
    }

    this.receiveLayoutData(message)
    this.receiveSearchData(message)
    // Customizable Area End
  }

  // Customizable Area Start
  receiveLayoutData = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      let receivedData = message.getData(getName(MessageEnum.LayoutMessageData));
      if(receivedData.userContext) {
        this.handleUserChange(receivedData.userContext)
      }
    }
  };
  receiveSearchData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
      let receivedData = message.getData(getName(MessageEnum.SearchMessageText));
      if(receivedData) {
        this.onChangeValue(receivedData.searchText)
      }
    }
  };

  handleImportExportActions(from: string, message: Message) {
    switch (from) {
      case getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked):
        this.handleImportExportModalClose();
        break;

      case getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage):
        this.handleFileUpload(message.properties.fileEvent)        
        break;

      case getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage):
        this.handleImportFile()
        break;

      case getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack): 
        this.handleBackToListPage();
        break
        
    }    
  }

  checkGetResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson?.errors);
      return false
    }
  }

  getProductListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getProductListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ isLoading: false, dragItem: responseJson.data, productPagination: responseJson.meta });
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  filterProductsApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.filterProductsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ isLoading: false, dragItem: responseJson.data, productPagination: responseJson.meta, openSortingFilterAction: null });
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  dragAndDropProductApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.dragAndDropProductApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        console.log('')
      }
    }
  }

  getPriceListApiCallResponse(apiRequestCallId: string, responseJson: { data: Array<DropdownOption>}) {
    if (apiRequestCallId === this.getPriceListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        const { isInitalRender } = this.state;
        const array = (responseJson?.data || []).map((item: DropdownOption) => {
          return {
            id: item.attributes.id,
            option: item.attributes.name
          }
        });
        this.setState({isLoading : false})
        this.setState((previous) => {
          const existingPriceListIds = new Set(previous.dropdownOptions.priceList.map((option: { id: number}) => option.id));
          const newArray = array.filter((option: { id: number}) => !existingPriceListIds.has(option.id));
          const updatedPriceList = [...previous.dropdownOptions.priceList, ...newArray];
          const valueArray = this.handleReturnPriceListArray(updatedPriceList);
          return {
            dropdownOptions: {
              ...this.state.dropdownOptions,
              priceList: uniqBy(valueArray, "id"),
            },
            priceListDropdownEmptyFlag: Boolean(array.length === 0)
          }
        });
        if(!isInitalRender) {
          return;
        } else {
          this.setState({
            isInitalRender: false
          })
        };
        const isAppliedFilter = localStorage.getItem("storageFilter");
        let storageFilter = {
          nameFilter: "",
          priceFilter: {
            order: "",
            range: [0, 100]
          },
          sectionFilter: [],
          productTypeFilter: '',
          preferenceFilter: {},
        };
        if(isAppliedFilter) {
          storageFilter = JSON.parse(isAppliedFilter);
        };
        this.setState({ 
          selectedPriceList: this.handleReturnSelectedPricelist(responseJson), 
          subFilterName: '',
          nameFilter: storageFilter?.nameFilter,
          productTypeFilter: storageFilter?.productTypeFilter,
          sectionFilter: storageFilter?.sectionFilter,
          priceFilter: storageFilter?.priceFilter,
          preferenceFilter: storageFilter?.preferenceFilter
          }, () => this.handleApplyFilter());
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  handleReturnSelectedPricelist = (responseJson: { data: Array<DropdownOption>}) => {
    // storage selected pricelist return function
    let selectedStoragePricelist = responseJson?.data?.filter(
      (list: DropdownOption) => {
        return list.attributes.is_master_price_list;
      }
    )[0]?.id;

    const storage_pricelist = localStorage.getItem(
      "selected_product_pricelist"
    );
    if (storage_pricelist) {
      selectedStoragePricelist = JSON.parse(storage_pricelist).id;
    }

    return selectedStoragePricelist;
  };

  handleReturnPriceListArray = (array: {id: number, option: string}[]) => {
    // Return storage selected pricelist with includes array
    const { isInitalRender } = this.state;

    const storage_pricelist = localStorage.getItem(
      "selected_product_pricelist"
    );

    if (storage_pricelist && isInitalRender) {
      let tempArray = JSON.parse(JSON.stringify(array));
      tempArray.push(JSON.parse(storage_pricelist));
      return tempArray;
    } else {
      return array;
    }
  };

  deactivateProductApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.deactivateProductApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ isLoading: false, confirmDeactivationModalOpen: false, productDeactivatedModalOpen: true })
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  activateProductApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.activateProductApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ isLoading: false }, () => {this.handleApplyFilter()})
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  getSectionListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getSectionListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            section: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.section_name, checked: false } })
          }}))
      }
    }
  }

  getPreferenceListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getPreferenceListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({dropdownOptions: {
            ...this.state.dropdownOptions,
            preference: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.preference_first_name, image: item?.attributes?.gallery_icon?.image } })
          }}))
      }
    }
  }

  productSuggesstionApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.productSuggessionApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        const list = responseJson?.filter_names?.map((value: string) => ({ label: value, value })) || [];
          this.setState({ optionsList: list });
        }
    }
  }

  exportCsvTemplateFileApiCallResponse(apiRequestCallId: string, responseJson: {message:string, url: string}) {
    if (apiRequestCallId === this.exportTemplateFileApiId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ openProductImpExtPopover: null, errorSnackbarOpen: true, snackbarSeverity: 'success', errorMessage:'Excel file successfully has been exported' })
        location.href = responseJson.url
      }
    }
  }

  exportCsvFileApiCallResponse(apiRequestCallId: string, responseJson: { data: { url: "string"}}) {
    if (apiRequestCallId === this.exportFileApiId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ errorSnackbarOpen: true, snackbarSeverity: 'success', errorMessage: 'Excel file successfully has been exported' })
        location.href = responseJson.data.url
      }
    }
  }
  importCsvFileApiCallResponse(apiRequestCallId: string, response: IImportCSVResponses) {
    if (apiRequestCallId !== this.importFileApiId) return;
    if (!response) return;
    
    // import button loader
    const messageLoader = new Message(getCustomEnumName(CustomEnums.ImportAPIDoneRepsonseMessage))
    messageLoader.addData('loader', "false");
    runEngine.sendMessage(messageLoader.id, messageLoader);
      
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportAPIResponse))
    message.addData('APIresponse', response)
    runEngine.sendMessage(message.id, message);
    if (response?.message) {
      this.setState({ snackbarSeverity: 'success', errorSnackbarOpen: true, errorMessage: response.message });
      this.props.navigation.goBack();
      this.handlePageChange(0);
    }
    else if (response?.errors) {
      const message = new Message(getCustomEnumName(CustomEnums.ImportExportErrorPopupData))
      message.addData('APIresponse', response);
      runEngine.sendMessage(message.id, message);
      navigateTo({
        props: this.props,
        screenName: "ImportExportErrorModal",
        raiseMessage: message
      })
    }
  }

  handleAddUser = () => {
    this.props.navigation.navigate("CfAddProduct");
  };

  handlePageChange = (
    page: number
  ) => {
    this.setState({ page });
    this.setState({isLoading: true, page: page})

    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }

    let apiUrl;
    let name = this.state.nameFilter;
    let priceOrder = this.state.priceFilter?.order;
    let priceRange = JSON.stringify(this.state.priceFilter?.range);
    let defaultPriceRange = JSON.stringify([0, 100]);
    let section = this.state.sectionFilter;
    let productType = this.state.productTypeFilter;
    let preference = Object.keys(this.state.preferenceFilter);

    if (this.state.priceFilter?.order) {
      this.setState(() => ({ sortingData: { ...this.state.sortingData, price_order: this.state.priceFilter?.order } }))
    }

    apiUrl = configJSON.getNewProductListApiEndPoint + 
    (`?page_no=${page+1}&per_page=100&price_list_id=${this.state.selectedPriceList}&for_pos=true`) +
    (name ? `&filter_by[name]=${this.state.nameFilter}` : '') +
    (priceOrder ? `&filter_by[price_order]=${this.state.priceFilter?.order}` : '') +
    (defaultPriceRange !== priceRange ? `&filter_by[start_price]=${this.state.priceFilter?.range[0]}&filter_by[end_price]=${this.state.priceFilter?.range[1]}` : '') +
    (section?.length !== 0 ? `&filter_by[section_ids][]=${this.state.sectionFilter}` : '') +
    (productType ? `&filter_by[product_type]=${this.state.productTypeFilter}` : '') +
    (preference?.length !== 0 ? `&filter_by[preference]=${this.state.preferenceFilter.option}` : '') +
    (this.state.query ? `${this.state.query}` : '') +
    (this.state.productQuery ? `&filter_by[query]=${this.state.productQuery}` : '');

    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: apiUrl,
      httpMethod: configJSON.getProductListApiMethod,
    });

    this.getProductListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  handlePriceModalOpen = () => {
    this.setState({openAddPriceListModal: true})
  }

  reorder = (list: [], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result:any) => {
    if (!result.destination) {
      return;
    }
    // my changes
    const orderNumberTopData = this.state.dragItem?.[0]?.attributes.order_number
		const updatedData = Array.from(this.state.dragItem);
		const movedItem = updatedData.splice(result.source.index, 1)[0];
		updatedData.splice(result.destination.index, 0, movedItem);

		updatedData.forEach((item: any, index: number) => {
			item.attributes.order_number = orderNumberTopData - index;
		});

		const affectedItems: any[] = updatedData
			.slice(
				Math.min(result.source.index, result.destination.index),
				Math.max(result.source.index, result.destination.index) + 1
			)
			.map((item: any) => ({
				id: item.id,
				order_number: item.attributes.order_number,
			}));

      this.setState({dragItem: updatedData});

    let apiUrl = configJSON.dragAndDropProductApiEndPoint;
    let headers = {
      "Content-type": "application/json",
      token: this.authToken
    }

    let httpBody = {
      data: {
        attributes: affectedItems
      }
    }
    const getAccount = apiCall({
      httpBody: httpBody,
      header: headers,
      url: apiUrl,
      httpMethod: configJSON.dragAndDropProductApiMethod,
    });

    this.dragAndDropProductApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  handleViewProductDetails = () => {
    localStorage.setItem("selectedPricelistId", this.state.selectedPriceList);
    this.props.navigation.navigate("CfViewProduct", {productId: this.state.viewProfileId})
  }
  

  handleCloseActionList = () => {
    this.setState({openAction: null})
  };

  getPriceList() {
    const { autoCopmpleteValue, pricelistDropPage } = this.state;
    let header = {
      token: this.authToken
    }
    const searchQuery = autoCopmpleteValue ? `&filter_by[name]=${this.state.autoCopmpleteValue}` : '&filter_by[name]=';
    let apiUrl = `${configJSON.getPriceListApiEndPoint}?page_no=${pricelistDropPage}&dropdown=true`+ searchQuery;
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url: apiUrl,
      httpMethod: configJSON.getPriceListApiMethod,
    });

    this.getPriceListApiCallId = getAccount.messageId;
    this.setState({isLoading: true})
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleClearSelectedFilter() {
    localStorage.removeItem("storageFilter");
    this.setState({
      nameFilter: "",
      priceFilter: {
        order: "",
        range: [0, 100]
      },
      sectionFilter: [],
      productTypeFilter: '',
      preferenceFilter: {},
      selectedFilter: 0,
      selectedFilterDrag: '',
      priceRangeValue: [0, 100],
    });
  };

  handleFilterByPriceList(value: {id: number, option: string}) {
    // clear the filter when change price list
    this.handleClearSelectedFilter();

    // Check any filter applied or not to show active
    this.isFilterApplied = this.handleCheckAppliedFilter();

    // Set selected product price list to storage
    localStorage.setItem("selected_product_pricelist", JSON.stringify(value));

    this.setState({selectedPriceList: value.id, isLoading: true});

    let apiUrl = `${configJSON.getNewProductListApiEndPoint}?token=${this.authToken}&page_no=${this.state.page + 1}&per_page=100&price_list_id=${value.id}`;
    const getAccount = apiCall({
      httpBody: {},
      header: {},
      url: apiUrl,
      httpMethod: configJSON.getProductListApiMethod,
    });

    this.getProductListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleEditProductDetails(getProductId: any) {
    localStorage.setItem("selectedPricelistId", this.state.selectedPriceList)
    this.props.navigation.navigate("CfEditProduct", { productId: getProductId })
  }

  handleProductDeactivationModalOpen() {
    this.setState({
      openAction: null,
      confirmDeactivationModalOpen: true
    })
  }

  handleProductDeactivationModalClose() {
    this.setState({confirmDeactivationModalOpen: false})
  }

  handleConfirmProductDeactivateModalOpen() {
    const { selectedPriceList, getProductDataForDeactivate} = this.state;

    const deactivateBody = {
      price_list_id: selectedPriceList
    }

    let apiUrl = 
      `${configJSON.getProductListApiEndPoint}/${getProductDataForDeactivate.id}/deactivate`;

    let message = makeApiMessage({
      url: apiUrl,
      method: configJSON.editProductListApiMethod,
      body: JSON.stringify(deactivateBody)
    });
    this.deactivateProductApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleConfirmProductActivateModalOpen() {
    this.setState({openAction: null})
   
    const { selectedPriceList, getProductDataForDeactivate} = this.state;

    const deactivateBody = {
      price_list_id: selectedPriceList
    }

    let apiUrl = 
      `${configJSON.getProductListApiEndPoint}/${getProductDataForDeactivate.id}/activate`;

    let message = makeApiMessage({
      method: configJSON.editProductListApiMethod,
      url: apiUrl,
      body: JSON.stringify(deactivateBody)
    });


    this.activateProductApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }
  
  handleConfirmProductDeactivateModalClose() {
    this.setState({productDeactivatedModalOpen: true})
  }

  handleGoList() {
    this.setState({productDeactivatedModalOpen: false});
    this.handleApplyFilter();
  }

  handleFilterChange(fitlerValue: string | number, mainFilterNameValue: string | number) {
    this.setState({ selectedFilter: fitlerValue, mainFilterName: mainFilterNameValue, selectedFilterDrag: fitlerValue })
  }

  checkObjectTrueFalse(object: {id: number, option: string ,checked: boolean}) {
    if (object.checked === true) {
      object.checked = false
      return object
    }
    else {
      object.checked = true
      return object
    }
  }

  matchObject(object: {id: number, option: string ,checked: boolean}, subFitlerValue: {id: number, option: string ,checked: boolean}) {
    if (object.id === subFitlerValue.id) {
      this.checkObjectTrueFalse(object);
    }
  }

  handleFilterPopupClose(closeValue: boolean) {
    this.setState({
      openSortingFilterAction: null,
      page: 0,
    })
    if (closeValue === true) {
      // clear the filter
      this.handleClearSelectedFilter();

      let newArray = this.state.dropdownOptions?.section?.map((item: any) => {
        item.checked = false
        return item
      })
      this.setState(() => ({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          section: newArray,
        }
      }))
      this.setState({
        nameFilter: "",
        priceFilter: {
          order: "",
          range: [0, 100]
        },
        sectionFilter: [],
        productTypeFilter: '',
        preferenceFilter: {},
        selectedFilter: 0,
        selectedFilterDrag: '',
        priceRangeValue: [0, 100],
      }, () => {
        this.getPriceList()
        this.handleApplyFilter()
      })
    }
  }

  handleClearFitler() {
    const { selectedFilter } = this.state;
    
    if (selectedFilter == 0) {
      this.setState({ nameFilter: "" });
    } else if (selectedFilter == 1) {
      this.setState({
        priceFilter: {
          order: "",
          range: [0, 100],
        },
      });
    } else if (selectedFilter == 2) {
      let newArray = this.state.dropdownOptions?.section?.map(
        (item: { id: number; option: string; checked: boolean }) => {
          item.checked = false;
          return item;
        }
      );
      this.setState(() => ({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          section: newArray,
        },
        sectionFilter: [],
      }));
    } else if (selectedFilter == 3) {
      this.setState({ productTypeFilter: "" });
    } else if (selectedFilter == 4) {
      this.setState({ preferenceFilter: {} });
    }
  }

  handleApplyFilterChange = () => {
    // set applied filter to local storage
    const {
      nameFilter,
      priceFilter,
      sectionFilter,
      productTypeFilter,
      preferenceFilter,
    } = this.state;

    let storageFilter = {
      nameFilter: nameFilter,
      priceFilter: priceFilter,
      sectionFilter: sectionFilter,
      productTypeFilter: productTypeFilter,
      preferenceFilter: preferenceFilter,
    };
    localStorage.setItem("storageFilter", JSON.stringify(storageFilter));

    this.setState({
      page: 0
    },
    () => this.handleApplyFilter()
    )
  }

  handleCheckAppliedFilter = () => {
    const isAppliedFilter = localStorage.getItem("storageFilter");
    let storageFilter = {
      nameFilter: "",
      priceFilter: {
        order: "",
        range: [0, 100]
      },
      sectionFilter: [],
      productTypeFilter: '',
      preferenceFilter: {},
    };
    if (isAppliedFilter) {
      storageFilter = JSON.parse(isAppliedFilter);
    }

    // price range filter
    const defaultPriceRange = JSON.stringify([0, 100]);
    const havePriceRangeFilter =
      defaultPriceRange !== JSON.stringify(storageFilter?.priceFilter?.range);

    // section filter length
    const sectionFilter = storageFilter?.sectionFilter.length > 0;

    // preferences filter
    const preference = Object.keys(storageFilter?.preferenceFilter);
    const preferenceFilter = preference?.length !== 0;

    // return true if any filter applied
    if (
      storageFilter?.nameFilter ||
      storageFilter?.productTypeFilter ||
      sectionFilter ||
      havePriceRangeFilter ||
      storageFilter?.priceFilter?.order ||
      preferenceFilter
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleApplyFilter = () => {
    this.setState({ isLoading: true });

    let headers = {
      "Content-type": "application/json", token: this.authToken
    }

    // Check any filter applied or not to show active
    this.isFilterApplied = this.handleCheckAppliedFilter();

    let apiUrl;

    let name = this.state.nameFilter;
    let priceOrder = this.state.priceFilter?.order;
    let defaultPriceRange = JSON.stringify([0, 100]);
    let priceRange = JSON.stringify(this.state.priceFilter?.range);
    let section = this.state.sectionFilter;
    let productType = this.state.productTypeFilter;
    let preference = Object.keys(this.state.preferenceFilter);

    if (this.state.priceFilter?.order) {
      this.setState(() => ({
        sortingData: {
          ...this.state.sortingData,
          price_order: this.state.priceFilter?.order
        }
      }))
    }

    apiUrl = configJSON.getNewProductListApiEndPoint +
      (`?page_no=${this.state.page + 1}&per_page=100&price_list_id=${this.state.selectedPriceList}&for_pos=true`) +
      (name ? `&filter_by[name]=${this.state.nameFilter}` : '') +
      (priceOrder ? `&filter_by[price_order]=${this.state.priceFilter?.order}` : '') +
      (defaultPriceRange !== priceRange ? `&filter_by[start_price]=${this.state.priceFilter?.range[0]}&filter_by[end_price]=${this.state.priceFilter?.range[1]}` : '') +
      (section?.length !== 0 ? `&filter_by[section_ids][]=${this.state.sectionFilter}` : '') +
      (productType ? `&filter_by[product_type]=${this.state.productTypeFilter}` : '') +
      (preference?.length !== 0 ? `&filter_by[preference]=${this.state.preferenceFilter.option}` : '') +
      (this.state.query ? `${this.state.query}` : '') +
      (this.state.productQuery ? `&filter_by[query]=${this.state.productQuery}` : '');

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.getProductListApiMethod,
    });

    this.filterProductsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getSectionList() {
    let apiUrl = `${configJSON.getSectionListApiEndPoint}?token=${this.authToken}&dropdown=true`;

    const getAccount = apiCall({
      header: {},
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.getSectionListApiMethod,
    });

    this.getSectionListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getPreferenceList() {
    let apiUrl = `${configJSON.getPreferenceListApiEndPoint}?dropdown=true`;
    let headers = { token: this.authToken }

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.getPreferenceListApiMethod,
    });

    this.getPreferenceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleInputChangeDebounced = () => {
    this.handleApplyFilter()
  }

  onChangeValue = (value: string) => {
    this.setState({
      page: 0,
      productQuery: value
    },()=>this.handleApplyFilter())
  }

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.handleApplyFilter());
  };

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  setName = (value: string) => {
    this.setState({ nameFilter: value, selectedFilterDrag: 0 });

    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let apiUrl;
    apiUrl = configJSON.productSuggesstionApiEndPoint + `?product_name=${value}`

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.productSuggesstionApiMethod,
    });

    this.productSuggessionApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
 
  setPrice = (orderType:any, value: any) => {
    this.setState(() => ({
      priceFilter: {
        ...this.state.priceFilter,
        [orderType]: value
      }
    }))
  }

  setSection = (value: {id: number, option: string, checked: boolean}) => {
    let newArray: [];
    let selectedSection: {id: number, option: string, checked: boolean}[] = [];
    if(this.state.dropdownOptions?.section.some((object:{ id: number, option: string, checked: boolean }) => object.id === value.id)) {
      newArray = this.state.dropdownOptions?.section?.map((objct:{ id: number, option: string, checked: boolean }) => {
        this.matchObject(objct, value)
        return objct
      });
      this.setState(() => ({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          section: newArray
        }
      }))
      newArray.map((item:any) => {
        if(item.checked === true) {
          return selectedSection.push(item.id)
        }
      })
    }
    this.setState({sectionFilter: selectedSection})
  }

  setProductType = (value: string) => {
    this.setState({productTypeFilter: value})
  }

  setPreference = (value: string | number) => {
    this.setState({preferenceFilter: value})
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.query !== this.state.query || prevState.productQuery !== this.state.productQuery 
      || prevState.selectedFilterDrag !== this.state.selectedFilterDrag
       
        ) {
      this.handleDeActivateDragable()
    }
  }

  handleDeActivateDragable = () => {
    if (this.state.query === "" && this.state.productQuery === "" && this.state.selectedFilterDrag === ""
    ) {
      this.setState({ isDragableDisabled: false })
    } else {
      this.setState({ isDragableDisabled: true })
    }
  }

  hoverToGetRowId = (getRowId: string | number) => {
    this.setState({ sectionPopupId: getRowId })
  }

  removeRowId = (getRemoveId: string | number) => {
    this.setState({ sectionPopupId: getRemoveId })
  }

  handleSectionPopupOpen = (checkid: string | number) => {
    if(this.state.sectionPopupId === checkid) {
      this.setState({sectionPopup: true})
    }
  }

  hoverToGetServiceRowId = (getRowId: string | number) => {
    this.setState({ servicePopupId: getRowId })
  }

  removeServiceRowId = (getRemoveId: string | number) => {
    this.setState({ servicePopupId: getRemoveId })
  }

  handleServicePopupOpen = (checkid: string | number) => {
    if(this.state.servicePopupId === checkid) {
      this.setState({servicePopup: true})
    }
  }

  handleServicePopupClose = () => {
    this.setState({servicePopup: false})
  }

  handleSectionPopupClose = () => {
    this.setState({sectionPopup: false})
  }

  closeAddPriceListModal = () => {
    this.setState({
      openAddPriceListModal: false,
    }, () => this.getPriceList())
  };

  handleOpenFilter = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ openSortingFilterAction: event?.currentTarget }, () => {
      if(this.state.dropdownOptions.section.length === 0) this.getSectionList();
      if(this.state.dropdownOptions.preference.length === 0) this.getPreferenceList();
    });
  }

  handleScrollPricelistDropdown = (event: React.SyntheticEvent) => {
    const { priceListDropdownEmptyFlag, autoCopmpleteValue } = this.state
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      !priceListDropdownEmptyFlag &&
      listboxNode.scrollHeight - position <= 1.3 &&
      autoCopmpleteValue.length < 2
    ) {
      this.setState({
        pricelistDropPage: this.state.pricelistDropPage + 1
      },
        () => this.getPriceList()
      )
    }
  }

  handleAutoCompleteChange = (getSearchValue: string) => {
    if(getSearchValue.length < 1 || getSearchValue.length > 2) {
      this.setState({
        pricelistDropPage: 1,
        autoCopmpleteValue: getSearchValue,
      }, () => this.getPriceList());
    }
  }

  handleCloseImpExpPopover = () => {
    this.setState({ openProductImpExtPopover: null})
  }

  handleExportTemplate = () => {
    const requestMessage = makeApiMessage({
      url: configJSON.ApiUrls.exportExcelTemplateApiUrl,
      method: configJSON.httpGetType,
    });

    this.exportTemplateFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  handleOpenImportModal = () => {
    this.setState({ openProductImpExtPopover: null})

    let message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupMeassage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    )
    this.send(message)
  }

  handleExportCsvFile = () => {
    this.setState({ openProductImpExtPopover: null });

    const requestMessage = makeApiMessage({
      url: configJSON.ApiUrls.exportExcelApiUrl + '?price_list_id=' + this.state.selectedPriceList,
      method: configJSON.httpGetType,
    });

    this.exportFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  handleImportExportModalClose = () => {
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupClose))
    message.addData('ParentpageRoute', 'ProductList')
    runEngine.sendMessage(message.id, message)
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ setLoaded: 0 })
    const file = event.target.files;

    let randomNumber = randomNumberGenerator(1, 9);
    const delay = randomNumber * 25;
    const uploadInterval = setInterval(() => {
      this.setState({
        setLoaded: updateLoadingTime(this.state.setLoaded)
      }, () => {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupFileMessage))
        message.addData('returnValue', { setLoaded: this.state.setLoaded, file: file && file[0] })
        runEngine.sendMessage(message.id, message)
      })

    }, delay);

    // for adding 20 percent every time
    function updateLoadingTime(prevLoaded: number) {
      if (prevLoaded >= 100) {
        clearInterval(uploadInterval);
        return 100;
      }
      return prevLoaded + 20
    }

    const checkFile = file && file[0];
    this.setState({ uploadedFile: checkFile as File})
  }

  handleImportFile = () => {
    this.setState({importButtonLoading: true})
    const formData = new FormData();
    formData.append("data[file]", this.state.uploadedFile as File);

    const requestMessage = makeApiMessage({
      url: configJSON.ApiUrls.importExcelApiUrl,
      body: formData,
      method: "POST",
      isHeader: true,
    });

    this.importFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSnackbarClose() {
    this.setState({ errorSnackbarOpen: false })
  }

  handleBackToListPage = () => {
    navigateTo({ props: this.props, screenName: "ProductList" })
    this.handlePageChange(1);
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.productManagement;
    const apiKeyPriceList = customPermissionApiKey.priceList;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    const valuePriceList = checkForNewPermissonStatus(apiKeyPriceList, userData as Array<PermissionGroupArray>);
    
    const apiKeyImportExport = customPermissionApiKey.dataImportExportPermission;
    const valueImportExportPermission = checkForImportExportPermissionStatus(apiKeyImportExport, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: {
        ...value,
        exportPermission: valueImportExportPermission.exportPermission
      },
      priceListPermissionStatus:valuePriceList
    })
  };

  generateStrSARMeasue = (service: any,measurementTypes: any) => {
    const strings = []
    let str = "";
    if (measurementTypes.includes("SQM")) {
      str += `SAR ${parseFloat(service.sqm_price)} - ${parseFloat(service.sqm)} SQM, `;
    }
    if (measurementTypes.includes("Weight")) {
      str += `SAR ${parseFloat(service.weight_price)} - ${parseFloat(service.weight)} Kg, `;
    }
    if (measurementTypes.includes("Quantity")) {
      str += `SAR ${parseFloat(service.price)} - 1 QTY, `;
    }
    str = str.slice(0, -2);
    strings.push(str);
    return strings;
  }

  // Customizable Area End
}