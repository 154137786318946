import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export const configJSON = require("./config");
import { IFilter } from "../../../components/src/FilterPopover";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { subscribeMessage } from "./util";
import { getFiterList, makeApiMessage } from "../../../components/src/common";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionStatus,customPermissionApiKey,checkForNewPermissonStatus, checkIsFilterApplied } from "../../utilities/src/CustomBlockHelpers"

interface TaxListItem {
  id: number;
  type: string;
  attributes: {
    id: number;
    tax_name: string;
    tax_rate: string;
    updated_at: string;
    created_at: string;
  };
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  token: string;
  // Customizable Area Start
  filterAnchor: any;
  openAction: any;
  page: number;
  pageSize: number;
  searchTextTax: string;
  query: string;
  suggestionFieldTitle: string;
  sortingData: ISortingData;
  taxItem: TaxListItem;
  taxLists: TaxListItem[];
  filters: IFilter[];
  meta: {
    next_page: number;
    pervious_page: number;
    total_pages: number;
    total_count: number;
    current_page: number;
  };
  permissionStatus: PermissionStatus;
  isLoading: boolean;
  isAppliedFilter: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TaxListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaxListCallId: string = "";
  filterSuggestionApiCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = subscribeMessage();

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.state = {
      token: "",
      filterAnchor: false,
      isLoading: false,
      page: 0,
      pageSize: 10,
      searchTextTax: "",
      suggestionFieldTitle: "",
      query: "",
      sortingData: {
        tax_name: "",
        tax_rate: "",
      },
      openAction: null,
      taxItem: {
        id: 104,
        type: "",
        attributes: {
          id: 104,
          tax_name: "",
          tax_rate: "",
          created_at: "",
          updated_at: "",
        },
      },
      taxLists: [],
      meta: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1,
      },
      filters: [
        {
          title: "Tax Name",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Tax Percentage",
          type: "select",
          options: [
            {
              label: "Higher to Lower",
              value: "desc",
            },
            {
              label: "Lower to Higher",
              value: "asc",
            },
          ],
          value: "",
        },
      ],
      isAppliedFilter: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    // Customizable Area Start
    switch (apiRequestCallId) {
      case this.getTaxListCallId:
        this.handleTaxListResponse(responseJson);
        break;
      case this.filterSuggestionApiCallId:
        this.handleFilterSuggResp(responseJson);
        break;
    }
    this.receiveSearchTextData(message)
    this.receiveDataFromLayout(message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleStorageFilter();
    // Customizable Area End
  }

  // Customizable Area Start
  receiveSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.handleSearchTax(recievedData.searchText)
        }
    }
  }
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  getTaxList = () => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const nameFilter = this.state.filters.find(
      (item: any) => item.title === "Tax Name"
    )?.value;
    const taxRateFilter = this.state.filters.find(
      (item: any) => item.title === "Tax Percentage"
    )?.value;
    const searchTextTax = this.state.searchTextTax;

    const taxListApiEndPoint =
      configJSON.taxListApi +
      "?page_no=" +
      (this.state.page + 1) +
      "&per_page=" +
      10 +
      this.state.query +
      (nameFilter ? `&filter_by[tax_name]=${nameFilter}` : "") +
      (taxRateFilter ? `&filter_by[percentage]=${taxRateFilter}` : "") +
      (searchTextTax ? `&filter_by[query]=${searchTextTax}` : "");

    const getTaxDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaxListCallId = getTaxDataMsg.messageId;

    getTaxDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      taxListApiEndPoint
    );
    getTaxDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getTaxDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getTaxDataMsg.id, getTaxDataMsg);
  };

  handleSearchTax = (value: string) => {
    this.setState(
      {
        page: 0,
        searchTextTax: value,
      },
      () => this.getTaxList()
    );
  };

  handleFilterChange = (filters: IFilter[]) => {
    // set filter applied value set
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("tax_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("tax_filter_value");
    }

    this.setState(
      {
        filters,
        isAppliedFilter: checkIsFilterApplied(filters),
        page: 0,
      },
      () => this.getTaxList()
    );
  };

  handlepopver = (event: any, item: TaxListItem) => {
    this.setState({
      openAction: event.currentTarget,
      taxItem: item,
    });
  };

  handleCloseActionList = () => {
    this.setState({ openAction: null });
  };

  handlePageChange = (page: number) => {
    this.setState({ page }, () => this.getTaxList());
  };

  handleViewTaxDetails = () => {
    this.props.navigation.navigate("ViewTax", {
      id: this.state.taxItem.id,
    });
    this.handleCloseActionList();
  };

  handleEditTaxDetails = () => {
    this.props.navigation.navigate("AddEditTax", {
      id: this.state.taxItem.id,
    });
    this.setState({ openAction: null });
    this.handleCloseActionList();
  };

  openTaxPopver = (event: any) => {
    this.setState({ filterAnchor: event.currentTarget });
  };

  closeTaxPopver = () => {
    this.setState({ filterAnchor: undefined });
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getTaxList());
  };

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleTaxListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        meta: responseJson.meta,
        taxLists: responseJson.data,
        isLoading: false,
      });
    }
  };

  handleFilterWithSorting = () => {
    this.state.filters.forEach((item) => {
      if (item.title === "Tax Percentage") {
        item.value = "";
      }
    });
  };

  handleTaxAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      return item;
    });

    let requestMessage = makeApiMessage({
      url: configJSON.taxNameFilterEndPoint + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFilterSuggResp = (responseJson: any) => {
    if (responseJson) {
      const updatedFilters = getFiterList(
        responseJson,
        this.state.filters,
        this.state.suggestionFieldTitle
      );
      this.setState({ filters: updatedFilters });
    }
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.taxcalculator;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleStorageFilter = () => {
    const applied_store_group_filter = localStorage.getItem(
      "tax_filter_value"
    );
    if (applied_store_group_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_store_group_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_store_group_filter)
          ),
        },
        () => this.getTaxList()
      );
    } else {
      this.getTaxList();
    }
  };
  // Customizable Area End
}
