// Customizable Area Start
import { CSSProperties } from "@material-ui/styles";


export const style: Record<string, CSSProperties> = {
  contentWrapper: {
    marginRight: 32,
    marginLeft: 48,
  },
  table: {
    minWidth: 700,
  },
  mainWrapper: {
    marginTop: "32px",
    fontFamily: "Montserrat",
  },
  pageHeader: {},
  headerWrapper: {
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: "space-between",
    marginBottom: 32,
    marginTop: 32,
    alignItems: "center",
  },
  addButton: {
    textTransform: "unset",
    fontFamily: "Montserrat",
    background: "#204B9C",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    width: "160px",
    borderRadius: "8px",
    "&:hover": {
      background: "#204B9C",
      color: "#FFFFFF",
    }
  },
  scrollable: {
    overflow: 'visible'
  },
  PriceModalWrapper: {
    padding: "24px 30px 24px 24px",
    maxHeight: "calc(100vh - 126px)", 
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ProductName: {
    color: 'rgba(26, 26, 26, 1)',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: "22px",
    fontFamily: "Montserrat",
    marginLeft: "12px",
    marginRight: 15,
    whiteSpace: 'nowrap'
  },
  ProductHeading: {
    color: '#ffffff',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: "22px",
    fontFamily: "Montserrat",
  },
  headerButtonPartAccount: {
    display: "flex",
  },
  popoverButton: {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  Accordion: {
    width: "100%",
    margin: "0 !important",
    borderRadius: "12px"
  },
  boxShadowNone: {
    boxShadow: "none"
  },
  AccordionSummary: {
    width: "100%",
    borderRadius: "12px 12px 0 0",
    height: "60px",
    background: "rgba(32, 75, 156, 1)",
  },
  CloseAccordionSummary: {
    borderRadius: "12px !important",
  },
  SummaryInner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  CollapseIcon: {
    transform: "rotate(-180deg)"
  },
  RotateCollapseIcon: {
    transform: "rotate(0deg)"
  },
  CancelBtn: {
    background: "#F1F5F9",
    color: "#64748B",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    padding: "16px 0",
    width: "186px",
    marginTop: "32px",
    marginRight: 16,
    borderRadius: "8px",
    textTransform: "none",
    "&:hover" : {
      background: "#F1F5F9",
      color: "#64748B",
    },
    "@media only screen and (max-width: 1024px)": {
      height:'44px',
    }
  },
  containedButton: {
    background: "#204B9C",
    color: "#FFFFFF !important",
    "&.Mui-disabled":{
      backgroundColor: "rgba(32, 75, 156, 0.5) !important",
      color: "#FFFFFF",
      opacity: 0.5
    },
    "&:hover": {
      background: "#204B9C",
      color: "#FFFFFF",
    }
  },
  AccordionDetails: {
    padding: "8px 2px 8px 8px",
  },
  ProductItem: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    border: "1px solid rgba(236, 236, 236, 1)",
    borderRadius: "12px",
    width: "100%",
    gap: 8,
    flexWrap: "wrap"
  },
  ProductImg: {
    width: "32px",
    height: "32px",
    border: "1px solid rgba(236, 236, 236, 1)",
    borderRadius: "8px",
    "& img": {
      width: "100%",
      height: "100%",
      objectfitCover: "cover"
    }
  },
  popoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  TagContainer: {
    display: "flex",
    gap: "4px",
    flexWrap: "wrap",
  },
  filterButtonAccount: {
    marginRight: "24px",
    cursor: "pointer",
    background: "#FFFFFF",
    height: "44px",
    color: "#4d4d4d",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #204B9C",
    padding: '10px'
  },
  viewPriceHeader: {
    color: '#1a1a1a',
    fontWeight: 600,
    marginBottom: '32px',
    fontSize: '24px'
  },
  tableContainer: {
    "@media (max-width: 1000px)": {
      width: 'calc(100% - 74px)'
    }
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    width: '60%',
    "@media (max-width: 1200px)": {
      width: '80%'
    }
  },
  addProductButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  productPrices: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14
  },
  productSeparator: {
    margin: '0 4px'
  },
  productList: {
    display: 'flex',
    alignItems: 'center',
  },
  productPricesInput: {
    width: '60px',
    padding: 0,
    "& .MuiOutlinedInput-input": {
      padding: 10
    }
  },
  newProductDropdown: {
    height: 56,
    width: 250
  },
  delete_icon: {
    height: '56px',
    minWidth: '56px',
    backgroundColor: '#f1f4f9',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  serviceIcon: {
    width: 18,
    height: 18,
    marginRight: 4
  },
  mt_32: {
    marginTop: 32
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
},
}

// Customizable Area End