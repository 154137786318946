import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall, handleLogout } from "../../../components/src/common";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  productDetails: any;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfViewProductDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductDetailsApiCallId:string = "";
  authToken: string = "";

  async componentDidMount() {
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.getProductDetails();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      productDetails: [],
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.receiveDataFromLayout(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getProductDetailsApiCallId) {
        if (this.checkGetResponse(responseJson)) {
          this.setState({productDetails: responseJson?.data?.attributes, isLoading: false});
        } else {
          this.setState({isLoading: false})
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        this.handleUserChange(recievedData.userContext)
      }
    }
  }

  checkGetResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson?.errors);
      return false
    }
  }

  handleBackToProductList = () => {
    this.props.navigation.navigate("ProductList")
  }

  getProductDetails() {
    this.setState({isLoading: true})
    let getProductEditId: any = this.props.navigation.getParam('productId');
    let selected_Pricelist = localStorage.getItem("selectedPricelistId");

    let apiUrl = `${configJSON.getProductListApiEndPoint}/${getProductEditId}?price_list_id=${selected_Pricelist}&token=${this.authToken}`;
    const getAccount = apiCall({
      httpBody: {},
      header: {},
      url: apiUrl,
      httpMethod: configJSON.getProductListApiMethod,
    });

    this.getProductDetailsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.productManagement;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleNavigateEdit = () =>{
    let getProductEditId: string = this.props.navigation.getParam("productId");
    this.props.navigation.navigate("CfEditProduct", {
      productId: getProductEditId,
    });
  }

  // Customizable Area End
}
