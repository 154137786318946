import React, { useCallback, useState } from "react";
import { StyledCheckBox } from "./CustomTheme.web";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  AutocompleteRenderGroupParams,
} from "@material-ui/lab/Autocomplete";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withStyles } from "@material-ui/core/styles";
import { IProductItems } from "../../blocks/CfPriceList/src/PriceListViewController.web";
import {
  Collapse,
  List,
  ListItem,
  styled,
  Typography,
  Divider,
  ListSubheader,
  Box,
  TextField,
  TextFieldProps,
  Popper,
  PaperProps,
  PopperProps,
  Paper,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { isEmpty } from "lodash";
const autoSeletStyle = {
  radioIcon: {
    display: "inline-block",
    borderRadius: "51%",
    width: 20,
    height: 20,
    border: "1px solid #64748C",
  },
  radioCheckedIcon: {
    display: "inline-block",
    width: 20,
    borderRadius: "51%",
    height: 20,
    border: "6px solid #204B9D",
    color: "#204b9c",
  },
  inputField: {
    paddingTop: "0px",
  },
};
interface Props {
  dataTestId: string;
  value: { id: string | number; option: string }[];
  selectedOptionForList?: {
    id: string | number;
    option: string;
    type: string;
  }[];
  selectedGroupOptionForList?: {
    id: string | number;
    option: string;
    type: string;
  }[];
  optionList: Array<IProductItems | { id: string | number; option: string }>;
  placeHolderText: string;
  debouncedFunction: Function;
  handleScroll?: Function;
  handleSelectOptions: Function;
  emptyDataMessage: string;
  isOnBlurFunction?: Function;
  id?: string;
  name?: string;
  style?: Object;
  isError?: boolean;
  customPaper?: boolean;
  customPopper?: boolean;
  changeInputOnClear?: boolean;
  listBoxHeight?: string;
  isRadioButtonShow?: boolean;
  isSelectAll?: boolean;
  optionStoreList: Array<
    IProductItems | { id: string | number; option: string; type: string }
  >;
  optionGroupList: Array<
    | IProductItems
    | { id: string | number; option: string; type: string; storecount?: number }
  >;
  currentGroupId: string;
  selectedGroupName?: string;
  searchStoreValue: string;
}

const useStyles = makeStyles(() => ({
  newProductDropdown: {
    minHeight: 56,
    minWidth: 300,
    maxWidth: "100%",
  },
  menuItems: {
    display: "flex",
    alignItems: "center",
    gap: "7px",
    width: "100%",
    padding: "15px",
    paddingTop: "0",
  },
  mainComp: {
    maxHeight: 400,
    width: "250px",
  },
}));

const PageConatinerStoreGroupMultiSelect = ({
  dataTestId = "searchableMultiselect",
  value,
  selectedOptionForList,
  selectedGroupOptionForList,
  optionList,
  placeHolderText = "",
  debouncedFunction,
  handleScroll,
  handleSelectOptions,
  emptyDataMessage = "No Data Found",
  isOnBlurFunction,
  id = "productAutoSelect",
  name = "productAutoSelect",
  style = {},
  isError,
  customPaper,
  customPopper,
  changeInputOnClear,
  listBoxHeight = "400",
  isRadioButtonShow,
  isSelectAll,
  optionStoreList,
  optionGroupList,
  currentGroupId,
  searchStoreValue,
  selectedGroupName,
}: Props) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = React.useState("");

  const flattenedOptions = optionGroupList.reduce((acc: any, group: any) => {
    acc.push({ ...group, isGroup: true });
    group.stores.forEach((store: any) => {
      acc.push({ ...store, group: group.option, isGroup: false });
    });
    return acc;
  }, []);

  const filteredOptions = flattenedOptions.filter((option: any) => {
    if (option.isGroup) {
      return (
        selectedGroupName === option.option ||
        option.option.toLowerCase().includes(searchStoreValue.toLowerCase())
      );
    }
    if (selectedGroupName) {
      return (
        option.group === selectedGroupName &&
        option.option.toLowerCase().includes(searchStoreValue.toLowerCase())
      );
    }
    return false;
  });

  return (
    <SimpleCustomAutocomplete
      multiple
      data-test-id={dataTestId}
      id='categories-subitems-autocomplete'
      options={filteredOptions}
      disableCloseOnSelect
      disableClearable={true}
      getOptionLabel={(option: any) =>
        option.isGroup ? option.option : `${option.option} (Store)`
      }
      renderOption={(option: any, props: any) => {
        if (option.isGroup) {
          return (
            <li {...props}>
              <div
                onClick={() =>
                  handleSelectOptions(selectedGroupOptionForList, option)
                }
                style={{cursor:"pointer", display: "flex", alignItems: "center" }}
                data-test-id={`products-${option.id}`}
              >
                <Radio
                  checked={selectedGroupOptionForList
                    ?.map((item) => item.id)
                    .includes(option.id)}
                  value={option.option}
                  style={{ marginRight: 2 }}
                />
                <Typography
                  style={
                    selectedGroupOptionForList
                      ?.map((item) => item.id)
                      .includes(option.id)
                      ? {
                          color: "rgb(32, 75, 156)",
                          fontSize: "18px",
                          fontWeight: 500,
                        }
                      : { fontSize: "18px", fontWeight: 500 }
                  }
                >
                  {option.option} ({option.storecount})
                </Typography>
              </div>
            </li>
          );
        } else {
          return (
            <li {...props}>
              <Box
                component={"li"}
                {...props}
                id={`products-${option.id}`}
                data-test-id={`productstore-${option.id}`}
                className={classes.menuItems}
                onClick={(event) => {
                  handleSelectOptions(selectedOptionForList, option);
                }}
              >
                <StyledCheckBox
                  checked={selectedOptionForList
                    ?.map((item) => item.id)
                    .includes(option.id)}
                />
                &nbsp;<span>{option.option}</span>
              </Box>
            </li>
          );
        }
      }}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
        const sortedValues = value.sort(
          (
            a: { id: string | number; option: string; type?: string },
            b: { id: string | number; option: string; type?: string }
          ) => {
            if (a.type === "group" && b.type !== "group") return -1;
            if (a.type !== "group" && b.type === "group") return 1;
            return 0;
          }
        );

        const displayValues =
          sortedValues.length > 2
            ? `${sortedValues
                .slice(0, 2)
                .map((item) => item.option)
                .join(", ")}...`
            : sortedValues.map((item) => item.option).join(", ");
        return (
          <TextFieldComp
            {...params}
            style={{ paddingTop: "0px" }}
            data-test-id='autoCompleteInput'
            fullWidth={true}
            variant='outlined'
            className={`${isError && "isError"}`}
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <span
                  style={{
                    color: "#4d4d4d",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                  }}
                >
                  {displayValues ? (
                    displayValues
                  ) : (
                    <span style={{ fontSize: "14px" }}>No Group Selected</span>
                  )}
                </span>
              ),
            }}
          />
        );
      }}
      ListboxProps={{
        onScroll: handleScroll,
        style: {
          maxHeight: listBoxHeight,
        },
      }}
      PaperComponent={useCallback((props: PaperProps) => {
        return <Paper {...props} className={classes.mainComp} />;
      }, [])}
      PopperComponent={useCallback((props: PopperProps) => {
        return <Popper {...props} placement='bottom' />;
      }, [])}
    />
  );
};

const SimpleCustomAutocomplete: any = withStyles({
  root: {
    width: "230px",
    "@media only screen and (max-width: 1024px)": {
      width: "200px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      minHeight: "56px",
      flexWrap: "nowrap",
      "@media only screen and (max-width: 1024px)": {
        maxHeight: "44px",
        alignContent: "center",
        fontSize: "12px",
        height: "auto",
        padding: "9.5px 4px",
        minHeight: "40px",
        paddingLeft: "20px !important",
      },
    },
    "& .MuiChip-deleteIcon": {
      display: "none",
    },
    "& .MuiChip-root": {
      color: "#204B9C",
      backgroundColor: "#204B9C1A",
    },
  },
  option: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiAutocomplete-listbox": {
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "2px !important",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "20px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
    },
  },
})(Autocomplete);

const TextFieldComp = withStyles({
  root: {
    "&.isError .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "@media only screen and (max-width: 1024px)": {
      "& .MuiInputBase-input": {
        fontSize: "12px",
        padding: "6.5px 14px !important",
      },
    },
  },
})(TextField);

const LabelOptions = styled("p")({
  fontWight: 500,
  fontSize: "16px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "14px",
  },
});

const TitleTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: "16px",
  color: "#4D4D4D",
  marginTop: "20px",
});

const StyleListSubheader = styled(ListSubheader)({
  fontWeight: 600,
  fontSize: "16px",
  color: "#4D4D4D",
  marginTop: "20px",
});

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export default PageConatinerStoreGroupMultiSelect;
