import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { ChangeEvent } from "react";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { apiCall, handleLogout, makeApiMessage } from "../../../components/src/common";
import { CustomEnums, checkForImportExportPermissionStatus, getCustomEnumName, navigateTo, randomNumberGenerator } from "../../utilities/src/CustomBlockHelpers";
import ImportExportWebAdapter from "../../adapters/src/ImportExportWebAdapter";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

interface SubmitFormType {
  primaryName: string,
  secondaryName: string,
  selectedType: string, 
  uploadFileUrl: RequestInfo,
  id: number
}

interface Filter  {key:string, value:string}


export interface AllImageIconType {
  id: string,
  type: string,
  attributes: {
    id: number,
    image: string,
    name: string
    second_name: string,
    image_type: string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  token: string;
  // Customizable Area Start
  selectedType: string,
  permissionStatus: PermissionStatus;
  isLoadingPermission: boolean;
  options:string[];
  anchorEl: null | HTMLElement,
  allIcons: AllImageIconType[],
  allImages: AllImageIconType[],
  errorMessage: string,
  loading: boolean,
  selectedItem: string,
  selectedFile: string | null | ArrayBuffer,
  imageLabel: string,
  snackbar: {
    open: boolean,
    severity:"error" | "success" | "info" | "warning" | undefined,
    message:string
},
selectedTabs:any,
searchText: string,
  isGridView: boolean,
  sortingData: ISortingData;
  sortingDataImage: ISortingData;
  iconSort: string;
  imageSort: string;
  // gridItemsAction: 'Edit' | 'Delete' | ''
filters: {
  name: Filter,
  type: Filter,
},
currentTab:number,
query:string,
filterParams:string;
  deleteDialogOpen: boolean;
  listDeleteItemId: any;
  editItemData: any;
  isEditMode: boolean;
  gridSelectMode: 'Edit' | 'Delete' | '';
  gridSelectItem: 'Image' | 'Icon' | '';
  gridEditItem: any;
  gridDeleteItemIds: number[];
  isCheckedSelectAll: boolean;
  optionsList: { label: string, value: string }[];
  openGalleryAction: EventTarget & HTMLButtonElement | null;
  uploadedFile: File | null;
  setLoaded: number;
  selectedFileName: string;
  pageNumImg: number;
  totalPageImg: number;
  searchGalleryText: string,

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GalleryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getIconsCallId: string = "";
  getImagesCallId: string = "";
  createIconsCallId: string = "";
  debounceTimer: number | null | NodeJS.Timeout = null;
  deleteIconImageApiId: string = '';
  updateGalleryItemApiId: string = '';
  gallerySuggessionApiCallId: string = '';
  importFileApiId: string = '';
  exportFileApiId: string = "";
  exportTemplateFileGalleryApiId: string = "";
  adapter: ImportExportWebAdapter;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage),
      getCustomEnumName(CustomEnums.ImportExportClearFileMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.SearchTextMessage)
      // Customizable Area End
    ];
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.adapter = new ImportExportWebAdapter();

    // Customizable Area End
    this.state = {
       // Customizable Area Start
      gridSelectMode: '',
      options: [],
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      gridSelectItem: '',
      selectedType: '',
      token: '',
      allIcons: [],
      anchorEl: null,
      allImages: [],
      loading: false,
      errorMessage: "",
      selectedItem: 'Select type',
      selectedFile: "",
      imageLabel: 'Image',
      searchGalleryText: "",
      snackbar: {
        open: false,
        severity: 'info',
        message: ''
      },
      selectedTabs: '',
      searchText: '',
      isGridView: false,
      sortingData: {
        name: '',
      },
      sortingDataImage: {
        name: '',
      },
      iconSort: '',
      imageSort: '',
      filters: {
        name: {} as Filter,
        type: {} as Filter,
      },
      currentTab: 0,
      query: "",
      filterParams: "",
      deleteDialogOpen: false,
      listDeleteItemId: '',
      editItemData: undefined,
      isEditMode: false,
      gridEditItem: undefined,
      gridDeleteItemIds: [],
      isCheckedSelectAll: false,
      optionsList: [],
      openGalleryAction: null,
      uploadedFile: null,
      setLoaded: 0,
      selectedFileName: "",
      pageNumImg: 1,
      totalPageImg: 1,
      isLoadingPermission: true
  // Customizable Area End

    };


  
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResForExportFile(from,message)
    this.handleResForExportTemplateFile(from,message)
    this.receiveDataFromLayout(message)
    this.receiveSearchData(message)
    if (from === getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked)) {
      this.handleGalleryImportExportModalClose();
    };

    if (from === getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage)) {
      this.handleGalleryImportFile();
    };
    
    if (from === getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage)) {
      this.handleGalleryFileUpload(message.properties.fileEvent);
    };

    if (from === getCustomEnumName(CustomEnums.ImportExportClearFileMessage)) {
      this.setState({ 
        uploadedFile: message.properties.uploadedFile,
        setLoaded: message.properties.setLoaded 
      });
    };

    if(getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack) === message.id) {
      this.handleBackToListPage();
    }
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if((responseJson?.error || responseJson?.errors || !responseJson) && apiRequestCallId !== this.importFileApiId){
        handleLogout(this.props.navigation, responseJson?.errors)
        this.setState({ 
          loading: false,
          snackbar: {
            message: responseJson?.error || responseJson?.errors?.[0] || 'Something went wrong',
            severity: 'error',
            open:true
          }
        }) 
      } else {
        this.handleResponse(apiRequestCallId, message)
      } 
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAllIcons()
    this.getAllImage()
    if(this.props.navigation?.history.location?.pathname === '/Products-GalleryAddImageIcon'){
      const item = this.props.navigation?.history.location?.state
      if(item){
        this.setState({
        isEditMode: true,
        editItemData: item,
        selectedItem: item?.attributes?.image_type?.[0].toUpperCase() +item?.attributes?.image_type?.slice(1)  || 'Select type',
        imageLabel: item?.attributes?.image_type,
        selectedFileName: item?.attributes?.image_name
      })}
    }
    // Customizable Area End


  }






  // Customizable Area Start

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
            this.handleUserChange(recievedData.userContext)
        }
    }
  }

  receiveSearchData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
      const recievedData = message.getData(
          getName(MessageEnum.SearchMessageText)
      );
      if (recievedData) {
          this.searchByText(recievedData.searchText)
      }
  }
  }
  handleResponse = (apiRequestCallId: string, message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    switch (apiRequestCallId) {
      case this.getIconsCallId:
        this.setState({
          loading: false, 
          allIcons: responseJson.data
        });
        break;

      case this.getImagesCallId:
        this.getImageListRes(responseJson)
        break;

      case this.createIconsCallId:
        if (responseJson !== undefined && responseJson.error === undefined) {
          this.setState({ 
            snackbar: {
                message: `${this.state.imageLabel?.[0].toUpperCase()}${this.state.imageLabel.slice(1)} has been added successfully !!!`,
                severity: 'success',
                open:true
            }
        });
        setTimeout(this.goToGallery,
          2000) 
        }
        break;

        case this.updateGalleryItemApiId:
          if (responseJson !== undefined && responseJson.error === undefined) {
            this.setState({ 
              snackbar: {
                message: `${this.state.imageLabel?.[0].toUpperCase()}${this.state.imageLabel.slice(1)} has been updated successfully !!!`,
                  severity: 'success',
                  open:true
              }
          });
          setTimeout(this.goToGallery
          ,2000) 
          }
          break;

      case this.gallerySuggessionApiCallId:
        if (responseJson !== undefined && responseJson.error === undefined) {
          const list = responseJson?.suggestion?.map((value: string) => ({ label: value, value })) || [];
          this.setState({ optionsList: list });
        }
        break;

      case this.deleteIconImageApiId:
        this.getAllIcons()
        this.getAllImage()
        this.setState({
          deleteDialogOpen: false, 
          listDeleteItemId: '',
          gridSelectItem: '', 
          gridSelectMode: '',
          snackbar: {
            message: `Image/icon has been deleted successfully !!!`,
            severity: 'success',
            open:true
          }
        });
        break;

      case this.importFileApiId:
        if (responseJson) {
          const message = new Message(getCustomEnumName(CustomEnums.ImportExportAPIResponse))
          message.addData('APIresponse', responseJson)
          runEngine.sendMessage(message.id, message);
          this.handleResponseImportCsv(responseJson)
        }
        break;
    }
  }

  getImageListRes = (responseJson:{data:AllImageIconType[],meta:{total_pages:number}}) => {
     const filterData = this.state.filterParams
      const searchText = this.state.searchText
      this.setState({
        loading: false,
        allImages:filterData || searchText ? responseJson.data : Array.from(
          new Map(
            this.state.allImages.concat(responseJson.data)
            .map((image) => [image.id, image])
          ).values()
        ),
        totalPageImg: responseJson.meta.total_pages
      });
  }

  token = localStorage.getItem('token');

  handleResponseImportCsv = (responseJson: { message: string, errors: {[key: string]: string[]}, error: string, status: number }) => {
    if (responseJson?.message) {
      this.setState({
        snackbar: {
          message: responseJson.message,
          severity: 'success',
          open: true
        }
      })
      navigateTo({ props: this.props, screenName: "Gallery" })
      this.getAllIcons()
      this.getAllImage()
    } else if (responseJson?.status == 500) {
      this.setState({
        snackbar: {
          message: responseJson?.error || '',
          severity: 'error',
          open: true
        }
      })
      navigateTo({ props: this.props, screenName: "Gallery" })
    } else if (responseJson?.status == 422) {
      this.setState({
        snackbar: {
          message: responseJson?.errors.toString() || '',
          severity: 'error',
          open: true
        }
      })
      navigateTo({ props: this.props, screenName: "Gallery" })
    } else {
      const message = new Message(getCustomEnumName(CustomEnums.ImportExportErrorPopupData))
      message.addData('APIresponse', responseJson);
      runEngine.sendMessage(message.id, message);
      navigateTo({ 
        props: this.props,
        screenName: "ImportExportErrorModal",
        raiseMessage: message
      })

    }
  }

  handleBackToListPage = () => {
    navigateTo({ props: this.props, screenName: "Gallery" })
  }

  getSelectedTabValue = (value: any) => {
    this.setState({
      filters: {...value},
    });
  };

  goToGallery = () => {
    
      this.props.navigation.navigate("Gallery")
     
  }
  


 buildQueryParam = (key:string, value:string)=> {
    if (!key || !value) return "";
    return `${key}=${value.toLowerCase()}`;
  }




  callFilterApi = ()=>{
    const selectetedGalleryTabs   = this.state.filters;
    const galleryNameFilterQuery = this.buildQueryParam(selectetedGalleryTabs.name.key, selectetedGalleryTabs.name.value)
    const galleryTypeFilterQuery = this.buildQueryParam(selectetedGalleryTabs.type.key, selectetedGalleryTabs.type.value)
    const galleryFilterParams = galleryNameFilterQuery + (galleryNameFilterQuery && galleryTypeFilterQuery ? '&' : '') + galleryTypeFilterQuery

  this.setState({
    filterParams:galleryFilterParams,
    pageNumImg:1,
    }, () => {
      this.getAllIcons()
      this.getAllImage()
  })
  }

  clearFilter = ()=>{
    this.setState({
      loading: true,
      filters:{name:{} as Filter, type:{} as Filter} ,
      filterParams:"",
      }, () => {
        this.getAllIcons()
        this.getAllImage()
    })
  }

  getAllIcons = () => {
    this.setState({
      loading: true
    })

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const apiUrl = configJSON.getAllIconsApi 
      + (this.state.query ? `?${this.state.query}` :  `?filter_by[query]=`)
      + (this.state.filterParams ? `&${this.state.filterParams}` :'')
      + this.state.iconSort

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getIconsCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  getAllImage = () => {
    this.setState({
      loading: true
    })

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    
    const apiUrl = configJSON.getAllImagesApi 
      + (this.state.query ? `?${this.state.query}` :  `?filter_by[query]=`)
      + (this.state.filterParams ? `&${this.state.filterParams}` :'')
      + this.state.imageSort + `&per_page=10&page_no=${this.state.pageNumImg}`

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getImagesCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  FormSchema = Yup.object().shape({
    primaryName: Yup.string().trim()
      .required("Primary name is required"),
    secondaryName: Yup.string(),
    selectedType: Yup.string()
      .required("Atleast Select one item 1")
      .test('val', 'Atleast Select one item 2', val => val !== 'Select type')
      ,
    uploadFileUrl: Yup.mixed()
      .required("Upload Image")
  })

  toggleForm = () => {
    this.props.navigation.navigate("AddImageIcon")
  }

  handleSubmit = async (values: SubmitFormType) => {
    this.setState({
      loading: true
    })

    // Convert base64 to file object
    const url = await fetch(values.uploadFileUrl);
    const blob = await url.blob();
    const file = new File([blob], this.state.selectedFileName || "image.jpg", { type: blob.type });

    const formData = new FormData();
    formData.append("gallery_icon[name]", values.primaryName);
    formData.append("gallery_icon[second_name]", values.secondaryName);
    formData.append("gallery_icon[image_type]", values.selectedType.toLowerCase());
    formData.append("gallery_icon[image_type]", values.selectedType.toLowerCase());
    formData.append("gallery_icon[image]", file);

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createIconsCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createIconsApi
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  updateGalleryItem = async (values: SubmitFormType) => {
    this.setState({
      loading: true
    })

    // Convert base64 to file object
    const url = await fetch(values.uploadFileUrl);
    const blob = await url.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });

    const formData = new FormData();
    formData.append("name", values.primaryName);
    formData.append("second_name", values.secondaryName);
    formData.append("image_type", values.selectedType.toLowerCase());
    formData.append("image", file);

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateGalleryItemApiId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editIconsApi + (values?.id ?? '')
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), 
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), 
      configJSON.apiMethodTypePut
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }


  handleUploadClick = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const file = event.target.files && event.target.files[0];
  
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target?.result as string;
        img.onerror = () => {
          this.setState({
            selectedFileName: ""
          }); 
          alert(`${this.state.imageLabel} ${configJSON.isCourrpt}`);
        }
        img.onload = () => {
          const width = img.width;
          const height = img.height;
  
          // Check the width and height
          if (width > 129 || height > 129) {
            // Handle the case when the image dimensions exceed the maximum allowed size
            // For example, show an error message or perform necessary actions
            alert(configJSON.dimensionError);
            this.setState({
              selectedFile: "",
              selectedFileName: ""
            }); 
    
            setFieldValue("uploadFileUrl", "");
            return;
          }
          this.setState({
            selectedFile: e.target?.result as string,
            selectedFileName: file.name
          });
  
          setFieldValue("uploadFileUrl", e.target?.result);
        };
      };
  
      reader.readAsDataURL(file);

      return reader
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null })
  };

  handleSelectItem = (item: string) => {
    this.setState({
      selectedItem: item,
      imageLabel: item,
      selectedFileName: ""
    })
    // Handle selected item logic
  };

  handleCloseSnackbar = ()=>{
    this.setState({
        snackbar: {
            message:'',
            open:false,
            severity:'info'
        }
     })
}

  getSelectedOption = (value: string, id: string, setFieldsValue: Function) => {
    if (value === 'Upload') {
      const fileInput = document.getElementById('contained-button-file') as HTMLInputElement;
       if(fileInput){
        fileInput.value = '' 
        fileInput.click();
       } 
    }
    if (value === 'Delete') {
     setFieldsValue("uploadFileUrl", "");
     this.setState({
      selectedFileName: ''
     })
    }
  }

  listViewMenuAction = (option: string, item: any, identifier: any) => {
    if (option === 'Edit') {
      this.props.navigation.history.push(`Products-GalleryAddImageIcon`, item)
    }
    if (option === 'Delete') {
      this.setState({deleteDialogOpen: true, listDeleteItemId: item.attributes.id})
    }
  }

  gridViewMenuAction = (option: string, item: any, identifier: any) => {
    if (option === 'Edit') {
      this.setState({gridSelectMode: 'Edit', gridSelectItem: identifier, gridDeleteItemIds: [], gridEditItem: undefined, isCheckedSelectAll: false})
    }
    if (option === 'Delete') {
      this.setState({gridSelectMode: 'Delete', gridSelectItem: identifier, gridDeleteItemIds: [], gridEditItem: undefined, isCheckedSelectAll: false})
    }
  }

  gridViewSelectBtnAction = () => {
    if(this.state.gridSelectMode === 'Edit'){
      this.props.navigation.history.push(`Products-GalleryAddImageIcon`, this.state.gridEditItem)
    }else if(this.state.gridSelectMode === 'Delete'){
      this.setState({deleteDialogOpen: true, listDeleteItemId: this.state.gridDeleteItemIds})
    }

  }

  checkIsAllSelected = (selectedItems: any, allItems: any) => {
    return allItems.every((item: any)=> selectedItems.includes(item))
  }

  getAllIds = (itemType: 'Icon' | 'Image' | '') => {
    if(itemType === '') return []
    return itemType === 'Icon' 
      ? this.state.allIcons.map((icon: any)=>icon.attributes.id)
      : this.state.allImages.map((icon: any)=>icon.attributes.id)
  }

  gridViewItemSelectDelete = (itemId: number, isChecked: boolean) => {
    if(isChecked){
      this.setState((prevState)=>{
        const updatedSelection = [...prevState.gridDeleteItemIds, itemId]
        const allIds = this.getAllIds(this.state.gridSelectItem)
        const isAllSelected = this.checkIsAllSelected(updatedSelection, allIds)
        return {gridDeleteItemIds: updatedSelection, isCheckedSelectAll: isAllSelected}
      })
    }else {
      this.setState((prevState)=>{
        const localDeleteIds = [...prevState.gridDeleteItemIds].filter(item => item !== itemId)
        return {gridDeleteItemIds: localDeleteIds, isCheckedSelectAll: false}
      })
    }
  }

  gridViewItemSelecteEdit = (item: any) => {
    this.setState({gridEditItem: item})
  }

  deletePopupCancel = () => {
    this.setState({deleteDialogOpen: false, listDeleteItemId: ''})
  }

  selectAllForDelete = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked){
      let allIds = this.getAllIds(this.state.gridSelectItem) 
      this.setState({gridDeleteItemIds:  allIds, isCheckedSelectAll: true})
    }else{
      this.setState({gridDeleteItemIds: [], isCheckedSelectAll: false})
    }
  }

  handleCancelBtn =  (item:any)=>{
   return item.title === "Cancel" ? this.props.navigation.navigate('Gallery'): {}
  }


onSearchText = (text:string)=>{
    this.setState({
      searchText: text,
      pageNumImg:1
    },()=>this.debounce(this.handleDebouncedChange, 1000 ))
}

debounce = (func: () => void, delay: number ) => {
  if (this.debounceTimer &&  typeof this.debounceTimer === "number") {
    clearTimeout(this.debounceTimer);
  }
  this.debounceTimer = setTimeout(func, delay);
};

handleDebouncedChange = () => {

  this.searchByText(this.state.searchText);
  // this.searchByText(this.state.searchText)
   // Perform any other relevant task based on the debounced input value
 };


 searchByText = (value:string)=>{
   if (value === "") {
     this.setState({ allImages: [] })
   }
 
  const query =  value ? `filter_by[query]=${value}`: `filter_by[query]=`
  this.setState({
     query,
     pageNumImg: 1,
     searchText: value
  }, () => {
    this.getAllIcons()
    this.getAllImage()
  })
 }

 getCurrentTabValue = (value:number)=>{
 
      this.setState({
        currentTab:value
      })
 }

 clear = ()=>{
  let updatedTabsValue;
  switch(this.state.currentTab){
   
    case 0 : 
     updatedTabsValue =  {...this.state.filters, name: {} as Filter}
    this.setState({
        filters: updatedTabsValue
    })
    break;

    case 1: 
     updatedTabsValue =  {...this.state.filters, type: {} as Filter}
     this.setState({
      filters: updatedTabsValue,
   
    })
  break;

 default: {
  updatedTabsValue =  {...this.state.filters, name: {} as Filter}
  this.setState({
    filters: updatedTabsValue,
 
  })
 }

  }


}


  handleGridViewChange = (isGridView: boolean) => {
    this.setState({isGridView, gridDeleteItemIds: [], gridEditItem: undefined, gridSelectMode: '', gridSelectItem: '', isCheckedSelectAll: false})
  }

  listProps={
    onClick:() => this.handleGridViewChange(false)
  };
  gridProps={
    onClick:() => this.handleGridViewChange(true)
  };

  handleQueryChange = (query: string) => {
    this.setState({ iconSort: query }, 
      () => {this.getAllIcons()}
    );
  };

  handleImageQueryChange = (query: string) => {
    this.setState({ imageSort: query }, 
      () => {this.getAllImage()}
    );
  };

  sortingProps = {
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    onQueryChange: (query: string) => this.handleQueryChange(query),
    width: "calc(33% - 17px)",
  };

  sortingPropsImage = {
    onChange: (sortingDataImage: ISortingData) => this.setState({ sortingDataImage }),
    onQueryChange: (query: string) => this.handleImageQueryChange(query),
    width: "calc(50% - 25px)",
  };

  getAddImageSubmitBtnType = (itemTitle: any) => {
    return itemTitle === "Add" ? "submit" : "button"
  }

  getAddImageSubmitBtnText = (itemTitle: any) => {
    return itemTitle === "Add" && this.state.isEditMode? "Update" : itemTitle
  }

  checkIsError = (touched: any, errors: any , field: any) => {
    return touched[field] && errors[field]
  }

  getFormikInitValue = (value: any) => {
    return value || ''
  }


  deleteIconImage = (itemDelete: any) => {
    this.setState({loading: true, deleteDialogOpen: false, listDeleteItemId: ''})
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const bodyData = {
      image_ids: itemDelete
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteIconImageApi 
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethidTypeDelete
    );

    this.deleteIconImageApiId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  setGalleryName = (value: any) => {
    let headers: any = {
      "Content-type": "application/json", 
      token: this.token
    }

    let url: any;
    url = configJSON.gallerySuggestionApi + `?gallery_name=${value}`

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.apiMethodTypeGet,
    });

    this.gallerySuggessionApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleGalleryCloseActionList = () => {
    this.setState({ openGalleryAction: null })
  }

  handleGalleryExportTemplate = () => {
    this.setState({ openGalleryAction: null })
    const apiUrl = configJSON.exportTemplateGalleryCsvApiurl;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.apiMethodTypeGet,
    });

    this.exportTemplateFileGalleryApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGalleryOpenImportModal = () => {
    this.setState({ openGalleryAction: null });
    let msg = new Message(getCustomEnumName(CustomEnums.ImportExportPopupMeassage))
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    )
    this.send(msg)
  }

  handleGalleryExportCsv = () => {
    this.setState({ openGalleryAction: null });
    const url = configJSON.exportGalleryCsvApiurl;

    const requestMessage = makeApiMessage({
      url: url,
      method: configJSON.apiMethodTypeGet,
    });

    this.exportFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);  
  }

  handleGalleryImportExportModalClose = () => {
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupClose))
    message.addData('ParentpageRoute', 'Gallery')
    runEngine.sendMessage(message.id, message)
  }

  handleGalleryFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ setLoaded: 0 })
    const getGalleryFile = event.target.files;

    let randomGalleryNumber = randomNumberGenerator(1, 9);
    const delay = randomGalleryNumber * 25;
    const uploadGalleryInterval = setInterval(() => {
      this.setState({
        setLoaded: updateGalleryLoadingTime(this.state.setLoaded)
      }, () => {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupFileMessage))
        message.addData('returnValue', { setLoaded: this.state.setLoaded, file: getGalleryFile && getGalleryFile[0] })
        runEngine.sendMessage(message.id, message)
      })

    }, delay);

    // for adding 20 percent every time
    function updateGalleryLoadingTime(prevLoaded: number) {
      if (prevLoaded >= 100) {
        clearInterval(uploadGalleryInterval);
        return 100;
      }
      return prevLoaded + 20
    }

    const checkFile = getGalleryFile && getGalleryFile[0];
    this.setState({ uploadedFile: checkFile as File})
  };

  handleGalleryImportFile = () => {
    const url = configJSON.importGalleryCsvApiurl;
    
    const formData = new FormData();
    formData.append('data[file]', this.state.uploadedFile as File);
    const requestMessage = makeApiMessage({
      url: url,
      body: formData,
      method: configJSON.apiMethodTypePost,
      isHeader: true
    });

    this.importFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }

  handleGallerySnackbarClose = () => {
    this.setState({
      snackbar: {
        ...this.state.snackbar,
        open: false
      }
    })
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.galleryPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    value.editPermission && this.state.options.push("Edit")
    value.deactivatePermission && this.state.options.push("Delete")
    !value.editPermission && !value.deactivatePermission && this.state.options.push("No permission") 

    const apiKeyImportExport = customPermissionApiKey.dataImportExportPermission;
    const valueImportExportPermission = checkForImportExportPermissionStatus(apiKeyImportExport, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: {
        ...value,
        exportPermission: valueImportExportPermission.exportPermission
      },
      isLoadingPermission: false
    })
  };

  handleResForExportFile = async (from: string, message: Message) => {
    if (this.exportFileApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        location.href = responseJson.data.url;
        this.setState({
          snackbar: {
            message: configJSON.successExcelMessage,
            severity: 'success',
            open: true
          }
        })
      }
    }
  }

  handleResForExportTemplateFile = async (from: string, message: Message) => {
    if (this.exportTemplateFileGalleryApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        location.href = responseJson.url;
        this.setState({
          snackbar: {
            message: configJSON.successExcelMessage,
            severity: 'success',
            open: true
          }
        })
      }
    }
  }

  fetchMoreImages = () => {
    if(this.state.totalPageImg === this.state.pageNumImg) {
      return
    }

    this.setState({
      pageNumImg: this.state.pageNumImg + 1
    }, () => {
      this.getAllImage()
    })
  }

  // Customizable Area End
}
