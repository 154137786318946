import React from "react";
// Customizable Area Start
import { Button, styled } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import AddLoyaltyController, {
  Props,
  configJSON,
} from "./AddLoyaltyController.web";

import PageHeader from "../../../components/src/PageHeader";

export default class AddLoyalty extends AddLoyaltyController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <LoyalityViewWrapper>
        <div className={"mainContainerLoyalty"}>
          <PageHeader
            data-test-id='loyaltyListHeader'
            header={configJSON.loyaltyList}
            buttonText={configJSON.editLoyalty}
            onButtonClick={this.handleEdit}
            buttonStyle={{ width: 200 }}
            permissionStatus={this.state.permissionStatus.editPermission}
          />

          <div className={'formContainerLoyalty'}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <div className={ 'inputLabelLoyalty' }>
                  {configJSON.status}
                </div>
                  <div className="valueText"
                  style={{
                    color: this.state.loyalty.attributes.active
                      ? "#059669"
                      : "#DC2626",
                  }}
                >
                  {this.state.loyalty.attributes.active
                    ? "Active"
                    : "Deactivated"}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div className={'inputLabelLoyalty' }>
                  {configJSON.section}
                </div>
                  <div className={'sectionContainer'} style={{ flexWrap: "wrap" }}>
                  {this.state.loyalty.attributes.sections.map((section) => (
                    <div
                      className={'selectedSection'}>
                      {section.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "32px" }}>
              <div style={{ width: "50%" }}>
                <div className={'inputLabelLoyalty' }>
                  {configJSON.minimumPointsToRedeem}
                </div>
                  <div className="valueText"
                  style={{
                    color: "#204B9C",
                  }}
                >
                  {this.state.loyalty.attributes.min_redeemed_points}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div className={'inputLabelLoyalty' }>
                  {configJSON.expiryDate}
                </div>
                  <div className="valueText"
                  style={{
                    color: "#204B9C",
                  }}
                >
                  {this.state.loyalty.attributes.expiry_date}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "32px" }}>
            <div className={'indicatorContainer'}>
              <div style={{ width: "140px" }}>
                <div className={'inputLabelLoyalty' }>
                  {configJSON.spendAmount}
                </div>
                  <div className={"valueText"}
                  style={{
                    color: "#204B9C",
                  }}
                >
                  {"SAR " + this.state.loyalty.attributes.spend_amount}
                </div>
              </div>
              <div style={{ width: "20px" }}>
                <ArrowRightAltIcon />
              </div>
              <div style={{ width: "145px" }}>
                <div className={'inputLabelLoyalty' }>
                  {configJSON.earnedPoint}
                </div>
                  <div className="valueText"
                  style={{
                    color: "#204B9C",
                  }}
                >
                  {this.state.loyalty.attributes.earned_points}
                </div>
              </div>
            </div>
            <div className={'indicatorContainer'}>
              <div style={{ width: "110px" }}>
                <div className={'inputLabelLoyalty' }>
                  {configJSON.usedPoint}
                </div>
                  <div className="valueText"
                  style={{
                    color: "#204B9C",
                  }}
                >
                  {this.state.loyalty.attributes.redeemed_points}
                </div>
              </div>
              <div style={{ width: "20px" }}>
                <ArrowRightAltIcon />
              </div>
              <div style={{ width: "145px" }}>
                <div className={'inputLabelLoyalty' }>
                  {configJSON.creditAmount}
                </div>
                  <div className="valueText"
                  style={{
                    color: "#204B9C",
                  }}
                >
                  {"SAR " + this.state.loyalty.attributes.received_amount}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "32px",
            paddingBottom: "32px",
            marginTop:'32px'
          }}
        >
          <Button
            data-test-id='GoBack'
            variant='contained'
            onClick={this.goBack}
            size='medium'
            className="goBackButton"
            style={{ textTransform: "none" }}
            color='secondary'
            disabled={!!this.state.snackBarMessage}
          >
            {configJSON.backToListing}
          </Button>
        </div>
        </LoyalityViewWrapper>
      </>
    );
  }
}

const LoyalityViewWrapper = styled("div")({
  '& .mainContainerLoyalty': {
    fontFamily: 'Montserrat',
    margin: 0,
  },
  '& .formContainerLoyalty': {
    marginTop: 32,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
    borderRadius: '12px',
    padding: 32,
  },
  '& .inputLabelLoyalty': {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '20px',
    alignItems: 'center',
    letterSpacing: '-0.3px',
    color: '#334155',
    fontFamily: 'Montserrat !important',
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    }
  },
  '& .addButton': {
    marginTop: '8px',
    fontSize: '16px',
    color: 'rgba(77, 77, 77, 1)',
    backgroundColor: '#F1F5F9',
    fontWeight: 700,
  },
  '& .goBackButton': {
    height: '56px',
    backgroundColor: '#204B9C',
    width: '200px',
    fontSize: '16px',
    fontWeight: 700,
    "@media only screen and (max-width: 1024px)": {
      height: "44px",
      fontWeight: 600,
    }
  },
  '& .errorText': {
    marginTop: '-26px',
    color: 'red',
    fontSize: '12px',
  },
  '& .header': {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    marginTop: '84px',
  },
  '& .selectedSection': {
    borderRadius: '16px',
    fontFamily: 'Montserrat',
    background: 'rgba(32, 75, 156, 0.10)',
    color: '#204B9C',
    fontSize: '13px',
    fontWeight: 500,
    padding: '8px',
  },
  '& .valueText': {
    marginTop: '12px',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 500,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '13px',
    }
  },
  '& .indicatorContainer': {
    marginTop: '32px',
    justifyContent: 'space-between',
    width: '100%',
    height: '120px',
    display: 'flex',
    padding: '32px 20px',
    borderRadius: '12px',
    background: 'rgba(32, 75, 156, 0.10)',
  },
  '& .sectionContainer': {
    display: 'flex',
    gap: '8px',
    marginTop: '8px',
    maxWidth: '100%',
  },
});

// Customizable Area End
